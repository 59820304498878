<template>
  <form class="c-EnvelopesOnlyForm pl-12" @submit.prevent="submitEnvelopesOnly">
    <!-- Text -->
    <div class="text-area pr-6">
      <p>{{ text }}</p>
    </div>

    <!-- Action buttons -->
    <div class="action-btns-area d-flex">
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        variant="outlined"
        class="mr-2"
        :disabled="isSubmitting"
        @click="() => $emit('cancel')"
      >
        Cancel
      </v-btn>

      <v-btn
        type="submit"
        color="primary"
        class="elevation-0"
        :loading="isSubmitting"
        :disabled="isSubmitting"
      >
        Confirm
      </v-btn>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { envelopesOnlyActivated } from '@/model/control/controlMode'
import {
  Policy,
  Params_AutomaticControlEvent as AutomaticPolicyParams,
} from 'rfs/control/proto/policy_pb'
import type { Device } from 'rfs/control/proto/model_pb'

export default defineComponent({
  name: 'EnvelopesOnlyForm',
  props: {
    groupId: {
      type: String,
      required: true,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: true,
    },
  },
  setup() {
    return {
      text: 'Operating envelopes will be activated for all group members, and can be edited at the single device level. Any existing commands will be cancelled.',
    }
  },
  data() {
    return { globalSnackbarStore: useGlobalSnackbar(), isSubmitting: false }
  },
  methods: {
    async submitEnvelopesOnly(): Promise<void> {
      this.$emit('submitting', true)
      this.isSubmitting = true

      try {
        if (!this.devices.length) throw new Error('no devices available')

        // Turn on all Operating ENvelopes.
        await this.$services.control.updateDevices({
          updates: this.devices.map((d) => {
            return { deviceId: d.id, oeEnabled: true }
          }),
        })

        await this.$services.control.setGroupPolicy({
          policy: Policy.AUTOMATIC_CONTROL_EVENT,
          ids: [this.groupId],
          params: {
            paramsOneof: {
              case: 'automaticControlEvent',
              value: new AutomaticPolicyParams({
                constraintsOnly: true,
              }),
            },
          },
        })

        this.globalSnackbarStore.openSnackbar(envelopesOnlyActivated, 'info')

        this.$emit('success')
      } catch (err) {
        this.globalSnackbarStore.openSnackbar(
          (err as any).message || 'Something went wrong. try again.',
          'error'
        )
        console.error('EnvelopesOnlyForm.submitEnvelopesOnly: %o', err)
      } finally {
        this.$emit('submitting', false)
        this.isSubmitting = false
      }
    },
  },
})
</script>

<style lang="scss">
.c-EnvelopesOnlyForm {
  display: grid;
  grid-template-columns: 1fr 0fr;
  grid-template-rows: auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .text-area {
    grid-area: 1 / 1 / 2 / 2;
  }
  .action-btns-area {
    grid-area: 2 / 2 / 3 / 3;
  }
}
</style>

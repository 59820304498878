import { DateTime } from 'luxon'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Format } from '@/utils/format'
import { createDateTimeFromISOTime } from '@/utils/time'
import type { Header, Row, DataTable } from '@/model/tables/DataTable'
import {
  CHARGER_INPUT_FIELD_TOOLTIP_TEXT,
  formatSoc,
  formatPower,
  Metric,
  formatEnergy,
  getWaypointLabel,
} from '@/model/control/waypoint'
import type { Waypoint } from 'rfs/control/proto/waypoints_pb'
import type { ScheduleWaypoint } from 'rfs/control/proto/control_service_pb'
import type { DeviceScheduleCommand } from 'rfs/device/proto/proxy_pb'
import { DeviceType } from 'rfs/control/proto/model_pb'

export enum Columns {
  INDEX = 'INDEX',
  DATE = 'DATE',
  START_TIME = 'START_TIME',
  END_TIME = 'END_TIME',
  TIME = 'TIME',
  TARGET_VALUE = 'TARGET_VALUE',
  ACTION_BTNS = 'ACTION_BTNS',
}

export interface AutomaticControlRow extends Row {
  [Columns.INDEX]: string
  [Columns.DATE]?: string
  [Columns.START_TIME]: string
  [Columns.END_TIME]: string
  [Columns.TARGET_VALUE]?: string
}

export type AutomaticControlDataTable = DataTable<AutomaticControlRow>

function headerTargetValue(metric: Metric, powerRating?: number): Header {
  const title = (() => {
    switch (metric) {
      // Charger
      case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
        return 'Max Power (kW)'
      // Nuvve EVSE (LPEA) and  Battery (VEC).
      case Metric.METRIC_ACTIVE_POWER_WATTS:
        return 'Power (kW)'
      // Battery
      case Metric.METRIC_SOC_PERCENT:
        return 'SoC (%)'
      // Battery - Emulate
      case Metric.METRIC_SOC_WATT_HOURS:
        return 'Energy (kWh)'
      default:
        throw new Error('unexpected metric')
    }
  })()

  return {
    title,
    key: Columns.TARGET_VALUE,
    width: '20%',
    sortable: false,
    tooltip: getHeaderTooltip(metric, powerRating),
  }
}

export function createAutomaticControlHeaders(opts: {
  metric: Metric
  recurring?: boolean
  powerRating?: number
  deviceType?: DeviceType
}): Header[] {
  const timezoneSuffix = DateTime.now().toFormat('(ZZZZ)')

  const headers: Header[] = [
    { title: '', key: Columns.INDEX, width: '5%', sortable: false },
  ]

  // Recurring schedule has no "date" field.
  if (!opts.recurring) {
    headers.push({
      title: 'Date',
      key: Columns.DATE,
      width: '20%',
      sortable: false,
    })
  }

  const label = getWaypointLabel({
    metric: opts.metric,
    deviceType: opts.deviceType,
  })

  headers.push(
    {
      title: `Start time ${timezoneSuffix}`,
      key: Columns.START_TIME,
      width: '20%',
      sortable: false,
      tooltip: `Enter the start time of the scheduled ${label} using hour starting convention (${label} starting at 10 will start at 10:00).`,
    },
    {
      title: `End time ${timezoneSuffix}`,
      key: Columns.END_TIME,
      width: '20%',
      sortable: false,
      tooltip: `Enter the end time of the scheduled ${label} using hour starting convention (${label} ending at 11 will end at 11:00).`,
    },
    headerTargetValue(opts.metric, opts.powerRating),
    {
      title: '',
      key: Columns.ACTION_BTNS,
      width: '15%',
      sortable: false,
    }
  )

  return headers
}

export function createAutomaticControlDataTable(opts: {
  metric: Metric
  waypoints?: Waypoint[]
  recurringWaypoints?: ScheduleWaypoint[]
  deviceScheduleCommands?: DeviceScheduleCommand[]
}): AutomaticControlDataTable {
  let rows: AutomaticControlRow[] = []

  const formatDate = (dt: DateTime) => dt.toLocaleString(DateTime.DATE_SHORT)
  const formatTime = (dt: DateTime) => dt.toLocaleString(DateTime.TIME_SIMPLE)

  if (opts.waypoints) {
    rows = opts.waypoints.map((w, index): AutomaticControlRow => {
      const { startTime, endTime, targetValue } = w

      const row = {
        id: index.toString(),
        [Columns.INDEX]: index.toString(),
        [Columns.DATE]: startTime
          ? formatDate(DateTime.fromMillis(startTime.toMillis()))
          : TEXT_NO_VALUE,
        [Columns.START_TIME]: startTime
          ? formatTime(DateTime.fromMillis(startTime.toMillis()))
          : TEXT_NO_VALUE,
        [Columns.END_TIME]: endTime
          ? formatTime(DateTime.fromMillis(endTime.toMillis()))
          : TEXT_NO_VALUE,
      }

      switch (opts.metric) {
        case Metric.METRIC_MAX_CHARGE_POWER_WATTS: // Charger
        case Metric.METRIC_ACTIVE_POWER_WATTS: // Battery (VEC)
          return {
            ...row,
            [Columns.TARGET_VALUE]: formatPower(targetValue) || TEXT_NO_VALUE,
          }
        // Battery (SoC)(%).
        case Metric.METRIC_SOC_PERCENT:
          return {
            ...row,
            [Columns.TARGET_VALUE]: formatSoc(targetValue) || TEXT_NO_VALUE,
          }
        // Battery - Emulate
        case Metric.METRIC_SOC_WATT_HOURS:
          return {
            ...row,
            [Columns.TARGET_VALUE]: formatEnergy(targetValue) || TEXT_NO_VALUE,
          }
        default:
          throw new Error('unsupported metric type')
      }
    })
  } else if (opts.recurringWaypoints) {
    rows = opts.recurringWaypoints.map((w, index): AutomaticControlRow => {
      const { startTime, endTime, value } = w

      const now = DateTime.now()

      const row = {
        id: index.toString(),
        [Columns.INDEX]: index.toString(),
        [Columns.START_TIME]: ((): string => {
          const dt = createDateTimeFromISOTime(startTime, now)
          return dt ? formatTime(dt) : TEXT_NO_VALUE
        })(),
        [Columns.END_TIME]: ((): string => {
          const dt = createDateTimeFromISOTime(endTime, now)
          return dt ? formatTime(dt) : TEXT_NO_VALUE
        })(),
      }

      switch (opts.metric) {
        case Metric.METRIC_MAX_CHARGE_POWER_WATTS: // Charger
        case Metric.METRIC_ACTIVE_POWER_WATTS: // Battery (VEC)
          return {
            ...row,
            [Columns.TARGET_VALUE]: formatPower(value) || TEXT_NO_VALUE,
          }
        // Battery
        case Metric.METRIC_SOC_PERCENT:
          return {
            ...row,
            [Columns.TARGET_VALUE]: formatSoc(value) || TEXT_NO_VALUE,
          }
        // Battery - Emulate
        case Metric.METRIC_SOC_WATT_HOURS:
          return {
            ...row,
            [Columns.TARGET_VALUE]: formatEnergy(value) || TEXT_NO_VALUE,
          }
        default:
          throw new Error('unsupported metric type')
      }
    })
  } else if (opts.deviceScheduleCommands) {
    rows = opts.deviceScheduleCommands.map((w, index): AutomaticControlRow => {
      const { startTime, endTime } = w

      return {
        id: index.toString(),
        [Columns.INDEX]: index.toString(),
        [Columns.DATE]: startTime
          ? formatDate(DateTime.fromMillis(startTime.toMillis()))
          : TEXT_NO_VALUE,
        [Columns.START_TIME]: startTime
          ? formatTime(DateTime.fromMillis(startTime.toMillis()))
          : TEXT_NO_VALUE,
        [Columns.END_TIME]: endTime
          ? formatTime(DateTime.fromMillis(endTime.toMillis()))
          : TEXT_NO_VALUE,
        [Columns.TARGET_VALUE]: formatPower(w.value) || TEXT_NO_VALUE,
      }
    })
  }

  return { rows }
}

export function getHeaderTooltip(metric: Metric, powerRating?: number): string {
  const formatted = Format.fmtWatts(powerRating)

  switch (metric) {
    // Battery.
    case Metric.METRIC_SOC_PERCENT:
      return 'Enter positive values from 0 to 100'
    // Battery - Emulate.
    case Metric.METRIC_SOC_WATT_HOURS:
      return '' // TODO(rafael)
    // Chargers.
    case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
      return CHARGER_INPUT_FIELD_TOOLTIP_TEXT
    // V2G.
    case Metric.METRIC_ACTIVE_POWER_WATTS:
      return powerRating
        ? `Enter positive values (up to ${formatted}) to charge and negative values (down to −${formatted}) to discharge.`
        : 'Enter positive values to charge and negative values to discharge.'
    default:
      return ''
  }
}

import { DateTime } from 'luxon'
import type { RittaConfig } from '@/config'

const HTML_ID_V2G_BUS_EASTER_EGG = 'v2gBusEasterEgg'

export function isV2gBusEasterEggEnabled(
  config: Readonly<RittaConfig>
): boolean {
  return !!config.featureFlags.v2gBusEasterEgg
}

export function addV2gBusEasterEgg(): void {
  const newIframe = document.createElement('iframe')
  // @ts-ignore
  newIframe.style =
    'width:0;height:0;border:0;border:none;visibility:hidden;opacity:0;position:fixed;pointer-events:none;'
  newIframe.setAttribute('id', HTML_ID_V2G_BUS_EASTER_EGG)
  newIframe.setAttribute('width', '0')
  newIframe.setAttribute('height', '0')
  newIframe.setAttribute('frameborder', '0')
  newIframe.setAttribute(
    'allow',
    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
  )
  newIframe.setAttribute(
    'src',
    'https://www.youtube.com/embed/zFHBfFAmcbc?autoplay=1&start=11&end=20&mute=0'
  )
  document.body.appendChild(newIframe)

  // Save in storage.
  const data = { lastTime: DateTime.now().toMillis() }
  localStorage.setItem(HTML_ID_V2G_BUS_EASTER_EGG, JSON.stringify(data))
}

export function removeV2gBusEasterEgg(): void {
  const iframe = document.getElementById(HTML_ID_V2G_BUS_EASTER_EGG)

  if (iframe) {
    iframe.remove()
  }
}

export function shouldAddV2gBusEasterEgg(): boolean {
  const now = DateTime.now()

  const isTodayFriday = now.weekday === 5

  if (!isTodayFriday) return false

  const state = localStorage.getItem(HTML_ID_V2G_BUS_EASTER_EGG)

  if (!state) return true

  const { lastTime } = JSON.parse(state)

  return !DateTime.fromMillis(Number(lastTime)).hasSame(now, 'day')
}

<template>
  <insight-template
    :isLoading
    :charts
    :dataSource
    :summaryBoxes
    :analysisSections
    :interval
    @new-interval="handleNewInterval"
  />
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import type { Interval } from 'luxon'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { MEGA_OPTIONS, Format } from '@/utils/format'
import { numberNoDecimals } from '@/utils/formatText'
import {
  formatDtWithZoneShort,
  intervalLast7Days,
  toCalendarPeriod,
} from '@/utils/time'
import { Timestamp } from '@/services/timestamp_pb'
import {
  chartDistributedGeneration,
  getMaxPvGenerationByFeeder,
  getNumberOfPvsByFeeder,
  getTopFeedersByMaxPvGeneration,
  getTopFeedersByNumberOfPvs,
  newPvInsightDataSource2,
} from '@/model/insight/InsightChartData'
import {
  getNumberOfPvs,
  getPeakPvLoad,
  getPvSystemPeak,
} from '@/model/insight/summaries'
import InsightTemplate, {
  type SummaryBox,
  type AnalysisSection,
} from '@/components/analyze/insights/InsightTemplate.vue'
import { TimeSeriesDataSource } from '@/model/charts'
import type { HorizontalBarChartData } from '@/types/charts'

const EMPTY_HORIZONTAL: HorizontalBarChartData = { bars: [] }

export default defineComponent({
  name: 'InsightPv',
  components: { InsightTemplate },
  setup() {
    return { charts: [chartDistributedGeneration] }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      interval: intervalLast7Days(this.$observationTime()),
      dataSource: TimeSeriesDataSource.emptyDataSource(),
      numberOfPvs: undefined as undefined | ReturnType<typeof getNumberOfPvs>,
      peakPvLoad: undefined as
        | undefined
        | Awaited<ReturnType<typeof getPeakPvLoad>>,
      systemPeak: undefined as undefined | ReturnType<typeof getPvSystemPeak>,
      numberOfPvsByFeeders: [] as number[],
      topFeedersByNumberOfPvs: EMPTY_HORIZONTAL,
      maxPvGenerationByFeeder: [] as number[],
      topFeedersByMaxPvGeneration: EMPTY_HORIZONTAL,
    })
  },
  computed: {
    summaryBoxes(): SummaryBox[] {
      return [
        {
          title: 'Number of PV systems',
          mainValue:
            this.numberOfPvs?.total !== undefined
              ? numberNoDecimals.format(this.numberOfPvs.total)
              : TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Metered',
              text:
                this.numberOfPvs?.metered !== undefined
                  ? numberNoDecimals.format(this.numberOfPvs.metered)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Estimated',
              text:
                this.numberOfPvs?.estimated !== undefined
                  ? numberNoDecimals.format(this.numberOfPvs.estimated)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Installed capacity',
              text:
                this.numberOfPvs?.installedCapacity !== undefined
                  ? Format.fmtWatts(
                      this.numberOfPvs.installedCapacity,
                      MEGA_OPTIONS
                    )
                  : TEXT_NO_VALUE,
            },
          ],
        },
        {
          title: 'Peak PV generation',
          mainValue:
            this.peakPvLoad?.peak !== undefined
              ? Format.fmtWatts(this.peakPvLoad.peak, MEGA_OPTIONS)
              : TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Time of peak',
              text: this.peakPvLoad?.timeOfPeak
                ? formatDtWithZoneShort(this.peakPvLoad.timeOfPeak)
                : TEXT_NO_VALUE,
            },
            {
              label: 'Metered',
              text:
                this.peakPvLoad?.peakOfMetered !== undefined
                  ? Format.fmtWatts(this.peakPvLoad.peakOfMetered, MEGA_OPTIONS)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Estimated',
              text:
                this.peakPvLoad?.peakOfEstimated !== undefined
                  ? Format.fmtWatts(
                      this.peakPvLoad.peakOfEstimated,
                      MEGA_OPTIONS
                    )
                  : TEXT_NO_VALUE,
            },
          ],
        },
        {
          title: 'System peak',
          mainValue: Format.fmtWatts(this.systemPeak?.total, MEGA_OPTIONS),
          infoColumnItems: [
            {
              label: 'Time of peak',
              text: this.systemPeak?.timeOfPeak
                ? formatDtWithZoneShort(this.systemPeak.timeOfPeak)
                : TEXT_NO_VALUE,
            },
            {
              label: 'PV generation at peak',
              text:
                this.systemPeak?.pvProductionAtPeak !== undefined
                  ? Format.fmtWatts(
                      this.systemPeak.pvProductionAtPeak,
                      MEGA_OPTIONS
                    )
                  : TEXT_NO_VALUE,
            },
            {
              label: 'PV generation of peak',
              text:
                this.systemPeak?.pvProductionAtPeakPercent !== undefined
                  ? Format.fmtPercent(this.systemPeak.pvProductionAtPeakPercent)
                  : TEXT_NO_VALUE,
            },
          ],
        },
      ]
    },
    analysisSections(): AnalysisSection[] {
      return [
        {
          title: 'PV adoption',
          histogram: {
            title: 'Number of PV systems by feeder',
            xAxisTitle: 'Number of PV systems',
            yAxisTitle: 'Number of feeders',
            data: this.numberOfPvsByFeeders,
          },
          horizontalBar: {
            title: 'Number of PV systems (top 10 feeders)',
            xAxisTitle: 'Number of PV systems',
            data: this.topFeedersByNumberOfPvs,
          },
        },
        {
          title: 'PV generation',
          histogram: {
            title: 'Max PV generation by feeder',
            xAxisTitle: 'Max generation (kW)',
            yAxisTitle: 'Number of feeders',
            data: this.maxPvGenerationByFeeder,
          },
          horizontalBar: {
            title: 'Max PV generation (top 10 feeders)',
            xAxisTitle: 'Max generation (kW)',
            data: this.topFeedersByMaxPvGeneration,
          },
        },
      ]
    },
  },
  watch: {
    interval: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
    async fetchData() {
      this.isLoading = true

      this.dataSource = TimeSeriesDataSource.emptyDataSource()
      this.numberOfPvs = undefined
      this.systemPeak = undefined
      this.numberOfPvsByFeeders = []
      this.topFeedersByNumberOfPvs = EMPTY_HORIZONTAL
      this.maxPvGenerationByFeeder = []
      this.topFeedersByMaxPvGeneration = EMPTY_HORIZONTAL

      const fixedInterval = toCalendarPeriod(
        this.interval,
        this.$observationTime()
      )

      const timeParams =
        fixedInterval !== undefined
          ? { fixedInterval }
          : {
              start: Timestamp.fromDateTime(this.interval.start),
              end: Timestamp.fromDateTime(this.interval.end),
            }

      try {
        const [feeders, distributedSolars, gridImpactTable, gridImpactStats] =
          await Promise.all([
            this.$services.queryService.getResourcesByType(ResourceType.FEEDER),
            this.$services.queryService.getResourcesByType(
              ResourceType.SOLAR_DISTRIBUTED
            ),
            this.$services.analysisService.fetchDERImpactsTable({
              ...timeParams,
              componentType: ResourceType.FEEDER,
              limit: -1,
            }),
            this.$services.analysisService.fetchDERImpactsStats({
              ...timeParams,
              componentType: 'NONE',
            }),
          ])

        this.peakPvLoad = await getPeakPvLoad(
          this.$services,
          this.interval,
          distributedSolars.map((ds) => ds.id)
        )

        this.systemPeak = getPvSystemPeak(gridImpactStats)

        this.dataSource = newPvInsightDataSource2(
          this.$observationTime(),
          this.$services,
          this.interval
        )
        this.numberOfPvs = getNumberOfPvs(distributedSolars)
        this.numberOfPvsByFeeders = getNumberOfPvsByFeeder(
          feeders,
          distributedSolars
        )
        this.topFeedersByNumberOfPvs = getTopFeedersByNumberOfPvs(
          feeders,
          distributedSolars
        )
        this.maxPvGenerationByFeeder =
          getMaxPvGenerationByFeeder(gridImpactTable)
        this.topFeedersByMaxPvGeneration =
          getTopFeedersByMaxPvGeneration(gridImpactTable)
      } catch (err) {
        console.error('InsightPv.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>

<template>
  <div
    class="position-relative"
    style="overflow-y: hidden; overflow-x: hidden; height: 100%"
  >
    <!-- Left Drawer -->
    <left-panel v-show="!isStreetViewVisible" />

    <!-- Controls & Map -->
    <current-map-expand
      class="position-relative"
      @vue:mounted="addZoomPrevention"
      @vue:before-unmount="removeZoomPrevention"
    >
      <!-- Btn Expand Nav. Drawer Left -->
      <btn-expand-left-panel
        v-show="showBtnExpandLeftPanel"
        style="position: absolute; z-index: 1; top: 1.5rem; left: 0.75rem"
      />

      <!-- Right side controls -->
      <div
        aria-label="Right side controls"
        v-show="!isStreetViewVisible"
        class="d-flex"
        style="position: absolute; z-index: 1; top: 1.5rem; right: 0.75rem"
      >
        <upline-control
          v-if="showUplineControl"
          style="margin-right: 0.75rem"
        />

        <!-- Map layer controls -->
        <ce-controls />
      </div>

      <!-- Map -->
      <ce-google-maps />
    </current-map-expand>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { addZoomPrevention, removeZoomPrevention } from '@/utils/map'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import CeControls from '@/components/maps/CeControls.vue'
import BtnExpandLeftPanel from '@/components/navigation/BtnExpandLeftPanel.vue'
import LeftPanel from '@/components/navigation/LeftPanel.vue'
import CeGoogleMaps from '@/components/maps/CeGoogleMaps.vue'
import CurrentMapExpand from '@/components/others/CurrentMapExpand.vue'
import UplineControl from '@/components/maps/UplineControl.vue'

export default defineComponent({
  components: {
    BtnExpandLeftPanel,
    LeftPanel,
    CeControls,
    CeGoogleMaps,
    CurrentMapExpand,
    UplineControl,
  },
  computed: {
    ...mapState(useNavigationControlsStore, ['isStreetViewVisible']),
    showBtnExpandLeftPanel(): boolean {
      const drawerLeftDisabled = !!this.$route.meta?.drawerLeftDisabled
      return !drawerLeftDisabled && !this.isStreetViewVisible
    },
    showUplineControl(): boolean {
      return !!this.$rittaConfig.map.uplineMapLayer?.enabled
    },
  },
  methods: {
    addZoomPrevention(comp: any): void {
      addZoomPrevention(comp.el as HTMLElement)
    },
    removeZoomPrevention(comp: any): void {
      removeZoomPrevention(comp.el as HTMLElement)
    },
  },
})
</script>

<template>
  <form class="c-ControlsOffForm pl-12" @submit.prevent="submitControlsOff">
    <!-- Text -->
    <div class="text-area pr-6">
      <p>{{ text1 }}</p>

      <!-- Only when Operating Envelope enabled -->
      <p v-if="isOperatingEnvelopeEnabled" class="pt-8">{{ text2 }}</p>
    </div>

    <!-- Action buttons -->
    <div class="action-btns-area d-flex">
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        variant="outlined"
        class="mr-2"
        :disabled="isSubmitting"
        @click="() => $emit('cancel')"
      >
        Cancel
      </v-btn>

      <v-btn
        type="submit"
        color="primary"
        class="elevation-0"
        :loading="isSubmitting"
        :disabled="isSubmitting"
      >
        Confirm
      </v-btn>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { controlsOffActivated } from '@/model/control/controlMode'
import { Policy } from 'rfs/control/proto/policy_pb'
import type { Device } from 'rfs/control/proto/model_pb'

export default defineComponent({
  name: 'ControlsOffForm',
  props: {
    groupId: {
      type: String,
      required: true,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: true,
    },
    isOperatingEnvelopeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      globalSnackbarStore: useGlobalSnackbar(),
      text1:
        'All group members will enter “local” control mode and will not receive commands from Camus.',
      text2:
        'Operating envelopes will be unavailable for all DERs in the group, and cannot be activated at the single device level while the group is in this control mode.',
    }
  },
  data() {
    return { isSubmitting: false }
  },
  methods: {
    async submitControlsOff(): Promise<void> {
      this.$emit('submitting', true)
      this.isSubmitting = true

      try {
        // Turn off all Operating ENvelopes.
        if (this.isOperatingEnvelopeEnabled) {
          if (!this.devices.length) throw new Error('no devices available')

          await this.$services.control.updateDevices({
            updates: this.devices.map((d) => {
              return { deviceId: d.id, oeEnabled: false }
            }),
          })
        }

        await this.$services.control.setGroupPolicy({
          policy: Policy.DEFAULT,
          ids: [this.groupId],
        })

        this.globalSnackbarStore.openSnackbar(controlsOffActivated, 'info')

        this.$emit('success')
      } catch (err) {
        this.globalSnackbarStore.openSnackbar(
          (err as any).message || 'Something went wrong. try again.',
          'error'
        )
        console.error('ControlsOffForm.submitControlsOff: %o', err)
      } finally {
        this.$emit('submitting', false)
        this.isSubmitting = false
      }
    },
  },
})
</script>

<style lang="scss">
.c-ControlsOffForm {
  display: grid;
  grid-template-columns: 1fr 0fr;
  grid-template-rows: auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .text-area {
    grid-area: 1 / 1 / 2 / 2;
  }
  .action-btns-area {
    grid-area: 2 / 2 / 3 / 3;
  }
}
</style>

<template>
  <div>
    <!-- Main chart -->
    <time-series-chart-group
      :charts
      :dataSource
      :interval
      disable-scroll
      class="px-6 pb-8"
      @new-interval="(newInterval) => $emit('new-interval', newInterval)"
    >
      <!-- Default slot: Summary boxes -->
      <v-row
        v-if="summaryBoxes.length"
        tag="section"
        aria-label="Summary boxes"
        class="position-relative pt-3 pb-12"
        style="min-height: 18rem"
      >
        <!-- Loading -->
        <centered-spinner v-if="isLoading" />

        <!--  -->
        <template v-else>
          <v-col
            v-for="summary of summaryBoxes"
            :key="summary.title"
            sm="4"
            role="region"
            aria-label="Summary box"
          >
            <div
              class="h-100 pa-4"
              :style="{ backgroundColor: summaryBgColor }"
            >
              <!-- Title -->
              <h3 class="d-block text-subtitle-2 pb-2">
                {{ summary.title }}
              </h3>

              <!-- Main value -->
              <span
                class="d-block text-h4"
                :aria-label="`${summary.title} value`"
              >
                {{ summary.mainValue }}
              </span>

              <!-- Info Column -->
              <info-column
                subtitle=" "
                :items="summary.infoColumnItems"
                :name="`${summary.title} items`"
              />
            </div>
          </v-col>
        </template>
      </v-row>
    </time-series-chart-group>

    <!-- Analysis sections -->
    <section
      aria-label="Analysis sections"
      class="position-relative"
      style="min-height: 50rem"
    >
      <centered-spinner v-if="isLoading" />

      <template v-else>
        <section
          v-for="analysisSection of analysisSections"
          :key="analysisSection.title"
          :aria-label="analysisSection.title"
          class="mx-6 mb-6 pa-6"
          :style="{ backgroundColor: chartsBgColor, minHeight: '25rem' }"
        >
          <!-- Title -->
          <ce-title big class="pb-12">
            {{ analysisSection.title }}
          </ce-title>

          <!-- Charts -->
          <div class="d-flex">
            <!-- Histogram chart -->
            <div style="flex: 1; padding: 0 2rem">
              <span class="d-block text-subtitle-1 font-weight-medium">
                {{ analysisSection.histogram.title }}
              </span>
              <histogram-chart
                :name="analysisSection.histogram.title"
                :values="analysisSection.histogram.data"
                :x-axis-title="analysisSection.histogram.xAxisTitle"
                :y-axis-title="analysisSection.histogram.yAxisTitle"
                :barColor
              />
            </div>

            <!-- Horizontal bar chart -->
            <div class="d-flex" style="flex: 1; padding: 0 2rem">
              <!-- Chart -->
              <div style="flex: 1">
                <span class="d-block text-subtitle-1 font-weight-medium">
                  {{ analysisSection.horizontalBar.title }}
                </span>
                <horizontal-bar-chart
                  :name="analysisSection.horizontalBar.title"
                  :horizontal-bar-data="analysisSection.horizontalBar.data"
                  :x-axis-title="analysisSection.horizontalBar.xAxisTitle"
                  :barColor
                />
              </div>

              <!-- Side data -->
              <div
                v-if="analysisSection.horizontalBar.sideData"
                role="region"
                aria-label="Side data"
              >
                <!-- Title -->
                <h4 class="d-block text-subtitle-1 font-weight-medium">
                  {{ analysisSection.horizontalBar.sideData.title }}
                </h4>
                <!-- Items -->
                <div style="padding-top: 11px">
                  <span
                    v-for="item of analysisSection.horizontalBar.sideData.items"
                    :key="item"
                    class="d-block text-caption text-end pr-12"
                    style="line-height: 1.5"
                  >
                    {{ item }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { Interval } from 'luxon'
import { GRAY_COOL_50, NEUTRAL_50, VELVET } from '@/constants/colors'
import {
  TimeSeriesDataSource,
  type ITimeSeriesDataSource,
} from '@/model/charts'
import CeTitle from '@/components/CeTitle.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import type { InfoColumnItem } from '@/components/InfoColumn'
import InfoColumn from '@/components/InfoColumn.vue'
import { TimeSeriesChartGroup } from '@/components/common'
import HistogramChart from '@/components/common/HistogramChart.vue'
import HorizontalBarChart from '@/components/common/HorizontalBarChart.vue'
import type { ChartDefinition, HorizontalBarChartData } from '@/types/charts'

export type SummaryBox = {
  title: string
  mainValue: string
  infoColumnItems: InfoColumnItem[]
}

export type AnalysisSection = {
  title: string
  histogram: {
    title: string
    xAxisTitle: string
    yAxisTitle: string
    data: number[]
  }
  horizontalBar: {
    title: string
    xAxisTitle: string
    data: HorizontalBarChartData
    sideData?: {
      title: string
      items: string[]
    }
  }
}

export default defineComponent({
  name: 'Insights',
  props: {
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    charts: {
      type: Array as PropType<ChartDefinition[]>,
      required: false,
      default: () => [],
    },
    dataSource: {
      type: Object as PropType<ITimeSeriesDataSource>,
      required: false,
      default: () => TimeSeriesDataSource.emptyDataSource(),
    },
    summaryBoxes: {
      type: Array as PropType<SummaryBox[]>,
      required: false,
      default: () => [],
    },
    analysisSections: {
      type: Array as PropType<AnalysisSection[]>,
      required: false,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['new-interval'],
  components: {
    CeTitle,
    CenteredSpinner,
    InfoColumn,
    TimeSeriesChartGroup,
    HistogramChart,
    HorizontalBarChart,
  },
  setup() {
    return {
      summaryBgColor: NEUTRAL_50.hex,
      chartsBgColor: GRAY_COOL_50.hex,
      barColor: VELVET.hex,
    }
  },
})
</script>

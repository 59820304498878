<template>
  <div class="c-SiteDERTab">
    <!-- Generation -->
    <info-column-group
      v-if="showGenerationInfoColumnGroup"
      :group="generationInfoColumnGroup"
      class="pb-6"
    />
    <ce-divider
      v-if="
        showGenerationInfoColumnGroup &&
        (showStorageInfoColumn || showEvChargingColumnGroup)
      "
    />

    <!-- Storage -->
    <template v-if="showStorageInfoColumn">
      <info-column
        :title="storageInfoColumn.title"
        :subtitle="storageInfoColumn.subtitle"
        :items="storageInfoColumn.items"
        :link-bottom="storageInfoColumn.linkBottom"
        class="pb-6"
      />
      <ce-divider v-if="showEvChargingColumnGroup" />
    </template>

    <!-- EV Charging -->
    <info-column-group
      v-if="showEvChargingColumnGroup"
      :group="evChargingInfoColumnGroup"
      class="pb-6"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Format } from '@/utils/format'
import { formatText } from '@/utils/formatText'
import { ChargerHelper } from '@/model/resource/charger/helper'
import { Resource } from 'rfs/pb/resource_pb'
import InfoColumn from '@/components/InfoColumn.vue'
import CeDivider from '@/components/others/CeDivider.vue'
import { InfoColumnAllProps } from '@/components/InfoColumn'
import InfoColumnGroup from '@/components/InfoColumnGroup.vue'
import { Group } from '@/components/InfoColumnGroup'
import {
  getNominalVoltage,
  getPowerRating,
  getPowerRatingDC,
} from '@/model/resource'
import { TEXT_NO_VALUE } from '@/constants/formatText'

export default defineComponent({
  name: 'SiteDERTab',
  props: {
    siteResource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    solarDistributedResources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
    batteryDistributedResources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
    chargerResources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
  },
  components: {
    InfoColumn,
    CeDivider,
    InfoColumnGroup,
  },
  computed: {
    showGenerationInfoColumnGroup(): boolean {
      return !!this.generationInfoColumnGroup.length
    },
    showStorageInfoColumn(): boolean {
      return this.batteryDistributedResources.length > 0
    },
    showEvChargingColumnGroup(): boolean {
      return !!this.evChargingInfoColumnGroup.length
    },
    batteriesMaxCapacity(): number {
      return this.batteryDistributedResources.reduce(
        (acc, r) => acc + (r.ratings?.watthours ?? 0),
        0
      )
    },
    batteriesPowerCapacity(): number {
      return this.batteryDistributedResources.reduce(
        (acc, r) => acc + (getPowerRating(r) ?? 0),
        0
      )
    },
    generationInfoColumnGroup(): Group {
      return this.solarDistributedResources.map((r, idx) => {
        const voltage = getNominalVoltage(r)
        const powerAC = getPowerRating(r)
        const powerDC = getPowerRatingDC(r)

        return {
          id: `${idx}`,
          infoColumn: {
            // Adds title only in the first info column.
            title: idx === 0 ? 'Generation' : undefined,
            items: [
              {
                label: 'Type',
                // TODO(rafael): Since "Generation" only displays Solar Distributed resources for now,
                // always show "DPV". Later, when more types of resources available ("Wind"),
                // this string needs to be dynamic.
                text: 'DPV',
              },
              {
                label: 'Capacity (AC)',
                text: Format.fmtWatts(powerAC) || formatText(),
              },
              {
                label: 'Capacity (DC)',
                text: Format.fmtWatts(powerDC) || formatText(),
              },
              {
                label: 'Meter number',
                text: formatText(r?.meter?.electrical),
              },
              {
                label: 'Manufacturer',
                text: formatText(r?.manufacture?.brand),
              },
              {
                label: 'Voltage (nominal)',
                text: Format.fmtVolts(voltage) || formatText(),
              },
            ],
          },
        }
      })
    },
    evChargingInfoColumnGroup(): Group {
      return this.chargerResources.map((r, idx) => ({
        id: `${idx}`,
        infoColumn: {
          // Adds title only in the first info column.
          title: idx === 0 ? 'EV Charging' : undefined,
          items: ChargerHelper.leftPanelItems(r),
        },
      }))
    },
    storageInfoColumn(): InfoColumnAllProps {
      const maxEnergyCapacity = Format.fmtEnergy(this.batteriesMaxCapacity)
      const maxPowerCapacity = Format.fmtWatts(this.batteriesPowerCapacity)

      const brand = this.batteryDistributedResources[0]?.manufacture?.brand
      const model = this.batteryDistributedResources[0]?.manufacture?.model

      return {
        title: 'Storage',
        items: [
          {
            label: 'Power Capacity',
            text: maxPowerCapacity || TEXT_NO_VALUE,
          },
          {
            label: 'Energy Capacity',
            text: maxEnergyCapacity || TEXT_NO_VALUE,
            tooltipEnabled: true,
            tooltipText: `This value indicates the nameplate energy capacity of the battery 
              (actual available capacity decreases over the lifetime of the battery in response to time and use).`,
          },
          { label: 'Manufacturer', text: formatText(brand) },
          { label: 'Model', text: formatText(model) },
        ],
      }
    },
  },
})
</script>

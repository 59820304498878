import { DateTime } from 'luxon'
import { RittaConfig } from '@/config'
import { Services } from '@/services'
import { ChartType, ChartDefinition, SeriesConfig } from '@/types/charts'
import { LAGOON, MALIBU } from '@/constants/colors'
import {
  AggregateDataSource,
  ITimeSeriesDataSource,
  TimeSeriesDataSource,
} from '@/model/charts'
import { ResourceGroup } from 'rfs/frontend/proto/capacity_pb'
import { Metric } from '@/constants/metrics'
import { CHART_SIZE_BIG } from '@/utils/charts'

// Used to fetch all substations and aggregated as Net Load Forecast.
// It's a RegEx that is used in `ForecastDataRequest.resource_pattern.regex`.
const NET_LOAD_FORECAST_RESOURCE = '.*'

const actualSeriesConfig: SeriesConfig = {
  seriesName: 'Load (actual)',
  seriesColor: MALIBU.hex,
}

const forecastSeriesConfig: SeriesConfig = {
  seriesName: 'Load (forecast)',
  seriesColor: LAGOON.hex,
  seriesLine: 'dashed',
}

export function createNetLoadChart(
  config: Readonly<RittaConfig>
): ChartDefinition {
  const title = config.analysis?.forecastDashboard?.netLoadChart?.useVtl
    ? 'System load (VTL 182)'
    : 'Net load'
  return {
    title,
    id: 'forecast-net-load-chart',
    type: ChartType.PowerMW,
    height: CHART_SIZE_BIG,
  }
}

export function createNetLoadDataSource(
  config: Readonly<RittaConfig>,
  services: Services,
  chart: ChartDefinition,
  endDate: DateTime
): ITimeSeriesDataSource {
  const product =
    config?.analysis?.forecastDashboard?.netLoadChart?.forecastProduct

  if (!product) throw new Error('No forecast product configured')

  // VTL.
  if (config.analysis?.forecastDashboard?.netLoadChart?.useVtl) {
    const ds = new TimeSeriesDataSource(
      (request) =>
        services.forecastService.getForecastData(
          NET_LOAD_FORECAST_RESOURCE,
          product,
          request
        ),
      endDate
    )

    // Load (actual)
    ds.addChartSeries(chart, {
      metric: Metric.FORECAST_ACTUAL_COND_POWER_REAL,
      unit: 'W',
      config: actualSeriesConfig,
    })

    // Load (forecast)
    ds.addChartSeries(chart, {
      metric: Metric.FORECAST_LATEST_COND_POWER_REAL,
      unit: 'W',
      config: forecastSeriesConfig,
    })

    return ds
  }

  const actualDs = new TimeSeriesDataSource((request) =>
    services.capacityService.fetchSupplyDemandTimeSeries(
      ResourceGroup.DEMAND,
      request.interval
    )
  )

  // Load (actual)
  actualDs.addChartSeries(chart, {
    resource: 'demand/net',
    metric: Metric.COND_POWER_REAL,
    unit: 'W',
    config: actualSeriesConfig,
  })

  const forecastDs = new TimeSeriesDataSource(
    (request) =>
      services.forecastService.getForecastData(
        NET_LOAD_FORECAST_RESOURCE,
        product,
        request
      ),
    endDate
  )

  // Load (forecast)
  forecastDs.addChartSeries(chart, {
    metric: Metric.FORECAST_LATEST_COND_POWER_REAL,
    unit: 'W',
    config: forecastSeriesConfig,
  })

  return new AggregateDataSource(actualDs, forecastDs)
}

import * as Sentry from '@sentry/vue'
import { CeUser } from '@/model/user'

type Options = {
  includeEmail: true
}

export async function sentryCaptureMessage(
  msg: string,
  severity: Sentry.SeverityLevel,
  opts?: Options
): Promise<void> {
  if (opts?.includeEmail) {
    try {
      const userEmail = await CeUser.getUserEmail()
      msg = msg + '; user email: ' + userEmail
    } catch {
      msg = msg + '; could not log user email'
    }
  }
  if (import.meta.env.PROD) {
    Sentry.captureMessage(msg, severity)
  } else {
    console.error(msg)
  }
}

// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file device/proto/proxy.proto (package ritta.device.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3, Timestamp } from "@bufbuild/protobuf";
import { Device } from "../../control/proto/model_pb.js";
import { Resource } from "../../pb/resource_pb.js";

/**
 * These metrics are used for both telemetry and command signals
 * NEXT TAG: 48
 *
 * @generated from enum ritta.device.proto.Metric
 */
export const Metric = /*@__PURE__*/ proto3.makeEnum(
  "ritta.device.proto.Metric",
  [
    {no: 0, name: "METRIC_UNSPECIFIED"},
    {no: 1, name: "METRIC_ACTIVE_POWER_WATTS"},
    {no: 2, name: "METRIC_VOLTAGE_VOLTS"},
    {no: 3, name: "METRIC_SOC_WATT_HOURS"},
    {no: 4, name: "METRIC_FREQUENCY_HZ"},
    {no: 5, name: "METRIC_REACTIVE_POWER_VARS"},
    {no: 6, name: "METRIC_POWER_STATE"},
    {no: 7, name: "METRIC_APPARENT_POWER_VAS"},
    {no: 16, name: "METRIC_ACTIVE_POWER_SETPOINT_PERCENT"},
    {no: 18, name: "METRIC_SOC_PERCENT"},
    {no: 19, name: "METRIC_MAX_DISCHARGE_POWER_WATTS"},
    {no: 20, name: "METRIC_MAX_CHARGE_POWER_WATTS"},
    {no: 42, name: "METRIC_MAX_SOC_WATT_HOURS"},
    {no: 21, name: "METRIC_ENERGY_DELIVERED_WATT_HOURS"},
    {no: 22, name: "METRIC_ENERGY_RECEIVED_WATT_HOURS"},
    {no: 8, name: "METRIC_VOLTAGE_AB"},
    {no: 9, name: "METRIC_VOLTAGE_BC"},
    {no: 10, name: "METRIC_VOLTAGE_CA"},
    {no: 11, name: "METRIC_CURRENT_A"},
    {no: 12, name: "METRIC_CURRENT_B"},
    {no: 13, name: "METRIC_CURRENT_C"},
    {no: 14, name: "METRIC_PV_VOLTAGE"},
    {no: 15, name: "METRIC_PV_CURRENT"},
    {no: 17, name: "METRIC_GRID_CONNECTED"},
    {no: 23, name: "SUNSPEC_E_BaseMet_Air_Temperature_C"},
    {no: 24, name: "SUNSPEC_E_BaseMet_Relative_Humidity_Percent"},
    {no: 25, name: "SUNSPEC_E_BaseMet_Barometric_Pressure_HPa"},
    {no: 26, name: "SUNSPEC_E_BaseMet_Wind_Speed_mps"},
    {no: 27, name: "SUNSPEC_E_BaseMet_Wind_Direction_Degrees"},
    {no: 28, name: "SUNSPEC_E_BaseMet_Rain_mm"},
    {no: 29, name: "SUNSPEC_E_BaseMet_Snow_mm"},
    {no: 30, name: "SUNSPEC_E_BaseMet_PPT_Type"},
    {no: 31, name: "SUNSPEC_E_BaseMet_Electric_Field_Vpm"},
    {no: 32, name: "SUNSPEC_E_BaseMet_Surface_Wetness_kOhms"},
    {no: 33, name: "SUNSPEC_E_BaseMet_Soil_Moisture_Percent"},
    {no: 34, name: "SUNSPEC_E_Irradiance_Global_Horizontal_Wpm2"},
    {no: 35, name: "SUNSPEC_E_Irradiance_Planeof_Array_Wpm2"},
    {no: 36, name: "SUNSPEC_E_Irradiance_Diffuse_Wpm2"},
    {no: 37, name: "SUNSPEC_E_Irradiance_Direct_Wpm2"},
    {no: 38, name: "SUNSPEC_E_Irradiance_Other_Wpm2"},
    {no: 39, name: "SUNSPEC_E_BOM_Temp_C"},
    {no: 40, name: "METRIC_SITE_ACTIVE_POWER_WATTS"},
    {no: 41, name: "METRIC_SOLAR_ACTIVE_POWER_WATTS"},
    {no: 43, name: "METRIC_MIN_SOC_WATT_HOURS"},
    {no: 44, name: "METRIC_LIMITS_RAMP_MAX"},
    {no: 45, name: "METRIC_LIMITS_RAMP_MIN"},
    {no: 46, name: "METRIC_ENVELOPE_POWER_CONSUMING"},
    {no: 47, name: "METRIC_ENVELOPE_POWER_PRODUCING"},
    {no: 48, name: "METRIC_CONTROLS_COMMANDABLE"},
  ],
);

/**
 * NEXT TAG: 15
 *
 * @generated from enum ritta.device.proto.CommandStatus
 */
export const CommandStatus = /*@__PURE__*/ proto3.makeEnum(
  "ritta.device.proto.CommandStatus",
  [
    {no: 0, name: "__DO_NOT_USE"},
    {no: 1, name: "COMMAND_STATUS_SUCCESS"},
    {no: 2, name: "COMMAND_STATUS_DEVICE_NOT_FOUND"},
    {no: 3, name: "COMMAND_STATUS_UNSUPPORTED_METRIC"},
    {no: 4, name: "COMMAND_STATUS_INVALID_VALUE"},
    {no: 5, name: "COMMAND_STATUS_INVALID_TIMES"},
    {no: 6, name: "COMMAND_STATUS_NO_CONNECTION_TO_DEVICE"},
    {no: 7, name: "COMMAND_STATUS_DEVICE_TIMEOUT"},
    {no: 8, name: "COMMAND_STATUS_DEVICE_UNEXPECTED_BEHAVIOUR"},
    {no: 9, name: "COMMAND_STATUS_UNKNOWN"},
    {no: 10, name: "COMMAND_STATUS_VALIDATION_ERROR"},
    {no: 11, name: "COMMAND_STATUS_VALIDATION_OK"},
    {no: 12, name: "COMMAND_STATUS_NOT_ATTEMPTED"},
    {no: 13, name: "COMMAND_STATUS_UNSUPPORTED_DURATION"},
    {no: 14, name: "COMMAND_STATUS_UNSUPPORTED_START_TIME"},
    {no: 15, name: "COMMAND_STATUS_UNSUPPORTED_COMMAND_TYPE"},
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.device.proto.GetDeviceRequest
 */
export const GetDeviceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.GetDeviceRequest",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.device.proto.AllDevices
 */
export const AllDevices = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.AllDevices",
  () => [
    { no: 1, name: "devices", kind: "message", T: Device, repeated: true },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.device.proto.TelemetryResponse
 */
export const TelemetryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.TelemetryResponse",
  () => [
    { no: 1, name: "measurements", kind: "message", T: Measurement, repeated: true },
  ],
);

/**
 * NEXT TAG: 5
 *
 * @generated from message ritta.device.proto.Measurement
 */
export const Measurement = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.Measurement",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "metric", kind: "enum", T: proto3.getEnumType(Metric) },
    { no: 4, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
);

/**
 * NEXT TAG: 7
 *
 * @generated from message ritta.device.proto.Command
 */
export const Command = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.Command",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metric", kind: "enum", T: proto3.getEnumType(Metric) },
    { no: 3, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "duration", kind: "message", T: Duration, opt: true },
    { no: 5, name: "start_time", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "command_type", kind: "enum", T: proto3.getEnumType(Command_CommandType) },
  ],
);

/**
 * @generated from enum ritta.device.proto.Command.CommandType
 */
export const Command_CommandType = /*@__PURE__*/ proto3.makeEnum(
  "ritta.device.proto.Command.CommandType",
  [
    {no: 0, name: "__DO_NOT_USE"},
    {no: 1, name: "COMMAND_TYPE_INSTANT_COMMAND"},
    {no: 2, name: "COMMAND_TYPE_RESTORE_LOCAL_CONTROL"},
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.device.proto.CommandStatusResponse
 */
export const CommandStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.CommandStatusResponse",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CommandStatus) },
  ],
);

/**
 * NEXT TAG: 7
 * Used by the proxy/archiver only
 *
 * @generated from message ritta.device.proto.BufferDataResponse
 */
export const BufferDataResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.BufferDataResponse",
  () => [
    { no: 1, name: "format", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fields", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */, repeated: true },
    { no: 4, name: "count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "start_time", kind: "message", T: Timestamp },
    { no: 6, name: "end_time", kind: "message", T: Timestamp },
  ],
);

/**
 * A command for the device to execute during the specified time window.
 * NEXT TAG: 5
 *
 * @generated from message ritta.device.proto.DeviceScheduleCommand
 */
export const DeviceScheduleCommand = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.DeviceScheduleCommand",
  () => [
    { no: 1, name: "metric", kind: "enum", T: proto3.getEnumType(Metric) },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 4, name: "end_time", kind: "message", T: Timestamp },
  ],
);

/**
 * A list of scheduled commands
 * NEXT TAG: 3
 *
 * @generated from message ritta.device.proto.DeviceSchedule
 */
export const DeviceSchedule = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.DeviceSchedule",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "commands", kind: "message", T: DeviceScheduleCommand, repeated: true },
    { no: 3, name: "validate_only", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * A Response to a Scheduled command, includes the command which the status
 * corresponds to for the caller's convenience
 * NEXT TAG: 4
 *
 * @generated from message ritta.device.proto.DeviceScheduleCommandResponse
 */
export const DeviceScheduleCommandResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.DeviceScheduleCommandResponse",
  () => [
    { no: 1, name: "command", kind: "message", T: DeviceScheduleCommand },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(CommandStatus) },
    { no: 3, name: "messages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * A list of schedule responses
 * NEXT TAG: 3
 *
 * @generated from message ritta.device.proto.DeviceScheduleResponse
 */
export const DeviceScheduleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.DeviceScheduleResponse",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "results", kind: "message", T: DeviceScheduleCommandResponse, repeated: true },
  ],
);

/**
 * NEXT TAG: 4
 *
 * @generated from message ritta.device.proto.DeviceScheduleTimeRange
 */
export const DeviceScheduleTimeRange = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.DeviceScheduleTimeRange",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start_time", kind: "message", T: Timestamp },
    { no: 3, name: "end_time", kind: "message", T: Timestamp },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.device.proto.GetStreamingResourceMeasurementsRequest
 */
export const GetStreamingResourceMeasurementsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.GetStreamingResourceMeasurementsRequest",
  () => [
    { no: 1, name: "device_id_pattern", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message ritta.device.proto.GetResourcesRequest
 */
export const GetResourcesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.GetResourcesRequest",
  [],
);

/**
 * @generated from message ritta.device.proto.GetResourcesResponse
 */
export const GetResourcesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.GetResourcesResponse",
  () => [
    { no: 1, name: "resources", kind: "message", T: Resource, repeated: true },
  ],
);


import { DateTime } from 'luxon'
import { routeForResource } from '@/router/routeForResource'
import { createNotificationRoute } from '@/utils/router/create'
import { titleCase } from '@/utils/formatText'
import type {
  DataTable,
  Header,
  Filters,
  FilterDate,
  FilterDropDown,
  FilterMultiSelect,
} from '@/model/tables/DataTable'
import { newFilterFieldsMinMax } from '@/model/tables/helper'
import { ColumnType } from '@/model/tables/column'
import { getResourceType } from '@/model/resource'
import {
  getResponseLabel,
  getSeverityLabel,
  getStateLabel,
  getThingAffected,
} from '@/model/notification/formatting'
import {
  type Notification,
  Notification_Response as NotificationResponse,
  Notification_Severity as NotificationSeverity,
  Notification_State as NotificationState,
} from 'rfs/frontend/proto/notification_pb'
import { Resource } from 'rfs/pb/resource_pb'

export type NotificationsDataTable = DataTable<Notification>

export function createHeaders(): Header<Notification>[] {
  const timezoneSuffix = DateTime.now().toFormat('(ZZZZ)')

  return [
    {
      title: 'ID',
      key: 'id',
      routeFactory: (_config, row) => createNotificationRoute(row.id),
      columnSelector: { required: true },
    },
    {
      title: 'Condition',
      key: 'condition',
    },
    {
      title: 'Response',
      key: 'response',
      value: (row) => getResponseLabel(row.response),
    },
    {
      title: 'Resource',
      key: 'thingAffected',
      value: (row) => getThingAffected(row),
      routeFactory: (_config, row) => {
        if (!row.resourceId) return undefined

        return routeForResource(
          new Resource({
            id: row.resourceId,
            type: getResourceType(row.resourceId),
          })
        )
      },
    },
    {
      title: 'Severity',
      key: 'severity',
      value: (row) => getSeverityLabel(row.severity),
    },
    {
      title: 'Downline DERs',
      key: 'downlineDers',
      valueType: ColumnType.INTEGER,
    },
    {
      title: `Start time ${timezoneSuffix}`,
      key: 'startTime',
      valueType: ColumnType.DATETIME,
    },
    {
      title: `End time ${timezoneSuffix}`,
      key: 'endTime',
      valueType: ColumnType.DATETIME,
    },
    {
      title: 'State',
      key: 'state',
      sortable: false, // Because the state is dependent on the current time
      value: (row) => getStateLabel(row.state),
    },
    {
      title: 'Read',
      key: 'read',
      value: (row) => titleCase(row.read ? 'yes' : 'no'),
    },
  ]
}

function newFilterResponse(): FilterMultiSelect<NotificationResponse> {
  return {
    type: 'multiselect',
    items: [
      {
        title: getResponseLabel(NotificationResponse.ALERT),
        value: NotificationResponse.ALERT,
      },
      {
        title: getResponseLabel(NotificationResponse.CONSTRAINT),
        value: NotificationResponse.CONSTRAINT,
      },
    ],
    value: [],
  }
}

function newFilterSeverity(): FilterMultiSelect<NotificationSeverity> {
  return {
    type: 'multiselect',
    items: [
      {
        title: getSeverityLabel(NotificationSeverity.LOW),
        value: NotificationSeverity.LOW,
      },
      {
        title: getSeverityLabel(NotificationSeverity.MEDIUM),
        value: NotificationSeverity.MEDIUM,
      },
      {
        title: getSeverityLabel(NotificationSeverity.HIGH),
        value: NotificationSeverity.HIGH,
      },
    ],
    value: [],
  }
}

function newFilterStartTime(): FilterDate {
  return { type: 'date', label: 'Start Time', value: null }
}

function newFilterEndTime(): FilterDate {
  return { type: 'date', label: 'End Time', value: null }
}

function newFilterState(): FilterMultiSelect<NotificationState> {
  return {
    type: 'multiselect',
    items: [
      {
        title: getStateLabel(NotificationState.ACTIVE),
        value: NotificationState.ACTIVE,
      },
      {
        title: getStateLabel(NotificationState.INACTIVE),
        value: NotificationState.INACTIVE,
      },
      {
        title: getStateLabel(NotificationState.FORECASTED),
        value: NotificationState.FORECASTED,
      },
    ],
    value: [],
  }
}

function newFilterRead(): FilterDropDown<boolean> {
  return {
    type: 'dropdown',
    items: [
      { title: 'No', value: false },
      { title: 'Yes', value: true },
    ],
    value: null,
  }
}

export function createPristineFilters(): Filters {
  return (
    new Map()
      // .set('condition', newFilterCondition())
      .set('response', newFilterResponse())
      .set('severity', newFilterSeverity())
      .set('downlineDers', newFilterFieldsMinMax())
      .set('startTime', newFilterStartTime())
      .set('endTime', newFilterEndTime())
      .set('state', newFilterState())
      .set('read', newFilterRead())
  )
}

export function createFiltersForNotificationsMenu(): Filters {
  const filterRead = newFilterRead()
  filterRead.value = false
  return new Map().set('read', filterRead)
}

import { DateTime, Interval } from 'luxon'

import { Metric } from '@/constants/metrics'
import { RittaConfig } from '@/config/types'
import { ResourceType } from '@/constants/resourceType'
import { Options, siteChartsDataProviderOptions } from '@/constants/siteCharts'
import {
  AggregateDataSource,
  FixedTimeDataSource,
  ITimeSeriesDataSource,
  TimeSeriesDataSource,
} from '@/model/charts'
import { addForecastInterval } from '@/model/forecast'
import {
  getDisplayName,
  hasProductionMeter,
  hasReferenceName,
  isDetectedResource,
  isSiteMeter,
} from '@/model/resource'
import { getVoltageMetrics } from '@/model/resource/TelemetryChartData'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { Config_Phasing as Phasing, Resource } from 'rfs/pb/resource_pb'
import { Services } from '@/services'
import { ChartsService } from '@/services/charts.service'
import { ChartDefinition, SeriesConfig } from '@/types/charts'

/**
 * Powerwall and Charger time series have very high resolution raw data;
 * powerwall data is in 45sec intervals, Chargepoint is every minute.
 * That is too many data points to chart efficiently, and we want the data to align
 * with 15min (or 1hr) AMI data.
 */
const MIN_DATA_RESOLUTION = Resolution.FIVE_MINUTE

function formatConfig(
  name: keyof Options,
  meterId: string | undefined
): SeriesConfig {
  const config = siteChartsDataProviderOptions[name] as SeriesConfig
  if (meterId) {
    return { ...config, seriesName: `${config.seriesName} (${meterId})` }
  } else {
    return config
  }
}

/**
 * Return a TimeSeriesDataSource that is configured to show the following:
 * - AMI Consumption
 * - AMI Production (via distributed solar)
 * - Battery Telemetry
 * - Solar Telemetry
 * - Charger Telemetry
 */
export function newSiteChartDataSource(
  chartDefinitions: ChartDefinition[],
  siteResource: Resource,
  otherResources: Resource[],
  services: Services,
  config: Readonly<RittaConfig>
): ITimeSeriesDataSource {
  const { chartsService } = services
  const meter = otherResources.find((r) => isSiteMeter(siteResource, r))
  const solar = solarDistributedResources(otherResources)
  const battery = batteryDistributedResource(otherResources)
  const [powerChart, voltageChart, storageChart] = chartDefinitions
  const evChargers = evChargerResources(otherResources)

  // nothing to do if site has no meter
  if (meter?.id == null) return TimeSeriesDataSource.emptyDataSource()

  let tsDataSource: ITimeSeriesDataSource

  // Configure the data source to retrieve AMI consumption and voltage for the meter
  const meterDataSource = new TimeSeriesDataSource((request) => {
    return chartsService.fetchTimeSeries(meter.id, request)
  })
  meterDataSource.addChartSeries(powerChart, {
    metric: Metric.POWER_CONSUMED_NET,
    unit: 'W',
    config: formatConfig('amiConsumptionConfig', meter.meter?.electrical),
  })
  // 3-phase meters show the individual phases; not all phases have the same nominal voltage.
  getVoltageMetrics(meter, Phasing.UNSPECIFIED).forEach((tsc) => {
    if (tsc.metric === Metric.COND_VOLTAGE) {
      tsc.config = formatConfig('voltageConfig', meter.meter?.electrical)
    }
    meterDataSource.addChartSeries(voltageChart, tsc)
  })

  tsDataSource = meterDataSource

  // Forecasting data sources
  if (config.monitor?.site?.forecasts?.enabledAMI) {
    tsDataSource = new AggregateDataSource(
      tsDataSource,
      forecastAMIDataSources(meter, powerChart, chartsService, config)
    )
  }
  if (config.monitor?.site?.forecasts?.enabledPV) {
    tsDataSource = new AggregateDataSource(
      tsDataSource,
      ...forecastPVDataSources(solar, powerChart, chartsService, config)
    )
  }

  // AMI Production data sources
  const solarDataSources = solar.map((solar) => {
    const pvDataSource = new TimeSeriesDataSource((request) => {
      return chartsService.fetchTimeSeries(solar.id, request)
    })
    if (hasProductionMeter(solar, siteResource)) {
      // Solar with an AMI meter has `energy.produced` in Bigtable
      pvDataSource.addChartSeries(powerChart, {
        metric: Metric.POWER_PRODUCED,
        config: formatConfig('amiProductionConfig', solar.meter!.electrical),
      })
    } else if (hasReferenceName(solar, 'pi.id')) {
      // Solar with PI data has power data in Bigtable
      pvDataSource.addChartSeries(powerChart, {
        metric: Metric.COND_DOWNLINE_GEN_POWER_REAL,
        config: formatConfig('unmeteredProductionConfig', ''),
      })
    }
    return pvDataSource
  })
  if (solarDataSources.length) {
    tsDataSource = new AggregateDataSource(tsDataSource, ...solarDataSources)
  }

  // Distributed Battery data sources
  if (battery) {
    const { batteryConfig } = siteChartsDataProviderOptions
    const batDataSource = new TimeSeriesDataSource((request) => {
      request.resolution = MIN_DATA_RESOLUTION
      return chartsService.fetchTimeSeries(battery.id, request)
    })
    batDataSource.addChartSeries(powerChart, {
      metric: Metric.SENSOR_SOLAR_POWER_REAL,
      unit: '-W',
      config: batteryConfig[Metric.SENSOR_SOLAR_POWER_REAL],
    })
    batDataSource.addChartSeries(powerChart, {
      metric: Metric.COND_POWER_REAL,
      config: batteryConfig[Metric.COND_POWER_REAL],
    })
    batDataSource.addChartSeries(powerChart, {
      metric: Metric.SENSOR_SITE_POWER_REAL,
      config: batteryConfig[Metric.SENSOR_SITE_POWER_REAL],
    })
    batDataSource.addChartSeries(storageChart, {
      metric: Metric.COND_CHARGE_LEVEL,
      config: batteryConfig[Metric.COND_CHARGE_LEVEL],
    })
    tsDataSource = new AggregateDataSource(tsDataSource, batDataSource)
  }

  // EV Chargers
  const chargerDataSources = evChargers.map((evc) => {
    const evChargersDataSource = new TimeSeriesDataSource((request) => {
      request.resolution = MIN_DATA_RESOLUTION
      return chartsService.fetchTimeSeries(evc.id, request)
    })
    const config = formatConfig('evChargerConfig', getDisplayName(evc))
    if (isDetectedResource(evc)) {
      config.seriesLine = 'dashed'
    }
    return evChargersDataSource.addChartSeries(powerChart, {
      metric: Metric.COND_POWER_REAL,
      config,
    })
  })
  if (chargerDataSources.length) {
    tsDataSource = new AggregateDataSource(tsDataSource, ...chargerDataSources)
  }

  return tsDataSource
}

function forecastAMIDataSources(
  meter: Resource,
  powerChart: ChartDefinition,
  chartsService: ChartsService,
  config: Readonly<RittaConfig>
) {
  // Forecasts uses a fixed 21-day interval
  const interval = addForecastInterval(bestForecastInterval(), config)

  const meterForecast = new FixedTimeDataSource(interval, (request) =>
    chartsService.fetchTimeSeries(meter.id, request)
  )
  return meterForecast.addChartSeries(powerChart, {
    metric: Metric.FORECAST_POWER_CONSUMED_NET,
    config: formatConfig('amiForecastConfig', meter.meter?.electrical),
  })
}

function forecastPVDataSources(
  solar: Resource[],
  powerChart: ChartDefinition,
  chartsService: ChartsService,
  config: Readonly<RittaConfig>
) {
  const interval = addForecastInterval(bestForecastInterval(), config)

  return solar.map((solar) => {
    const dataSource = new FixedTimeDataSource(interval, (request) =>
      chartsService.fetchTimeSeries(solar.id, request)
    )
    // All solar uses the non-AMI forecast metric, whether or not a production meter is present
    return dataSource.addChartSeries(powerChart, {
      metric: Metric.FORECAST_LATEST_COND_POWER_REAL,
      unit: '-W',
      config: formatConfig('unmeteredForecastConfig', ''),
    })
  })
}

function solarDistributedResources(otherResources: Resource[]) {
  return otherResources.filter((r) => r.type === ResourceType.SOLAR_DISTRIBUTED)
}

function batteryDistributedResource(otherResources: Resource[]) {
  return otherResources.find((r) => r.type === ResourceType.BATTERY_DISTRIBUTED)
}

function evChargerResources(otherResources: Resource[]) {
  return otherResources.filter((r) => r.type === ResourceType.CHARGER)
}

function bestForecastInterval(dt = DateTime.now()): Interval {
  return Interval.fromDateTimes(dt.minus({ days: 21 }), dt)
}

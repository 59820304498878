import { type DateTime, Interval } from 'luxon'
import type { RittaConfig } from '@/config'
import type { Services } from '@/services'
import type { TimeSeriesFetch } from '@/services/charts.service'
import type { Group } from 'rfs/control/proto/model_pb'
import type { TimeSeries } from 'rfs/frontend/proto/tsdb_pb'

export enum CommunicationStatusType {
  UNSPECIFIED = 0,
  GOOD = 1,
  UNREACHABLE = 2,
}

export function getCommunicationStatusLabel(
  type: CommunicationStatusType
): string {
  switch (type) {
    case CommunicationStatusType.GOOD:
      return 'Good'
    case CommunicationStatusType.UNREACHABLE:
      return 'Unreachable'
    default:
      return 'UNSPECIFIED'
  }
}

export type CommunicationStatusMap = Map<
  // Resource ID
  string,
  CommunicationStatusType
>

export function isCommunicationStatusEnabled(
  config: Readonly<RittaConfig>,
  group?: null | Group
): boolean {
  const groupId = group?.id
  const groupIds = config.control?.communicationStatus?.groupIds ?? []
  return Boolean(groupId && groupIds.includes(groupId))
}

function createFetchRequest(now: DateTime): TimeSeriesFetch {
  return {
    interval: Interval.fromDateTimes(now.minus({ minutes: 5 }), now),
    metrics: [{ metric: 'controls.commandable' }],
  }
}

function getStatusFromTimeSeries(
  series: undefined | TimeSeries
): CommunicationStatusType {
  const lastDp = series?.data?.at(-1)
  const lastValue = lastDp?.y
  return lastValue === 1
    ? CommunicationStatusType.GOOD
    : CommunicationStatusType.UNREACHABLE
}

export async function fetchCurrentCommunicationStatus(
  services: Services,
  resourceId: string,
  now: DateTime
): Promise<CommunicationStatusType> {
  let result = CommunicationStatusType.UNSPECIFIED

  try {
    const res = await services.chartsService.fetchTimeSeries(
      resourceId,
      createFetchRequest(now)
    )
    result = getStatusFromTimeSeries(res.series[0])
  } catch (err) {
    console.error(
      `fetchCurrentCommunicationStatus: something happen for "${resourceId}"`,
      err
    )
  }

  return result
}

export async function fetchMultiCurrentCommunicationStatus(
  services: Services,
  resourceIds: string[],
  now: DateTime
): Promise<CommunicationStatusMap> {
  const newMap: CommunicationStatusMap = new Map()

  try {
    const { series } = await services.chartsService.fetchMultiTimeSeries(
      resourceIds,
      createFetchRequest(now)
    )

    for (const s of series) {
      if (!s.resource) continue
      newMap.set(s.resource, getStatusFromTimeSeries(s))
    }
  } catch (err) {
    console.error('fetchMultiCurrentCommunicationStatus: %o', err)
  }

  return newMap
}

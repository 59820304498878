import { Resource, Site_Function as F, Genesis } from 'rfs/pb/resource_pb'
import type { RittaConfig } from '@/config'
import { ResourceType } from '@/constants/resourceType'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Format } from '@/utils/format'
import { createFeederRoute, createSubstationRoute } from '@/utils/router/create'
import type { HeaderItem } from '@/model/types'
import { formatPhase } from './conductor/phase'

/** Given a resource type and identifier, return the canonical resource ID. */
export function makeId(rtype: ResourceType, id: string): string {
  // Don't format the ID multiple times
  return id.startsWith(rtype + '/') ? id : `${rtype}/${id}`
}

/** Given a resource ID, return the resource type. */
export function getResourceType(id: string): ResourceType | undefined {
  return Object.values(ResourceType).find((rtype) => id.startsWith(rtype))
}

/**
 * Extracts the `id` part of the fully-qualified `{type}/{id}` resource ID.
 * If the ID is not fully-qualified, it is returned as is.
 * It's expected that "<resource>.type" is always available.
 * @returns {string} The `id` without the leading `type`
 * @see https://docs.google.com/document/d/1gNYTFppY9VPKNem3rhRXnBPz3bBGfZ18ulWKwaUI-vE/view#heading=h.40rfuqcirhvj
 */
export function getUnqualifiedId(resource: Resource | string): string {
  if (typeof resource === 'string') {
    // Remove the resource type prefix and return the ID
    return resource.split('/').at(-1) ?? ''
  } else if (resource.id.startsWith(resource.type)) {
    return resource.id.substring(resource.type.length + 1)
  } else {
    return resource.id
  }
}

/**
 * Returns a display name for the resource, which is usually the title.
 * If the resource has no title, applicant, or GIS element, the unqualified ID is used.
 */
export function getDisplayName(resource: Resource): string {
  const applicant = resource.project?.applicant
  const title = resource.title
  const gisElement = resource.gis?.element

  // The title will be an empty string if it's missing
  return applicant || title || gisElement || getUnqualifiedId(resource)
}

/**
 * Returns readable string for site function.
 */
export function getSiteFunction(resource: Resource): string | undefined {
  let siteFunction

  if (resource.site) {
    switch (resource.site.function) {
      case F.UNSPECIFIED:
        siteFunction = 'Unspecified'
        break
      case F.RESIDENTIAL:
        siteFunction = 'Residential'
        break
      case F.COMMERCIAL:
        siteFunction = 'Commercial'
        break
      case F.INDUSTRIAL:
        siteFunction = 'Industrial'
        break
      case F.AGRICULTURAL:
        siteFunction = 'Agricultural'
        break
      case F.STREETLIGHT:
        siteFunction = 'Streetlight'
        break
      case F.NONRESIDENTIAL:
        siteFunction = 'Non-Residential'
        break
      case F.COMMERCIAL_INDUSTRIAL:
        siteFunction = 'Commercial-Industrial'
        break
      default:
        console.error(`Unknown site function '${resource.site.function}'`)
    }
  }

  return siteFunction
}

/**
 * Returns the operating voltage for a resource.
 * Note that this differs from the rated voltage because rated voltage is a maximum
 * supported value, whereas nominal voltage is the expected operating voltage.
 */
export function getNominalVoltage(r: Resource | undefined): number | undefined {
  // Zero is not a valid nominal voltage
  return r?.config?.voltage || undefined
}

/**
 * Return the (active) power rating for a given resource.
 * This is AC power.
 */
export function getPowerRating(resource?: Resource): number | undefined {
  const rating = resource?.ratings
  return rating?.power?.real ?? rating?.wattage
}

/**
 * Return the DC power rating for a given resource (if any).
 */
export function getPowerRatingDC(resource: Resource): number | undefined {
  const summary = resource.components?.summary
  return summary?.cells?.power ?? summary?.modules?.power
}

/**
 * Does the resource contain a reference including the given name
 */
export function hasReferenceName(resource: Resource, ref: string): boolean {
  return resource.reference.some((str) => str.name.includes(ref))
}

/**
 * A resource has a production meter if it has a meter and that meter
 * is not the same as the site's meter.
 */
export function hasProductionMeter(rsrc: Resource, site: Resource): boolean {
  const meter = rsrc.meter?.electrical
  return !!meter && meter !== site.meter?.electrical
}

/**
 * Is the resource detected by Camus algorithms,
 * instead of being provded by the utility in GIS records?
 */
export function isDetectedResource(resource: Resource): boolean {
  return resource.record?.genesis === Genesis.DETECTION
}

/**
 * Is the resource a customer or utility site?
 */
export function isSite(r: Resource): boolean {
  return (
    r.type === ResourceType.SITE_CUSTOMER ||
    r.type === ResourceType.SITE_UTILITY
  )
}

/**
 * Is the given resource the site's electrical meter?
 */
export function isSiteMeter(site: Resource, r: Resource): boolean {
  return (
    r.type === ResourceType.METER_ELECTRICAL &&
    r.meter?.electrical === site.meter?.electrical
  )
}

const midlineTypes = new Set([
  ResourceType.SWITCH,
  ResourceType.SECTIONALIZER,
  ResourceType.REGULATOR,
  ResourceType.RECLOSER,
  ResourceType.FUSE,
  ResourceType.CAPACITOR,
  ResourceType.BREAKER,
  ResourceType.TRANSFORMER,
])

/**
 * Is the given resource a device between the feeder header and a meter?
 */
export function isMidlineDevice(r: Resource): boolean {
  return midlineTypes.has(r.type as ResourceType)
}

/**
 * Does the resource have storage metrics?
 */
export function isStorageResource(r: Resource): boolean {
  return (
    r.type === ResourceType.BATTERY_DISTRIBUTED ||
    r.type === ResourceType.BATTERY_STATION
  )
}

function formatRatingApparent(r?: Resource): string {
  const power = r?.ratings?.power
  return power ? Format.fmtApparentPower(power.apparent) : ''
}

export function createHeaderItems(r?: Resource): HeaderItem[] {
  const items: HeaderItem[] = []

  // Substation.
  const unqualifiedSubstationId = r?.upline?.substation
  if (unqualifiedSubstationId) {
    items.push({
      id: 'Substation',
      label: 'Substation',
      content: [
        {
          text: unqualifiedSubstationId,
          to: createSubstationRoute(unqualifiedSubstationId),
        },
      ],
    })
  }

  // Feeder.
  const unqualifiedFeederId = r?.upline?.feeder
  if (unqualifiedFeederId) {
    items.push({
      id: 'Feeder',
      label: 'Feeder',
      content: [
        {
          text: unqualifiedFeederId,
          to: createFeederRoute(unqualifiedFeederId),
        },
      ],
    })
  }

  // Phase. Some devices (e.g. substations, feeders) dont have a phase.
  if (formatPhase(r)) {
    items.push({
      id: 'Phase',
      label: 'Phase',
      content: [{ text: formatPhase(r) }],
    })
  }

  // Rating (Apparent Power)
  items.push({
    id: 'Rating',
    label: 'Rating',
    content: [{ text: formatRatingApparent(r) || TEXT_NO_VALUE }],
  })

  return items
}

/**
 * Resource type to display name mapping.
 */
export function getResourceTypeDisplayName(type: ResourceType): string {
  switch (type) {
    case ResourceType.BATTERY_DISTRIBUTED:
      return 'Distributed Battery'
    case ResourceType.BATTERY_STATION:
      return 'Utility Battery'
    case ResourceType.BIOMASS:
      return 'Biomass'
    case ResourceType.BREAKER:
      return 'Breaker'
    case ResourceType.CAPACITOR:
      return 'Capacitor'
    case ResourceType.CHARGER:
      return 'Charger'
    case ResourceType.CONDUCTOR:
      return 'Conductor'
    case ResourceType.FEEDER:
      return 'Feeder'
    case ResourceType.FUSE:
      return 'Fuse'
    case ResourceType.GENERATION:
      return 'Generation'
    case ResourceType.HYDRO:
      return 'Hydro'
    case ResourceType.METER_ELECTRICAL:
      return 'Meter'
    case ResourceType.METHANE:
      return 'Methane'
    case ResourceType.MICROGRID:
      return 'Microgrid'
    case ResourceType.PROVIDER:
      return 'Provider'
    case ResourceType.RECLOSER:
      return 'Recloser'
    case ResourceType.REGULATOR:
      return 'Regulator'
    case ResourceType.SECTIONALIZER:
      return 'Sectionalizer'
    case ResourceType.SENSOR_CONDUCTOR:
      return 'Conductor Sensor'
    case ResourceType.SENSOR_ELECTRICAL:
      return 'Electrical Sensor'
    case ResourceType.SITE_CUSTOMER:
      return 'Customer Site'
    case ResourceType.SITE_UTILITY:
      return 'Utility Site'
    case ResourceType.SOLAR_DISTRIBUTED:
      return 'Distributed Solar'
    case ResourceType.SOLAR_FARM:
      return 'Utility Solar'
    case ResourceType.SUBSCRIPTION_ELECTRICAL:
      return 'Electrical Subscription'
    case ResourceType.SUBSTATION:
      return 'Substation'
    case ResourceType.SWITCH:
      return 'Switch'
    case ResourceType.TRANSFORMER:
      return 'Transformer'
    case ResourceType.VEHICLE:
      return 'Vehicle'
    case ResourceType.WIND:
      return 'Wind'
    case ResourceType.ZONE_VOLTAGE:
      return 'Voltage Zone'
    default:
      return type
  }
}

// NOTE: The order of the tabs here controls the order of the tabs in the UI.
export enum ResourceTab {
  IMPACTS = 'impacts',
  TELEMETRY = 'telemetry',
  OPERATIONS = 'operations',
  GRID = 'grid',
}

const resourceTabLabel: Record<ResourceTab, string> = {
  [ResourceTab.TELEMETRY]: 'Telemetry',
  [ResourceTab.IMPACTS]: 'Impacts',
  [ResourceTab.OPERATIONS]: 'Operations',
  [ResourceTab.GRID]: 'Grid',
}

export function getResourceTabLabel(tab: ResourceTab): string {
  return resourceTabLabel[tab]
}

export function hasResourceTabEnabled(
  tab: ResourceTab,
  resource: Resource,
  config: Readonly<RittaConfig>
): boolean {
  switch (tab) {
    case ResourceTab.TELEMETRY:
      if (resource.device?.sensor) {
        return true // A device with a sensor has telemetry
      }
      return config.monitor?.telemetry?.includes(resource.type) ?? false
    case ResourceTab.OPERATIONS:
      return config.monitor?.operations?.includes(resource.type) ?? false
    case ResourceTab.IMPACTS:
      return config.gridImpact?.resourceTypes?.includes(resource.type) ?? false
    case ResourceTab.GRID:
      // TODO(rafael): for now the "Grid" tab should not exist in the Resource left panel.
      return false
  }
}

/**
 * Check if the given resource type has "Telemetry", "Operations", or "Impacts" tab enabled.
 * This does not consider any resource-specific data that might enable or disable a tab, but
 * that is handled by `hasResourceTabEnabled()`.
 */
export function hasAnyResourceTabEnabled(
  rt: ResourceType,
  config: Readonly<RittaConfig>
): boolean {
  return !!(
    config.monitor?.telemetry?.includes(rt) ||
    config.monitor?.operations?.includes(rt) ||
    config.gridImpact?.resourceTypes?.includes(rt)
  )
}

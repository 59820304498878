<template>
  <control-mode-template
    v-model="selectedOption"
    :options
    :is-loading
    :is-submitting
    :msg-loading-failed
    @try-again="fetchData"
  >
    <!-- Forms -->
    <!-- Controls off -->
    <template v-slot:[Option.CONTROLS_OFF]>
      <controls-off-form
        :is-operating-envelope-enabled
        :group-id="group.id"
        :devices
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Envelopes only -->
    <template v-slot:[Option.ENVELOPES_ONLY]>
      <envelopes-only-form
        :group-id="group.id"
        :devices
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Manual -->
    <template v-slot:[Option.MANUAL]>
      <manual-form
        :is-operating-envelope-enabled
        :metric="computedMetric"
        :group-id="group.id"
        :devices
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Recurring Schedule (Waypoint) -->
    <template v-slot:[Option.RECURRING_SCHEDULE]>
      <recurring-waypoint-form
        :ref="REF_FORM"
        :group-id="group.id"
        :metric="computedMetric"
        :future-interval
        :device-type
        :power-rating
        :devices
        :current-o-e-status
        :initial-waypoints="recurringSchedule.waypoints"
        @new-waypoints="handleNewRecurringWaypoints"
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Temporary Schedule -->
    <template v-slot:[Option.TEMPORARY_SCHEDULE]>
      <!-- Event -->
      <template v-if="useEventControls">
        <group-event-temporary-schedule-form
          v-if="latestDeviceSchedule !== null"
          :ref="REF_FORM"
          :future-interval
          :device-type
          :power-rating
          :initial-device-schedule="latestDeviceSchedule"
          @new-device-schedule="handleNewDeviceSchedule"
          @cancel="cancelOption"
          @submitting="handleFormSubmitting"
          @success="handleFormSuccess"
        />
      </template>

      <!-- Waypoint -->
      <temporary-waypoint-form
        v-else
        :ref="REF_FORM"
        :group
        :metric="computedMetric"
        :future-interval
        :device-type
        :power-rating
        :devices
        :current-o-e-status
        :initial-waypoints="scheduleResponse.waypointsList"
        @new-waypoints="handleNewWaypoints"
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Charts -->
    <waypoint-charts
      :is-operating-envelope-enabled
      :use-event-controls
      :max-capacity-watthours
      :group
      :devices
      :future-interval
      :metric="computedMetric"
      :schedule-response
      :new-temporary-waypoints
      :new-recurring-waypoints
      :recurring-schedule
      :device-schedule
      :interval-broadcaster
    />
  </control-mode-template>
</template>

<script lang="ts">
import { defineComponent, type PropType, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { PlanScheduleMetric } from '@/constants/metrics'
import { Timestamp } from '@/services/timestamp_pb'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import {
  GroupHelper,
  isEnvelopesOnly,
  isTemporarySchedule,
} from '@/model/control/group/helper'
import { fetchSimulatedManualModeSeries } from '@/model/control/group/GroupWaypointChartData'
import {
  Option,
  optionEnvelopesOnly,
  optionControlsOff,
  optionManual,
  optionRecurringSchedule,
  optionTemporarySchedule,
  type SelectItem,
} from '@/model/control/controlMode'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import { getPowerRating } from '@/model/resource'
import { Metric, getMaxAllowedDateTime } from '@/model/control/waypoint'
import ControlsOffForm from '@/components/control/ControlsOffForm.vue'
import EnvelopesOnlyForm from '@/components/control/EnvelopesOnlyForm.vue'
import ControlModeTemplate from '@/components/control/ControlModeTemplate.vue'
import ManualForm from '@/components/control/ManualForm.vue'
import WaypointCharts from '@/components/control/WaypointCharts.vue'
import GroupEventTemporaryScheduleForm from '@/components/control/group/GroupEventTemporaryScheduleForm.vue'
import TemporaryWaypointForm from '@/components/control/TemporaryWaypointForm.vue'
import RecurringWaypointForm from '@/components/control/RecurringWaypointForm.vue'
import type { Device, DeviceType, Group } from 'rfs/control/proto/model_pb'
import { DeviceSchedule } from 'rfs/device/proto/proxy_pb'
import {
  Schedule,
  type ScheduleWaypoint,
} from 'rfs/control/proto/control_service_pb'
import { ScheduleTimeSeriesResponse } from 'rfs/frontend/proto/controls_pb'
import type { Waypoint } from 'rfs/control/proto/waypoints_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import { Policy } from 'rfs/control/proto/policy_pb'

/**
 * Vue Options API lacks intellisense support for component methods.
 */
interface ScheduleFormComponent {
  shouldChangeRoute: (next: Function) => void
}

export type NewTemporaryWaypoints = {
  waypoints: Waypoint[]
  unifyWithRecurringWaypoints?: boolean
}

export default defineComponent({
  name: 'ControlMode',
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: false,
      default: () => [],
    },
    resources: {
      type: Array as PropType<Resource[]>,
      required: false,
      default: () => [],
    },
    /**
     * This value should only be used for Groups that belong to
     * Locations.
     *
     * The only way to transform user input in SoC (state of charge, %) to
     * watt hours is using this value below to do the math.
     *
     * This transformation is require only for the charts.
     */
    maxCapacityWatthours: {
      type: Number,
      required: false,
    },
    /**
     * Instructs components to refresh their data, signaled by parent component.
     */
    intervalBroadcaster: {
      type: Object as PropType<IntervalBroadcaster>,
      required: false,
    },
    currentOEStatus: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
  },
  components: {
    ControlModeTemplate,
    ControlsOffForm,
    EnvelopesOnlyForm,
    ManualForm,
    WaypointCharts,
    GroupEventTemporaryScheduleForm,
    TemporaryWaypointForm,
    RecurringWaypointForm,
  },
  setup() {
    return { Option }
  },
  data() {
    const now = this.$observationTime()

    return shallowReactive({
      now,
      REF_FORM: 'REF_FORM',
      selectedOption: null as null | Option,
      isLoading: false,
      hasLoadingFailed: false,
      msgLoadingFailed: '',
      isSubmitting: false,
      groupSupportedMetrics: [] as Metric[],
      latestDeviceSchedule: new DeviceSchedule(),
      modifiedDeviceSchedule: undefined as undefined | DeviceSchedule,
      scheduleResponse: new ScheduleTimeSeriesResponse(),
      newTemporaryWaypoints: undefined as undefined | NewTemporaryWaypoints,
      newRecurringWaypoints: undefined as undefined | ScheduleWaypoint[],
      recurringSchedule: new Schedule(),
      /**
       * The power rating is used for "targetValue" validation and the table's
       * header tooltip.
       */
      powerRating: undefined as undefined | number,
    })
  },
  computed: {
    deviceType(): DeviceType {
      return this.group.deviceType
    },
    useEventControls(): boolean {
      return GroupHelper.isGroupOfV2g(this.group)
    },
    computedMetric(): Metric {
      // TODO(rafael): Nuvve group returns no supported metric yet.
      if (this.useEventControls) {
        return Metric.METRIC_ACTIVE_POWER_WATTS
      } else if (this.groupSupportedMetrics.length) {
        // NOTE: Use the first metric available.
        return this.groupSupportedMetrics[0]
      } else {
        return Metric.METRIC_UNSPECIFIED
      }
    },
    optionControlsOff(): SelectItem {
      return {
        ...optionControlsOff,
        props: {
          disabled: this.group.currentPolicy === Policy.DEFAULT,
        },
      }
    },
    optionEnvelopesOnly(): SelectItem {
      return {
        ...optionEnvelopesOnly,
        props: {
          disabled: isEnvelopesOnly(
            this.group.currentPolicy,
            this.group.currentPolicyParams
          ),
        },
      }
    },
    isOperatingEnvelopeEnabled(): boolean {
      return this.currentOEStatus !== undefined
    },
    options(): SelectItem[] {
      const items: SelectItem[] = []

      if (GroupHelper.hasControlsOff(this.group)) {
        items.push(this.optionControlsOff)
      }

      if (this.isOperatingEnvelopeEnabled) {
        items.push(this.optionEnvelopesOnly)
      }

      if (GroupHelper.hasManualControls(this.group)) {
        items.push(optionManual)
      }

      if (
        !this.useEventControls &&
        GroupHelper.hasAutomaticControls(this.group)
      ) {
        items.push(optionRecurringSchedule)
      }

      if (GroupHelper.hasAutomaticControls(this.group)) {
        items.push(optionTemporarySchedule)
      }

      return items
    },
    futureInterval(): Interval {
      return Interval.fromDateTimes(this.now, getMaxAllowedDateTime(this.now))
    },
    scheduleId(): undefined | bigint {
      return GroupHelper.getCurrentScheduleId(this.group.currentPolicyParams)
    },
    nextScheduleId(): undefined | bigint {
      return GroupHelper.getCurrentScheduleId(this.group.nextPolicyParameters)
    },
    deviceSchedule(): DeviceSchedule {
      return this.modifiedDeviceSchedule || this.latestDeviceSchedule
    },
  },
  watch: {
    group: {
      immediate: true,
      handler: async function (newValue: Group, oldValue: undefined | Group) {
        // Since the group changes every interval, wait for the group to change
        // to update "now". This way when "fetchData" is called, a new group
        // will be available (with up to date policies and schedule id).
        this.now = this.$observationTime()

        // 1. Update data.
        await this.fetchData({ refresh: newValue.id === oldValue?.id })
      },
    },
    selectedOption(): void {
      this.resetModifiedState()
    },
  },
  methods: {
    getForm() {
      return this.$refs[this.REF_FORM] as undefined | ScheduleFormComponent
    },
    /**
     * Allows the Schedule Form component to prompt the user
     * for confirmation before changing routes.
     */
    shouldChangeRoute(next: Function): void {
      const formComponent = this.getForm()

      if (formComponent) {
        formComponent.shouldChangeRoute(next)
      } else {
        next()
      }
    },
    cancelOption(): void {
      this.selectedOption = null
    },
    resetModifiedState(): void {
      this.modifiedDeviceSchedule = undefined
      this.newRecurringWaypoints = undefined
      this.newTemporaryWaypoints = undefined
    },
    handleFormSubmitting(newValue: boolean): void {
      this.isSubmitting = newValue
    },
    handleFormSuccess(): void {
      this.$emit('policy-updated')
    },
    handleNewDeviceSchedule(newValue: DeviceSchedule): void {
      this.modifiedDeviceSchedule = newValue
    },
    handleNewRecurringWaypoints(newValue: ScheduleWaypoint[]): void {
      this.newRecurringWaypoints = newValue
    },
    handleNewWaypoints(newValue: NewTemporaryWaypoints): void {
      this.newTemporaryWaypoints = newValue
    },
    resetState(): void {
      this.selectedOption = null
      this.hasLoadingFailed = false
      this.msgLoadingFailed = ''
      this.groupSupportedMetrics = []
      this.latestDeviceSchedule = new DeviceSchedule()
      this.scheduleResponse = new ScheduleTimeSeriesResponse()
      this.recurringSchedule = new Schedule()
      this.resetModifiedState()
    },
    /** * Each group has a list of supported metrics. */
    async fetchMetrics(): Promise<void> {
      const { supportedMetrics } =
        await this.$services.control.getGroupSupportedMetricTypes({
          id: this.group.id,
        })
      this.groupSupportedMetrics = supportedMetrics
    },
    async findPowerRating(): Promise<void> {
      // We can only discovver the power rating in groups that contain
      // a single Device (single Resource).
      if (this.devices.length !== 1) return

      // We only need "powerRating" for the following metric.
      if (this.computedMetric !== Metric.METRIC_ACTIVE_POWER_WATTS) return

      try {
        const device = this.devices[0]
        const resourceId = device.camusResourceId

        if (!resourceId) {
          throw new Error(`device "${device.id}" has no resource ID`)
        }

        const resource =
          await this.$services.queryService.getResource(resourceId)

        this.powerRating = getPowerRating(resource)
      } catch (err) {
        console.error('ControlMode.findPowerRating: %o', err)
      }
    },
    async fetchData({ refresh } = { refresh: false }): Promise<void> {
      try {
        if (!refresh) {
          this.isLoading = true
          this.resetState()
          await Promise.all([this.fetchMetrics(), this.findPowerRating()])
        }

        // Temporary Schedule: LPEA (Nuvve).
        if (this.useEventControls) {
          await this.fetchLatestDeviceSchedule()
        } else {
          this.latestDeviceSchedule = new DeviceSchedule()
        }

        // Temporary Schedule.
        if (
          isTemporarySchedule(
            this.group.currentPolicy,
            this.group.currentPolicyParams
          )
        ) {
          this.scheduleResponse =
            await this.$services.controlsData.fetchPlanSchedule(
              this.group.id,
              {
                interval: this.futureInterval,
                // Ask for all plan scheule metrics.
                metrics: Object.values(PlanScheduleMetric).map((m) => ({
                  metric: m,
                })),
              }
              // NOTE(rafael): not sending any "waypoints" means asking for
              // the current scheduled waypoints.
            )
        } else if (this.group.currentPolicy === Policy.MANUAL_CONTROL_EVENT) {
          // Manual mode.
          this.scheduleResponse =
            (await fetchSimulatedManualModeSeries(
              this.$services,
              this.group,
              this.computedMetric,
              this.futureInterval.start
            )) ?? new ScheduleTimeSeriesResponse()
        } else {
          // When group running in "Controls off" or "Envelopes only", use an
          // empty response so nothing is displayed on the charts.
          this.scheduleResponse = new ScheduleTimeSeriesResponse()
        }

        await this.updateRecurringSchedule()
      } catch (err) {
        this.hasLoadingFailed = true
        this.msgLoadingFailed =
          (err as any).message ??
          (refresh
            ? 'Something went wrong when refreshing the data.'
            : 'Something went wrong fetching the initial data.')
        console.error('ControlMode.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    /**
     * When "Event" controls, fetches the latest device schedule.
     */
    async fetchLatestDeviceSchedule(): Promise<void> {
      // TODO(rafael): only a single device is expected for now.
      if (!this.devices.length || this.devices.length > 1) {
        throw new Error('unexpected number of devices')
      }

      this.latestDeviceSchedule = await this.$services.proxy.deviceGetSchedule({
        deviceId: this.devices[0].id,
        startTime: Timestamp.fromDateTime(this.futureInterval.start),
        endTime: Timestamp.fromDateTime(this.futureInterval.end),
      })
    },
    async updateRecurringSchedule() {
      const scheduleId = this.nextScheduleId || this.scheduleId

      // Always update the recurring schedule state so it keeps the chart
      // always up-to-date in terms of unified waypoints.
      if (!scheduleId) {
        this.recurringSchedule = new Schedule()
        return
      }

      // Skip, still the same, nothing to update.
      if (scheduleId === this.recurringSchedule?.id) return

      const { schedule } = await this.$services.control.getSchedule({
        id: scheduleId,
      })

      if (!schedule) {
        throw new Error(
          `fetchRecurringSchedule: recurring schedule not found for scheduleId "${scheduleId}"`
        )
      }

      this.recurringSchedule = schedule
    },
  },
})
</script>

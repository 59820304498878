import { Interceptor } from '@connectrpc/connect'
import { RittaConfig } from '@/config'
import { CacheInterceptor } from './cache'
import { factoryE2eAuthInterceptor } from './e2eAuth'
import { LocalDevInterceptor } from './localDev'
import { MetadataInterceptor } from './metadata'
import { factoryRefreshTokenInterceptor } from './refreshToken'
import { ControlErrorInterceptor } from './controlError'

const RFS_TOKENZ_ENDPOINT = '/ritta.main/tokenz'

/**
 * Returns the gRPC interceptors depending on the production/development environment.
 */
export function getInterceptors(
  isDevEnv: boolean,
  config: Readonly<RittaConfig>
): Interceptor[] {
  const interceptors: Interceptor[] = []

  // Local dev
  isDevEnv && interceptors.push(LocalDevInterceptor)

  // E2E tests
  const identityToken = import.meta.env.VITE_E2E_IDENTITY_TOKEN
  if (identityToken) {
    interceptors.push(factoryE2eAuthInterceptor(identityToken))
  }

  // Chrome extension to debug Grpc Web requests/responses.
  // More: https://github.com/SafetyCulture/grpc-web-devtools
  const chromeExtensionInterceptor =
    // @ts-ignore
    window.__CONNECT_WEB_DEVTOOLS__ as Interceptor

  if (chromeExtensionInterceptor) {
    // Catches "cannot encode message google.protobuf.Any to JSON" when
    // intercepting requests to "GroupHistory" endpoint.
    // More: https://github.com/SafetyCulture/grpc-web-devtools/issues/153
    const wrapper: Interceptor = (next) => async (req) => {
      try {
        const res = await chromeExtensionInterceptor(next)(req)
        return res
      } catch (err) {
        if (!(err as any).message.includes('google.protobuf.Any')) {
          console.error(err)
        }
        return next(req)
      }
    }

    interceptors.push(wrapper)
  }

  // Cache
  interceptors.push(CacheInterceptor)

  // Refresh token
  const refreshTokenUrl = config.rfsEndpoint + RFS_TOKENZ_ENDPOINT
  interceptors.push(factoryRefreshTokenInterceptor(refreshTokenUrl))

  // gRPC metadata
  interceptors.push(MetadataInterceptor)

  // Submit messages to Sentry when errors from Control Service.
  if (!isDevEnv) interceptors.push(ControlErrorInterceptor)

  return interceptors
}

import { Custom, CustomOptions } from '@/utils/validation/decorators'
import { validators } from '@/utils/validation/validators'

/**
 * Maps all properties in the class.
 */
export type ValidationResult<T extends FormFields = FormFields> = {
  [P in keyof OnlyProperties<T>]: string[]
} & {
  isValid: boolean
  /** * A validation message for the entire form. */
  preValidation?: string
}

export abstract class FormFields {
  /**
   * Optional method that, when implemented, will run BEFORE the normal
   * validation. Useful for early returns in the validation process.
   */
  protected preValidate?(): undefined | string

  /**
   * Validades all properties of the instance.
   */
  public validate(): ValidationResult<this> {
    // Pre-validation.
    const preValidation = this.preValidate?.()
    if (preValidation) {
      return { isValid: false, preValidation } as ValidationResult<this>
    }

    // Start of validation.
    const finalValidation = { isValid: true } as ValidationResult<this>

    //eslint-disable-next-line @typescript-eslint/no-this-alias
    const formFields = this

    // Validate all properties of the instance.
    for (const [fieldName, fieldValue] of Object.entries(formFields)) {
      // @ts-ignore - Pass properties that are arrow functions.
      if (typeof formFields[fieldName] === 'function') continue

      // @ts-ignore - init.
      finalValidation[fieldName] = []

      // Is expected that all decorators have a validator registered in
      // `validators`.
      for (const [decoratorName, validator] of Object.entries(validators)) {
        /**
         * Generally the value of `decoratorParams` is going to be `true`.
         * But it can be something else as:
         *
         * Example 1:
         * `@DoSomething('run')`
         * decoratorParams === 'run'
         *
         * Example 2:
         * `@ComplexValidation({ foo: 'bar'})`
         * decoratorParams === { foo: 'bar' }
         */
        const decoratorParams = Reflect.getMetadata(
          decoratorName,
          formFields,
          fieldName
        )

        // Should validate.
        if (decoratorParams) {
          // Special case: Custom validators.
          // There may be multiple custom validators in the same field name.
          if (decoratorName === Custom.name && Array.isArray(decoratorParams)) {
            for (const param of decoratorParams as CustomOptions[]) {
              const result = validator(fieldValue, fieldName, formFields, param)

              if (typeof result === 'string') {
                // @ts-ignore
                finalValidation[fieldName].push(result)
              }
            }
          } else {
            const result = validator(
              fieldValue,
              fieldName,
              formFields,
              decoratorParams
            )

            if (typeof result === 'string') {
              // @ts-ignore
              finalValidation[fieldName].push(result)
            }
          }
        }
      }
    }

    // Analize the validation and mark as invalid.
    for (const [_, arr] of Object.entries(finalValidation)) {
      if (Array.isArray(arr) && arr.length) {
        finalValidation.isValid = false
        break
      }
    }

    return finalValidation
  }
}

import { DateTime, Duration, Interval } from 'luxon'
import { createIntervalFrom } from '@/model/control/waypoint'
import type { DeviceScheduleCommand } from 'rfs/device/proto/proxy_pb'
import { TimeSeries_DataPoint as TimeSeriesDataPoint } from 'rfs/frontend/proto/tsdb_pb'

/**
 * Currently for Nuvve, there's a limit for the duration of a single event.
 */
export const ALLOWED_COMMAND_DURATION = Duration.fromObject({ hours: 4 }) // (End - Start)

/**
 * Checks a list of events in search for overlapping ones.
 */
export function validateOverlapping(
  commands: DeviceScheduleCommand[]
): string[] {
  const errorMsgs: string[] = []

  const overlapPairs: [number, number][] = []

  commands.forEach((command, index) => {
    const commandInterval = createIntervalFrom(command)

    if (!commandInterval) return

    commands.forEach((command2, index2) => {
      // It's the same command, skip.
      if (index === index2) return

      // It was already detected, skip.
      if (
        overlapPairs.some(
          (pair) => pair.includes(index) && pair.includes(index2)
        )
      ) {
        return
      }

      const command2Interval = createIntervalFrom(command2)

      if (!command2Interval) return

      if (commandInterval.overlaps(command2Interval)) {
        overlapPairs.push([index, index2])
        errorMsgs.push(`Event "${index}" overlaps with Event "${index2}".`)
      }
    })
  })

  return errorMsgs
}

/**
 * Converts a command in to a list of data points with a 1-minute resolution.
 *
 * Start time inclusive and end time exclusive, forming an [A, B) interval.
 *
 * The last data point will contain `null` for the Y-axis to distinguish it
 * from the next command.
 */
export function convertCommandToDataPoints(
  command: DeviceScheduleCommand
): TimeSeriesDataPoint[] {
  const startDt = command.startTime
    ? DateTime.fromMillis(command.startTime.toMillis())
    : undefined
  const endDt = command.endTime
    ? DateTime.fromMillis(command.endTime.toMillis())
    : undefined

  // Start and End are required to create datapoints.
  if (!startDt || !endDt) return []

  const interval = Interval.fromDateTimes(startDt, endDt)

  const datapoints: TimeSeriesDataPoint[] = []

  let currentDt = interval.start

  while (currentDt < interval.end) {
    datapoints.push(
      new TimeSeriesDataPoint({
        x: currentDt.toMillis(),
        y: command.value,
      })
    )

    currentDt = currentDt.plus({ minute: 1 })
  }

  if (datapoints.length) {
    datapoints.push(
      new TimeSeriesDataPoint({
        x: datapoints[datapoints.length - 1].x + 1_000, // add one second.
        y: undefined, // must not have a value.
      })
    )
  }

  return datapoints
}

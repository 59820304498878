<template>
  <div class="c-GroupEventTemporaryScheduleForm">
    <!-- Table -->
    <ce-data-table
      v-if="table.rows.length"
      :headers
      :table
      :bg-color="bgColor"
      :show-pagination="false"
      :is-loading="isValidating"
      :sticky-offset="TOP_BAR_HEIGHT"
      sticky
      dense
      border-bottom
      class="pb-4"
    >
      <!-- Custom rows -->
      <template v-slot:item="{ item, index }">
        <automatic-control-row-edit
          v-if="indexOfEventToEdit === index && formFields"
          :index="index"
          :metric="metric"
          v-model:date="formFields.date"
          v-model:start-time="formFields.startTime"
          v-model:end-time="formFields.endTime"
          v-model:target-value="formFields.targetValue"
          :validation="formValidation"
          :min-date="minStart"
          :max-date="maxDate"
          @cancel="cancelEdit"
          @save="saveEditted"
        />
        <automatic-control-row
          v-else
          :row="item"
          :is-disabled="isSubmitting || isRowBeingEditted"
          @edit="() => handleClickEditEvent(index)"
          @delete="() => handleClickDeleteEvent(index)"
        />
      </template>
    </ce-data-table>

    <!-- Overlap errors -->
    <alert-error
      data-test="overlap"
      v-for="(err, index) of overlapErrors"
      :key="index"
      class="mb-2"
    >
      {{ err }}
    </alert-error>

    <!-- Server Validation errors -->
    <alert-error
      data-test="serverValidationError"
      v-for="(result, index) of serverValidationResponse?.results ?? []"
      :key="index"
      class="mb-2"
    >
      <span class="d-block">Event "{{ index }}":</span>
      <ul>
        <li v-for="msg of result.messages" :key="msg">{{ msg }}</li>
      </ul>
    </alert-error>

    <!-- Server Failed -->
    <alert-error v-if="failedMsg" data-test="failedMsg" class="mb-2">
      {{ failedMsg }}
    </alert-error>

    <!-- Action buttons -->
    <form-actions
      ref="formActions"
      :metric
      :deviceType
      :isSubmitting
      :is-editting="isRowBeingEditted"
      :isTouched
      :has-overlaps="Boolean(overlapErrors.length)"
      @add="addNewEvent"
      @cancel="$emit('cancel')"
      @submit="openSubmitConfirmDialog"
    />

    <!-- Modal Confirmation -->
    <dialog-confirm
      v-if="dialogState"
      :title="dialogState.title"
      :body="dialogState.body"
      @cancel="() => closeDialog('cancel')"
      @confirm="() => closeDialog('confirm')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { DateTime, Interval } from 'luxon'
import { Timestamp } from '@/services/timestamp_pb'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { GREY11 } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import {
  createAutomaticControlDataTable,
  createAutomaticControlHeaders,
} from '@/model/control/WaypointDataTable'
import {
  Metric,
  changeRouteMessage,
  computeAllowedIntervalAndFilter,
  confirmationTitle,
  getScheduleSuccessMessage,
  getSubmitScheduleConfirmationMessage,
  validateTemporaryOverlapping,
} from '@/model/control/waypoint'
import { WaypointFormFields } from '@/model/control/waypointFormFields'
import AlertError from '@/components/common/AlertError.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import AutomaticControlRow from '@/components/control/AutomaticControlRow.vue'
import AutomaticControlRowEdit from '@/components/control/AutomaticControlRowEdit.vue'
import FormActions from '@/components/control/FormActions.vue'
import DialogConfirm from '@/components/common/DialogConfirm.vue'
import {
  DeviceSchedule,
  DeviceScheduleCommand,
  type DeviceScheduleResponse,
} from 'rfs/device/proto/proxy_pb'
import type { DeviceType } from 'rfs/control/proto/model_pb'

type DialogProps = {
  title: string
  body: string
  confirmCb: Function
  cancelCb?: Function
}

const DEFAULT_ERR_MSG = 'Something went wrong.'

export default defineComponent({
  name: 'GroupEventTemporaryScheduleForm',
  props: {
    deviceType: {
      type: Number as PropType<DeviceType>,
      required: true,
    },
    futureInterval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    initialDeviceSchedule: {
      type: Object as PropType<DeviceSchedule>,
      required: true,
    },
    powerRating: {
      type: Number,
      required: false,
    },
  },
  components: {
    AlertError,
    CeDataTable,
    AutomaticControlRow,
    AutomaticControlRowEdit,
    FormActions,
    DialogConfirm,
  },
  setup(props) {
    const metric = Metric.METRIC_ACTIVE_POWER_WATTS

    return {
      TOP_BAR_HEIGHT,
      TEXT_NO_VALUE,
      metric,
      globalSnackbarStore: useGlobalSnackbar(),
      headers: createAutomaticControlHeaders({
        metric,
        powerRating: props.powerRating,
        deviceType: props.deviceType,
      }),
      bgColor: GREY11.hex,
    }
  },
  data() {
    const { allowedInterval, filtered } = computeAllowedIntervalAndFilter(
      this.futureInterval,
      this.initialDeviceSchedule.commands
    )

    return shallowReactive({
      isSubmitting: false,
      hasSubmittingFailed: false,
      isValidating: false,
      hasValidatingFailed: false,
      failedMsg: null as null | string,
      isAddingNewEvent: false,
      isTouched: false,
      indexOfEventToEdit: undefined as undefined | number,
      dialogState: null as null | DialogProps,
      allowedEventInterval: allowedInterval,
      events: filtered,
      serverValidationResponse: null as null | DeviceScheduleResponse,
      //
      formFields: undefined as
        | undefined
        | ReturnType<typeof shallowReactive<WaypointFormFields>>,
      formValidation: undefined as
        | undefined
        | ReturnType<typeof WaypointFormFields.prototype.validate>,
    })
  },
  computed: {
    now(): DateTime {
      return this.futureInterval.start
    },
    minStart(): DateTime {
      return this.allowedEventInterval.start
    },
    maxDate(): DateTime {
      return this.allowedEventInterval.end
    },
    table() {
      return createAutomaticControlDataTable({
        metric: this.metric,
        deviceScheduleCommands: this.events,
      })
    },
    overlapErrors(): string[] {
      return validateTemporaryOverlapping(this.events)
    },
    isRowBeingEditted(): boolean {
      return this.indexOfEventToEdit !== undefined
    },
    newDeviceSchedule(): null | DeviceSchedule {
      return this.isTouched
        ? new DeviceSchedule({
            deviceId: this.initialDeviceSchedule.deviceId,
            commands: this.events,
          })
        : null
    },
  },
  watch: {
    initialDeviceSchedule(): void {
      if (this.isTouched || this.isRowBeingEditted) return

      this.resetState()
    },
    allowedEventInterval(): void {
      this.validateNewTemporarySchedule()
    },
  },
  mounted(): void {
    this.focusBtnAddEvent()
  },
  methods: {
    shouldChangeRoute(next: Function): void {
      if (this.isTouched) {
        this.dialogState = {
          title: confirmationTitle,
          body: changeRouteMessage,
          cancelCb: () => next(false),
          confirmCb: () => next(),
        }
      } else {
        next()
      }
    },
    handleClickEditEvent(index: number): void {
      const deviceScheduleCommand = this.events?.[index]

      if (!deviceScheduleCommand)
        throw new Error('deviceScheduleCommand expected')

      this.indexOfEventToEdit = index

      this.formFields = shallowReactive(
        new WaypointFormFields({
          deviceScheduleCommand,
          metric: this.metric,
          deviceType: this.deviceType,
          powerRating: this.powerRating,
          config: {
            getNow: () => this.now,
            getMinStart: () => this.minStart,
            getMaxEnd: () => this.maxDate,
          },
        })
      )

      this.formValidation = undefined
    },
    cancelEdit(): void {
      // When the user chooses to cancel the addition of a new Event,
      // delete the new Event from the list of Events since
      // it's an empty Event.
      if (this.isAddingNewEvent) {
        const indexOfLastEvent = this.events.length - 1
        const newEvents = [...this.events]
        newEvents.splice(indexOfLastEvent, 1)
        this.events = newEvents
        this.isAddingNewEvent = false
      }

      this.indexOfEventToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined

      this.focusBtnAddEvent()
    },
    saveEditted(): void {
      if (!this.formFields) {
        throw new Error(
          'GroupEventTemporaryScheduleForm.saveEditted: unavailable formFields'
        )
      }

      const validation = this.formFields.validate()

      if (!validation.isValid) {
        this.formValidation = validation
        return
      }

      const edittedEvent = this.formFields.generateEvent()

      const newEvents = [...this.events]

      if (this.indexOfEventToEdit === undefined) {
        throw new Error(
          'GroupEventTemporaryScheduleForm.saveEditted: unexpected "undefined" index'
        )
      }

      newEvents[this.indexOfEventToEdit] = edittedEvent

      this.events = newEvents
      this.indexOfEventToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined
      this.isAddingNewEvent = false

      this.handleTouchedEvents()

      this.focusBtnAddEvent()
    },
    focusBtnAddEvent(): void {
      ;(this.$refs.formActions as any)?.focusBtnAdd?.()
    },
    addNewEvent(): void {
      this.isAddingNewEvent = true

      const pristineEvent = new DeviceScheduleCommand({
        metric: this.metric,
      })

      const newEvents = [...this.events, pristineEvent]

      this.events = newEvents

      const lastIndex = newEvents.length - 1

      this.$nextTick(() => {
        this.handleClickEditEvent(lastIndex)
      })
    },
    handleClickDeleteEvent(index: number): void {
      const newEvents = [...this.events]
      newEvents.splice(index, 1)
      this.events = newEvents
      this.handleTouchedEvents()
    },
    async handleTouchedEvents(): Promise<void> {
      const newDeviceSchedule = new DeviceSchedule({
        deviceId: this.initialDeviceSchedule.deviceId,
        commands: [...this.events],
      })
      this.isTouched = true
      this.$emit('new-device-schedule', newDeviceSchedule)
      this.validateNewTemporarySchedule()
    },
    resetState(): void {
      const { allowedInterval, filtered } = computeAllowedIntervalAndFilter(
        this.futureInterval,
        this.initialDeviceSchedule.commands
      )

      this.allowedEventInterval = allowedInterval
      this.events = filtered
      this.isTouched = false
      this.indexOfEventToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined
    },
    closeDialog(opt: 'cancel' | 'confirm'): void {
      if (opt === 'confirm') {
        this.dialogState?.confirmCb()
      } else if (opt === 'cancel') {
        this.dialogState?.cancelCb?.()
      } else {
        throw new Error(
          'GroupEventTemporaryScheduleForm.closeDialog: unexpected option'
        )
      }

      this.dialogState = null
    },
    openSubmitConfirmDialog(): void {
      this.dialogState = {
        title: confirmationTitle,
        body: getSubmitScheduleConfirmationMessage({
          metric: this.metric,
          deviceType: this.deviceType,
          hasWaypoints: !!this.events.length,
        }),
        confirmCb: this.submitNewTemporarySchedule,
      }
    },
    async validateNewTemporarySchedule(): Promise<void> {
      // NOTE(rafael): no need to validate the device schedule when the user
      // have not touched the Events.
      if (!this.newDeviceSchedule) return
      // NOTE(rafael): only validate when the user stops editting one of the
      // Events.
      if (this.isRowBeingEditted) return

      this.isValidating = true
      this.hasValidatingFailed = false
      this.failedMsg = null

      try {
        const validationResponse =
          await this.$services.proxy.deviceSendSchedule({
            ...this.newDeviceSchedule,
            validateOnly: true, // <--- IMPORTANT!
          })

        const isServerValid = !validationResponse.results.some(
          (res) => res.messages.length
        )

        this.serverValidationResponse = isServerValid
          ? null
          : validationResponse
      } catch (err) {
        this.hasValidatingFailed = true
        this.failedMsg = (err as any).message || DEFAULT_ERR_MSG
        console.error(
          'GroupEventTemporaryScheduleForm.validateNewTemporarySchedule: %o',
          err
        )
      } finally {
        this.isValidating = false
      }
    },
    async submitNewTemporarySchedule(): Promise<void> {
      if (!this.newDeviceSchedule) return

      this.$emit('submitting', true)
      this.isSubmitting = true
      this.hasSubmittingFailed = false
      this.failedMsg = null

      try {
        // When no Events, use specific endpoint to delete all.
        if (!this.events.length) {
          await this.$services.proxy.deviceDeleteSchedule({
            deviceId: this.initialDeviceSchedule.deviceId,
            startTime: Timestamp.fromDateTime(this.allowedEventInterval.start),
            endTime: Timestamp.fromDateTime(this.allowedEventInterval.end),
          })

          this.handleSubmitSuccess()
        } else {
          // 1. Validate the Events.
          await this.validateNewTemporarySchedule()

          const canSubmit =
            !this.serverValidationResponse && !this.hasValidatingFailed

          if (canSubmit) {
            // 2. Send the final request.
            await this.$services.proxy.deviceSendSchedule(
              this.newDeviceSchedule
            )

            this.handleSubmitSuccess()
          }
        }
      } catch (err) {
        this.hasSubmittingFailed = true
        const failedMsg = (err as any).message || DEFAULT_ERR_MSG
        this.failedMsg = failedMsg
        this.globalSnackbarStore.openSnackbar(failedMsg, 'error')
        console.error(
          'GroupEventTemporaryScheduleForm.submitNewTemporarySchedule: %o',
          err
        )
      } finally {
        this.$emit('submitting', false)
        this.isSubmitting = false
      }
    },
    handleSubmitSuccess(): void {
      this.globalSnackbarStore.openSnackbar(
        getScheduleSuccessMessage({
          metric: this.metric,
          deviceType: this.deviceType,
        }),
        'info'
      )
      this.$emit('success')
    },
  },
})
</script>

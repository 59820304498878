import { DateTime, Interval } from 'luxon'
import { type PlainMessage } from '@bufbuild/protobuf'
import { sortedIndexBy as _sortedIndexBy } from 'lodash-es'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { BLUE3, PURPLE1 } from '@/constants/colors'
import type { TimeSeriesMap } from '@/model/charts'
import {
  findMaxMinMeanY,
  type TimeSeriesConfig,
} from '@/model/charts/TimeSeriesDataSource'
import { Metric } from '@/constants/metrics'
import { computeMinMaxMean } from '@/utils/charts'
import { Format } from '@/utils/format'
import { getPartialData } from '@/utils/chartjs'
import {
  ChartType,
  type ChartDefinition,
  type DataPointWithNull,
  type NumberOrNull,
} from '@/types/charts'
import type { Group } from 'rfs/control/proto/model_pb'
import { TimeSeries_DataPoint as TimeSeriesDataPoint } from 'rfs/frontend/proto/tsdb_pb'
import { GroupHelper } from './group/helper'

export type Summary = {
  min: NumberOrNull
  max: NumberOrNull
  mean: NumberOrNull
}

export type Series = DataPointWithNull[]

export type SeriesAndSummary = {
  series: Series
  summary?: Summary
}

export const ACTIVE_POWER_ID = 'active-power-chart'
export const ACTIVE_POWER_TITLE = 'Active Power'

export const REACTIVE_POWER_ID = 'reactive-power-chart'
export const REACTIVE_POWER_TITLE = 'Reactive Power'

export const SOC_ID = 'soc-chart'
export const SOC_TITLE = 'State of Charge'

export const TRANSFORMER_LOADING_ID = 'transformer-loading'
export const TRANSFORMER_LOADING_TITLE = 'Transformer Loading'

export const activePowerChartDefinition: ChartDefinition = {
  id: ACTIVE_POWER_ID,
  title: ACTIVE_POWER_TITLE,
  type: ChartType.Power,
}

export const reactivePowerChartDefinition: ChartDefinition = {
  id: REACTIVE_POWER_ID,
  title: REACTIVE_POWER_TITLE,
  type: ChartType.ReactivePower,
}

export const socChartDefinition: ChartDefinition = {
  id: SOC_ID,
  title: SOC_TITLE,
  type: ChartType.EnergyLine,
}

export const socPercentageChartDefinition: ChartDefinition = {
  id: SOC_ID,
  title: SOC_TITLE,
  type: ChartType.EnergyLinePercentage,
  yAxis: { min: 0, max: 1 }, // forces the chart to stop at 100%.
}

export const transformerLoadingChartDefinition: ChartDefinition = {
  id: TRANSFORMER_LOADING_ID,
  type: ChartType.ApparentPower,
  isAreaChart: true,
  title: TRANSFORMER_LOADING_TITLE,
}

export function createActivePowerTimeSeriesConfig(
  group: Group
): TimeSeriesConfig {
  return {
    metric: Metric.COND_POWER_REAL,
    unit: GroupHelper.hasNegativePower(group) ? '-W' : 'W',
    config: {
      seriesName: ACTIVE_POWER_TITLE,
      seriesColor: BLUE3.hex,
    },
  }
}

export function createReactivePowerTimeSeriesConfig(): TimeSeriesConfig {
  return {
    metric: Metric.COND_POWER_REACTIVE,
    config: {
      seriesName: REACTIVE_POWER_TITLE,
      seriesColor: BLUE3.hex,
    },
  }
}

export const socTimeSeriesConfig: TimeSeriesConfig = {
  metric: Metric.COND_CHARGE_STATE,
  config: { seriesName: SOC_TITLE, seriesColor: PURPLE1.hex },
}

export function activePowerYAxisFormatter(v: NumberOrNull): string {
  return v !== null ? Format.fmtWatts(v) : TEXT_NO_VALUE
}

export function reactivePowerYAxisFormatter(v: NumberOrNull): string {
  return v !== null ? Format.fmtReactivePower(v) : TEXT_NO_VALUE
}

export function socYAxisFormatter(v: NumberOrNull) {
  return v !== null ? Format.fmtEnergy(v) : TEXT_NO_VALUE
}

export function percentageYAxisFormatter(v: NumberOrNull) {
  return v !== null ? Format.fmtPercent(v) : TEXT_NO_VALUE
}

type TimeSeriesSummary = {
  last: null | PlainMessage<TimeSeriesDataPoint>
  minY: NumberOrNull
  meanY: NumberOrNull
  maxY: NumberOrNull
}

export function emptySummary(): TimeSeriesSummary {
  return { last: null, minY: null, meanY: null, maxY: null }
}

/**
 * Computes a summary for the first time series of a given chart, including:
 * - The last valid data point, considering a maximum age limit in minutes for recency;
 * - Min, max, and mean statistics within a specified time interval.
 */
export function getFirstTimeSeriesSummary(
  chartId: string,
  timeSeriesMap: TimeSeriesMap,
  interval: Interval,
  now: DateTime,
  ageLimitMinutes: number
): TimeSeriesSummary {
  const firstTimeSeries = timeSeriesMap.get(chartId)?.[0]

  if (!firstTimeSeries || !firstTimeSeries.data.length) return emptySummary()

  const lastDp = firstTimeSeries.data.at(-1)

  return {
    last:
      lastDp && isRecentDataPoint(now, lastDp, ageLimitMinutes) ? lastDp : null,
    ...computeMinMaxMean(
      getPartialData({
        start: interval.start.toMillis(),
        end: interval.end.toMillis(),
        data: firstTimeSeries.data,
      })
    ),
  }
}

/** * Ensures the last data point is recent enough for display. */
function isRecentDataPoint(
  now: DateTime,
  dp: PlainMessage<TimeSeriesDataPoint>,
  ageLimitMinutes: number
): boolean {
  return DateTime.fromMillis(dp.x) > now.minus({ minutes: ageLimitMinutes })
}

export function getTransformerSummary(
  chartId: string,
  timeSeriesMap: TimeSeriesMap,
  interval: Interval,
  now: DateTime,
  ageLimitMinutes: number
): TimeSeriesSummary {
  const result: TimeSeriesSummary = {
    last: null,
    minY: null,
    maxY: null,
    meanY: null,
  }

  const allTimeSeries = timeSeriesMap.get(chartId)

  // No series, skip.
  if (!allTimeSeries?.length) return result

  const uncontrollableData =
    allTimeSeries.find(
      (s) => s.metric === Metric.FORECAST_ACTUAL_COND_POWER_APPARENT
    )?.data ?? []

  const partialUncontrollableData = getPartialData({
    start: interval.start.toMillis(),
    end: interval.end.toMillis(),
    data: uncontrollableData,
  })

  const controllableData =
    allTimeSeries.find(
      (s) => s.metric === Metric.CONTROLLABLE_COND_POWER_APPARENT
    )?.data ?? []

  const partialControllableData = getPartialData({
    start: interval.start.toMillis(),
    end: interval.end.toMillis(),
    data: controllableData,
  })

  const mergedData = partialUncontrollableData.map((dp, index) => {
    let newY = dp.y

    if (newY != null) {
      if (
        partialControllableData[index] &&
        partialControllableData[index].x === dp.x
      ) {
        newY = newY + (partialControllableData[index].y ?? 0)
      }
    }

    return new TimeSeriesDataPoint({ x: dp.x, y: newY })
  })

  const values = findMaxMinMeanY(mergedData)

  result.meanY = values.meanY
  result.minY = values.min?.y ?? null
  result.maxY = values.max?.y ?? null

  const lastDp = mergedData.at(-1)
  result.last =
    lastDp && isRecentDataPoint(now, lastDp, ageLimitMinutes) ? lastDp : null

  return result
}

<template>
  <div
    data-testid="google-maps"
    :ref="REF_GMAPS_HTML_EL"
    style="height: 100%"
  ></div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue'
import { mapState, mapActions } from 'pinia'
import { RouteLocationNormalized as VueRouterRoute } from 'vue-router'
import { isEqual } from 'lodash-es'
import { Resource, Config_Phasing as ConfigPhasing } from 'rfs/pb/resource_pb'
import { MapLayerGroup, MapLayerGroupId, MapLayerId } from '@/config/types'
import {
  mapLayerCatalog,
  FactoryMapManagerLayer,
  ExtraProps,
} from '@/components/maps/layers/catalog'
import { routeForResource } from '@/router/routeForResource'
import { Feature } from '@/services/tile.service'
import { UPLINE_LAYER_ID, useAncestryStore } from '@/stores/ancestry'
import { CustomMapLayer, useCustomFiles } from '@/stores/customFiles'
import { GridMapState, Switches, useGridMapStore } from '@/stores/gridMap'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import { ICON_MAX_PIXELS } from '@/model/map/constants'
import { ControlPosition, createGmap } from '@/model/map/gmaps'
import { MapManager } from '@/model/map'
import {
  CloseInfoWindow,
  OpenInfoWindow,
  HoverInfo,
  LatLng,
  MapLayerFeature,
  LayersVisibility,
} from '@/model/map/types'
import { getDefaultPhaseColor } from '@/model/resource/conductor/phase'
import { customMount } from '@/utils/vue'
import { findCenterOfPolygon } from '@/utils/map'
import LandmarksControl from './LandmarksControl.vue'
import MapTypeControl from './MapTypeControl.vue'
import InfoWindow from './InfoWindow.vue'
import { newCustomLayer } from './layers/CustomLayer'
import { UplineLayer } from './layers/UplineLayer'
import { generateIconAtlas } from './layers/iconAtlas'
import { generateTintIconAtlas } from './layers/iconAtlasTint'

export type ComponentInstance = ReturnType<typeof customMount>

type CustomControls = Map<
  MapLayerId,
  {
    instance: ComponentInstance
    position: ControlPosition
  }
>

const HIGHLIGHTED = '-HIGHLIGHTED' as const

type MapLayerIdHighlighted = `${MapLayerId}${typeof HIGHLIGHTED}`

function addHighlightedSuffix(layerId: MapLayerId): MapLayerIdHighlighted {
  return `${layerId}${HIGHLIGHTED}`
}

function removeHighlightedSuffix(
  layerId: MapLayerId | MapLayerIdHighlighted
): MapLayerId {
  return layerId.replace(HIGHLIGHTED, '') as MapLayerId
}

function computeLayersVisibility(
  mapLayersGroups: MapLayerGroup[],
  switches: Switches,
  customMapLayers: Readonly<CustomMapLayer[]> = []
): LayersVisibility {
  const result: LayersVisibility = []

  // Configured Map Layers.
  for (const lg of mapLayersGroups ?? []) {
    const isGroupVisible = switches.has(lg.id)

    for (const l of lg.layers) {
      if (!l.disabled) {
        const isLayerVisible = switches.has(l.id)
        result.push([l.id, isGroupVisible && isLayerVisible])
      }
    }
  }

  // Custom Map Layers: since they are appended in the "Grid" group, first check
  // if the grid group is activated or not.
  for (const cml of customMapLayers) {
    const isGripGroupVisible = switches.has(MapLayerGroupId.GRID)
    result.push([cml.id, isGripGroupVisible && switches.has(cml.id)])
  }

  return result
}

export default defineComponent({
  name: 'CeGoogleMaps',
  setup() {
    return {
      // It's used to create Vue component programatically so they can have
      // access to all app's plugins.
      appContext: getCurrentInstance()?.appContext,
      mapManager: null as null | MapManager,
    }
  },
  data() {
    return {
      REF_GMAPS_HTML_EL: 'REF_GMAPS_HTML_EL',
      landmarksControl: null as null | ComponentInstance,
      mapTypeControl: null as null | ComponentInstance,
      highlightedMapLayerId: null as null | MapLayerIdHighlighted,
      infoWindow: {
        currentLayerId: null as null | MapLayerId,
        currentObject: null as null | object,
        currentComponent: null as null | ComponentInstance,
      },
      customControls: new Map() as CustomControls,
      markers: { generics: {} as { [key: string]: google.maps.Marker } },
    }
  },
  computed: {
    ...mapState(useGridMapStore, [
      'focus',
      'highlighted',
      'switches',
      'applicationMapLayers',
      'mapLayersGroups',
    ]),
    ...mapState(useCustomFiles, ['customMapLayers']),
    ...mapState(useAncestryStore, ['isUplineVisible', 'uplineResources']),
  },
  watch: {
    switches(): void {
      this.updateMapLayersVisibility()
      this.updateCustomControls()
      this.updateHighlightLayer()
    },
    focus(): void {
      this.updateFocus()
    },
    highlighted(): void {
      this.updateHighlightLayer()
    },
    customMapLayers(): void {
      this.updateCustomLayers()
      this.updateMapLayersVisibility()
    },
    applicationMapLayers(): void {
      this.updateMapLayers()
      this.updateMapLayersVisibility()
    },
    $route(newRoute: VueRouterRoute): void {
      // Checks the `meta` property of the route object for the custom flag
      // `resetMapCenterAndZoom`. Before navigating to specific routes the
      // view of the map should be resetted.
      if (newRoute.meta?.resetMapCenterAndZoom) {
        this.resetMapPosition()
      }

      // Any route change should close Street View.
      this.mapManager?.closeStreetView()
    },
    uplineResources(newValue: Resource[]): void {
      this.mapManager?.updateLayers([
        { layerId: UPLINE_LAYER_ID, newProps: { resources: newValue } },
      ])
    },
    isUplineVisible(newValue: boolean): void {
      this.mapManager?.updateLayers([
        { layerId: UPLINE_LAYER_ID, newProps: { visible: newValue } },
      ])
    },
  },
  methods: {
    ...mapActions(useNavigationControlsStore, ['updateStreetViewVisibility']),
    async initMap(): Promise<void> {
      try {
        const mapEl = this.$refs[this.REF_GMAPS_HTML_EL]

        if (!mapEl) throw new Error('HTML element is missing')

        const gmap = await createGmap(this.$rittaConfig, mapEl as HTMLElement)

        // Combine all the individual icons into a single canvas for IconLayer
        await generateIconAtlas()
        await generateTintIconAtlas()

        const mapManager = new MapManager(gmap, {
          default: {
            center: this.$rittaConfig.map
              .startingCoordinates as google.maps.LatLngLiteral,
          },
          // Move the InfoWindow up near the top of the icon
          infoWindowOffset: { width: 0, height: -ICON_MAX_PIXELS / 2 },
        })

        const landmarksControl = customMount(LandmarksControl, {
          appContext: this.appContext,
          props: {
            onChange: (visible: boolean) => {
              mapManager.setLandmarkVisibility(visible)
            },
          },
        })
        this.landmarksControl = landmarksControl

        const mapTypeControl = customMount(MapTypeControl, {
          appContext: this.appContext,
          props: {
            onChange: (mapType: google.maps.MapTypeId) =>
              gmap.setMapTypeId(mapType),
          },
        })

        this.mapTypeControl = mapTypeControl

        mapManager.setMapControls(landmarksControl.$el, mapTypeControl.$el)

        mapManager.setLandmarkVisibility(false)
        mapManager.setUpRightClickLocation()
        mapManager.setUpStreetView(this.updateStreetViewVisibility)
        mapManager.setUpInfoWindow(this.manageInfoWindow)
        mapManager.setResourceClickListener(this.onClickResource)

        this.mapManager = mapManager

        this.updateMapLayers()

        this.updateCustomLayers()

        this.updateMapLayersVisibility()

        this.updateCustomControls()

        // If a component wants to set new focus on the map.
        this.updateFocus()

        // If we have a highlight, apply it now that a MapManager instance
        // is created.
        this.updateHighlightLayer()

        // Upline feature.
        const customColor = this.$rittaConfig.map.uplineMapLayer
          ?.useDefaultThreePhaseColor
          ? getDefaultPhaseColor(ConfigPhasing.ABC).rgba
          : undefined

        this.mapManager.addLayers(
          [new UplineLayer({ customColor, id: UPLINE_LAYER_ID })],
          { independentLayers: true }
        )
        this.mapManager.updateLayers([
          {
            layerId: UPLINE_LAYER_ID,
            newProps: { visible: this.isUplineVisible },
          },
        ])
      } catch (err) {
        console.error('CeGoogleMaps.initMap: %o', err)
      }
    },
    onClickResource(r: Resource) {
      const route = routeForResource(r)
      // Verify that the resource's route is enabled and in the router config
      if (route && this.$router.hasRoute(route.name ?? '')) {
        this.$router.push(route)
      }
    },
    manageInfoWindow(
      info: HoverInfo,
      open: OpenInfoWindow,
      close: CloseInfoWindow
    ): void {
      if (info.layer == null) {
        return // No layer, no info!
      }
      // InfoWindow can be from the extra props or from the catalog
      const hoverLayerId = removeHighlightedSuffix(info.layer.id as MapLayerId)
      const infoWindowProps =
        (info.layer.props as ExtraProps).infoWindow ||
        mapLayerCatalog.get(hoverLayerId)?.infoWindow

      const object = info.object as Partial<Feature> | undefined
      const { currentLayerId, currentObject } = this.infoWindow

      // Quick exit if we don't have to make any changes
      if (currentLayerId === hoverLayerId && isEqual(currentObject, object)) {
        return
      }

      this.infoWindow.currentLayerId = null
      this.infoWindow.currentObject = null

      // Changed layer and/or object - close the current InfoWindow.
      close()
      this.destroyInfoWindowComponent()

      // If we have no object, layer, or InfoWindow details, exit early.
      if (!object || !hoverLayerId || !infoWindowProps) {
        return
      }
      // If there is no resource and the InfoWindow needs one, exit early.
      if (!object.resource && !infoWindowProps.ignoresResource) {
        return
      }
      // We have an object, a hover layer, and InfoWindowProps - yay!
      const { infoColumn, centerOfPoly } = infoWindowProps

      this.infoWindow.currentLayerId = hoverLayerId
      this.infoWindow.currentObject = object

      // If the map not showing a `Resource` fallback to the infowindow location
      let point = object.resource?.location?.point as LatLng | undefined
      const polygon = object.resource?.downline?.display?.[0]?.polygon

      if (!point && info.coordinate) {
        point = { lat: info.coordinate[1], lng: info.coordinate[0] }
      } else if (centerOfPoly && polygon) {
        // If infowindow is on a polygon, render it in the center of the shape
        point = findCenterOfPolygon(polygon)
      }

      if (point) {
        const component = customMount(InfoWindow, {
          appContext: this.appContext,
          props: { infoColumnProps: infoColumn(object) },
        })

        open(component.$el, point)

        this.infoWindow.currentComponent = component
      }
    },
    createNewVisibility(): LayersVisibility {
      return computeLayersVisibility(
        this.mapLayersGroups as MapLayerGroup[],
        this.switches,
        this.customMapLayers
      )
    },
    updateHighlightLayer(): void {
      // Always clear existing highlighted map layer.
      if (this.highlightedMapLayerId) {
        this.mapManager?.removeLayers([this.highlightedMapLayerId])
        this.highlightedMapLayerId = null
      }

      if (
        this.highlighted &&
        this.createNewVisibility().find(
          ([layerId, _isVisible]) => this.highlighted?.layerId === layerId
        )?.[1]
      ) {
        const { layerId, features } = this.highlighted

        const appLayer = mapLayerCatalog.get(layerId)

        if (appLayer?.highlightLayer) {
          const highlighlLayer = appLayer.highlightLayer(
            features as MapLayerFeature[]
          )

          const id = addHighlightedSuffix(appLayer.id)

          highlighlLayer.id = id

          this.highlightedMapLayerId = id

          this.mapManager?.addLayers([highlighlLayer])
        }
      }
    },
    destroyInfoWindowComponent(): void {
      if (this.infoWindow.currentComponent) {
        this.infoWindow.currentComponent.$destroy()
        this.infoWindow.currentComponent = null
      }
    },
    updateFocus(): void {
      if (!this.mapManager) return

      const focus = this.focus as GridMapState['focus']

      // NOTE: When the user has Street View open, searches for an address, and
      // clicks on the address, we should first close Street View.
      this.mapManager.closeStreetView()

      if (focus === null) {
        this.mapManager.clearFocus()
      } else {
        this.mapManager.focusLocations(focus)
      }
    },
    resetMapPosition(): void {
      this.mapManager?.resetMapPosition()
    },
    updateMapLayers() {
      if (this.mapManager === null) return

      const factories: FactoryMapManagerLayer[] = []

      for (const l of this.applicationMapLayers) {
        if (l.mapManagerLayer) {
          factories.push(l.mapManagerLayer as FactoryMapManagerLayer)
        }
      }

      this.mapManager.addLayers(
        factories.map((factory) =>
          factory(this.$rittaConfig, this.$services, this.mapManager!)
        )
      )
    },
    updateMapLayersVisibility(): void {
      if (!this.mapManager || !this.switches) return

      const computedSwitches = this.createNewVisibility().reduce<Set<string>>(
        (acc, [layerId, visible]) => {
          if (visible) {
            acc.add(layerId)
          }
          return acc
        },
        new Set()
      )

      this.mapManager.updateLayersVisibility(computedSwitches)
    },
    /**
     * Adds or removes custom controls based on the map layers visibilities.
     */
    updateCustomControls(): void {
      if (!this.mapManager || !this.switches) return

      for (const [layerId, visible] of this.createNewVisibility()) {
        // Add.
        if (visible) {
          const customControlProps = mapLayerCatalog
            .get(layerId as MapLayerId)
            ?.customControl?.(this.$rittaConfig)

          // No custom control, skip.
          if (!customControlProps) continue

          // Already rendered, skip.
          if (this.customControls.get(layerId as MapLayerId)) continue

          const { component: Component, position } = customControlProps

          const component = customMount(Component, {
            appContext: this.appContext,
          })
          this.mapManager.addCustomControl(component.$el, position)

          this.customControls.set(layerId as MapLayerId, {
            instance: component,
            position,
          })
        } else {
          // Remove.
          const customControl = this.customControls.get(layerId as MapLayerId)

          if (!customControl) continue

          const { instance, position } = customControl

          this.mapManager.removeCustomControl(instance.$el, position)

          instance.$destroy()

          this.customControls.delete(layerId as MapLayerId)
        }
      }
    },
    updateCustomLayers(): void {
      if (this.customMapLayers.length) {
        this.mapManager?.addLayers(this.customMapLayers.map(newCustomLayer))
      }
    },
    removeAndDestroyAllCustomControls(): void {
      for (const { instance, position } of this.customControls.values()) {
        this.mapManager?.removeCustomControl(instance.$el, position)
        instance.$destroy()
      }
    },
  },
  mounted(): void {
    this.initMap()
  },
  beforeUnmount(): void {
    this.removeAndDestroyAllCustomControls()

    // Destroy the two map controls we assign to the map
    this.mapTypeControl?.$destroy()
    this.landmarksControl?.$destroy()
  },
})
</script>

<template>
  <div class="c-PeakEventControls">
    <!-- Date input -->
    <rounded-borders-container class="mb-4">
      <div class="c-PeakEventControls__line">
        <!-- Label -->
        <div>
          <span class="text-subtitle-1 text-grey-darken-2"> Peak date </span>
        </div>

        <!-- Input -->
        <div>
          <v-radio-group
            v-model="radioOptionSelected"
            :disabled="formDisabled"
            class="pa-0 ma-0"
          >
            <v-radio
              v-for="option in radioGroupOptions"
              :key="option"
              :label="generateRadioLabel(option)"
              :value="option"
              color="secondary"
            />
          </v-radio-group>
        </div>
      </div>
    </rounded-borders-container>

    <!-- Time imput -->
    <rounded-borders-container class="mb-4">
      <div class="c-PeakEventControls__line">
        <!-- Label -->
        <div>
          <span class="text-subtitle-1 text-grey-darken-2"> Peak time </span>

          <span class="d-block">
            {{ computedTimeStart }} - {{ computedTimeEnd }} {{ timeZoneLabel }}
          </span>
        </div>

        <!-- Input -->
        <div>
          <time-range-slider
            v-model="selectedInterval"
            :disabled="formDisabled || !radioOptionSelected"
          />
        </div>
      </div>

      <!-- Message -->
      <div class="c-PeakEventControls__line">
        <span class="d-block text-caption mt-3" v-if="minDuration">
          Minimum event duration is {{ minDuration.toHuman() }}.
        </span>

        <span class="d-block text-caption mt-3" v-if="maxDuration">
          Maximum event duration is {{ maxDuration.toHuman() }}.
        </span>
      </div>
    </rounded-borders-container>

    <!-- Power imput -->
    <rounded-borders-container
      class="mb-4"
      v-if="initialPowerSetpoint !== undefined"
    >
      <div class="c-PeakEventControls__line">
        <!-- Label -->
        <div>
          <span class="text-subtitle-1 text-grey-darken-2">
            Max charge power
          </span>
        </div>

        <!-- Input -->
        <div>
          <v-text-field
            type="number"
            label="Setpoint"
            v-model="selectedPowerSetpoint"
            class="hide-arrows pr-2"
            suffix="kW"
          />
        </div>
      </div>
    </rounded-borders-container>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime, Duration, Interval } from 'luxon'

// Components
import RoundedBordersContainer from '@/components/RoundedBordersContainer.vue'
import TimeRangeSlider from '@/components/TimeRangeSlider.vue'
import { offsetNameShort } from '@/utils/time'

const RADIO_OPTION_TODAY = 'today'
const RADIO_OPTION_TOMORROW = 'tomorrow'

/**
 * This component agreggates two components.
 * One is Vuetify's VRadioGroup. The other is TimeRangeSlider.
 * Together they work as a form so the user can choose day and time range
 * for a new peak event.
 *
 * In the radio group component the user can choose the date of the peak event.
 * "Today" or "Tomorrow".
 *
 * In the time range slider component the user can set start end of the peak
 * event. It goes from "00:00" to "24:00" (displayed as "00:00").
 *
 * The only required prop is `initialInterval`.
 * It should be a valid Luxon Interval.
 * It's expected that the interval contains two Luxon DateTime instances with
 * same year, month and day.
 * Start hour and End hour will set the initial value displayed to the user
 * in the TimeRangeSlider component.
 * For example: "19:00" in the start DateTime and "21:00" in the end DateTime.
 *
 * IMPORTANT: The `initialInterval` prop won't mutate.
 * A new Luxon Interval will be emitted via `new-interval` event.
 *
 *
 * There are two other optional props:
 * - `timeZoneLabel` is used to pass a string which will then be displayed
 *    together with the time range label. Example: "MDT".
 *    The default value is a empty string.
 *    Why this prop? This way the PeakEventControls component can accept
 *    a UTC Luxon Interval or a Luxon Interval cofigured with a different time
 *    zone and be able to show it to the user.
 *
 * - `formDisabled` is used to disable the entire form
 *   The VRadioGroup component and the TimeRangeSlider component will show
 *   as disable to the user, which won't be able to modify the input values.
 *
 *
 * Example of use:
 * <peak-event-controls
 *   :initial-interval="myLuxonInterval"
 *   :form-disabled="false"
 *   @new-interval="handleNewInterval"
 * />
 */
export default defineComponent({
  name: 'PeakEventControls',
  props: {
    /**
     * Luxon Interval.
     */
    initialInterval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    /**
     * When `initialInterval` is configured with a time zone,
     * you can pass the string you want to represent the time zone in the form.
     * It only affects the layout.
     */
    timeZoneLabel: {
      type: String,
      required: false,
      default: () => offsetNameShort(),
    },
    /**
     * Through this prop the parent component can disable all
     * inputs at the same time.
     */
    formDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Smallest duration allowed.
     */
    minDuration: {
      type: Object as PropType<Duration>,
      required: false,
    },
    /**
     * Largest duration allowed.
     */
    maxDuration: {
      type: Object as PropType<undefined | Duration>,
      required: false,
    },
    /**
     * If we need to display a power setpoint, what is it's initial value?
     */
    initialPowerSetpoint: {
      type: Number as PropType<number | undefined>,
      required: false,
      default: undefined,
    },
  },
  components: {
    RoundedBordersContainer,
    TimeRangeSlider,
  },
  data() {
    const today = this.initialInterval.start.startOf('day')
    const tomorrow = today.plus({ day: 1 })
    const selectedInterval = Interval.fromDateTimes(
      this.initialInterval.start,
      this.initialInterval.end
    )
    return {
      today,
      tomorrow,
      selectedInterval,
      radioOptionSelected: undefined as string | undefined,
      radioGroupOptions: [RADIO_OPTION_TODAY, RADIO_OPTION_TOMORROW],
      selectedPowerSetpoint: this.initialPowerSetpoint?.toString(), // Text inputs work with strings.
    }
  },
  computed: {
    computedTimeStart(): string {
      return this.selectedInterval.start.toLocaleString(DateTime.TIME_24_SIMPLE)
    },
    computedTimeEnd(): string {
      return this.selectedInterval.end.toLocaleString(DateTime.TIME_24_SIMPLE)
    },
    peakEventData(): [Interval, undefined | string] {
      return [this.selectedInterval, this.selectedPowerSetpoint]
    },
  },
  watch: {
    /**
     * Watches the radio group input and creates a new Luxon Interval.
     */
    radioOptionSelected(newValue: string | undefined): void {
      // Reset selected interval.
      if (!newValue) {
        this.selectedInterval = Interval.fromDateTimes(
          this.initialInterval.start,
          this.initialInterval.end
        )
      } else {
        if (newValue === RADIO_OPTION_TODAY) {
          const { year, month, day } = this.today.toObject()
          this.selectedInterval = Interval.fromDateTimes(
            this.selectedInterval.start.set({ year, month, day }),
            this.selectedInterval.end.set({ year, month, day })
          )
        }
        if (newValue === RADIO_OPTION_TOMORROW) {
          const { year, month, day } = this.tomorrow.toObject()
          this.selectedInterval = Interval.fromDateTimes(
            this.selectedInterval.start.set({ year, month, day }),
            this.selectedInterval.end.set({ year, month, day })
          )
        }
      }
    },
    /**
     * Emits new values to the parent component.
     */
    peakEventData(): void {
      // Only emit an interval if the radio option is selected, otherwise
      // selectedInterval is not actually valid.
      const emittedInterval =
        this.radioOptionSelected === undefined
          ? undefined
          : this.selectedInterval

      const emittedSetpoint =
        this.selectedPowerSetpoint === undefined
          ? undefined
          : Number.parseFloat(this.selectedPowerSetpoint)

      this.$emit('peak-event', emittedInterval, emittedSetpoint)
    },
  },
  methods: {
    /**
     * Generates a label string for the radio option.
     */
    generateRadioLabel(option: string): string {
      let label = ''

      switch (option) {
        case RADIO_OPTION_TODAY:
          label = `${this.today.toLocaleString(DateTime.DATE_MED)} (Today)`
          break
        case RADIO_OPTION_TOMORROW:
          label = `${this.tomorrow.toLocaleString(
            DateTime.DATE_MED
          )} (Tomorrow)`
          break
      }

      return label
    },
  },
})
</script>

<style lang="scss">
.c-PeakEventControls {
  &__line {
    width: 100%;
    display: flex;

    // Left column.
    & > div:nth-of-type(1) {
      width: 40%;
    }

    // Right column.
    & > div:nth-of-type(2) {
      width: 60%;
    }
  }
}
</style>

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/forecast.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CohortsResponse, ForecastRequest, PeakLoadAnalyticsRequest, PeakLoadAnalyticsResponse, SystemPeakDemandResponse, SystemPeakRequest } from "./forecast_pb.js";
import { TimeSeriesResponse } from "./tsdb_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.Forecast2
 */
export const Forecast2 = {
  typeName: "ritta.frontend.proto.Forecast2",
  methods: {
    /**
     *  Fetches Net Load Forecast from MegaService
     *
     * @generated from rpc ritta.frontend.proto.Forecast2.GetResourceForecastData
     */
    getResourceForecastData: {
      name: "GetResourceForecastData",
      I: ForecastRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Returns a list of cohorts available for selection
     *
     * @generated from rpc ritta.frontend.proto.Forecast2.EnumCohorts
     */
    enumCohorts: {
      name: "EnumCohorts",
      I: Empty,
      O: CohortsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Returns the forecast quality times series.
     * The cohort to use is passed in the "resource" property of TimeSeriesRequest.
     * Supported metrics:
     * - TODO
     *
     * @generated from rpc ritta.frontend.proto.Forecast2.GetQualityTimeSeries
     */
    getQualityTimeSeries: {
      name: "GetQualityTimeSeries",
      I: ForecastRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Coincident Peak Load Month or Year Analytics
     * Coincident Peak Load Month Analytics (may move to a separate analytics service API)
     * Provides context about peak loads during the specified month (e.g. March) or year (e.g. 2022)
     * over a specified number of previous years
     *
     * @generated from rpc ritta.frontend.proto.Forecast2.GetPeakLoadAnalytics
     */
    getPeakLoadAnalytics: {
      name: "GetPeakLoadAnalytics",
      I: PeakLoadAnalyticsRequest,
      O: PeakLoadAnalyticsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Used by the System Peak Demand Table, shows the peak demand forecast and actual data by day
     *
     * @generated from rpc ritta.frontend.proto.Forecast2.GetSystemPeakDemand
     */
    getSystemPeakDemand: {
      name: "GetSystemPeakDemand",
      I: SystemPeakRequest,
      O: SystemPeakDemandResponse,
      kind: MethodKind.Unary,
    },
  }
};


import { Interval } from 'luxon'
import type { RouteLocationNormalized as Route } from 'vue-router'

import { MapLayerGroupId, MapLayerId } from '@/config/types'
import { Customer } from 'rfs/frontend/proto/config_pb'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'

type Layer = MapLayerGroupId | MapLayerId | string

declare global {
  // eslint-disable-next-line
  var dataLayer: Array<{}>
}

const IGNORE = ['@camus-infra.iam.gserviceaccount.com', '@camus.energy']

const IS_PROD = import.meta.env.PROD
// Playwright: check if running under automation.
const IS_AUTOMATED = navigator.webdriver
// Override this constant if you want to test analytics in development mode
const ENABLED = IS_PROD && !IS_AUTOMATED

// Before we can use any analytics APIs, we must bootstrap the global `gtag()` function.
if (ENABLED) {
  window.dataLayer = window.dataLayer || []
  window.gtag = function gtag() {
    // eslint-disable-next-line
    dataLayer.push(arguments)
  }
} else {
  // For non-production environments, specify a gtag function that just logs arguments
  window.gtag = function gtag(...args: any[]) {
    console.debug('[Analytics] gtag:', ...args)
  }
}

export const Analytics = {
  /** The analytics target ID from config */
  ID: '',
  /**
   * Initialize the gtag.js library using the given customer's config.
   * Analytics is enabled by default in production builds.
   */
  init(customer: Customer, enabled = ENABLED) {
    if (!enabled || !customer.analyticsId) return

    downloadGTagJS(customer.analyticsId)

    gtag('js', new Date())
    gtag('config', customer.analyticsId, {
      allow_google_signals: false, // Disable Google advertising features
      send_page_view: false,
      user_properties: { customer: customer.shortName },
    })

    // See https://support.google.com/analytics/answer/7201382
    if (import.meta.env.DEV) {
      gtag('config', customer.analyticsId, { debug_mode: true, update: true })
    }

    // Save the measurement ID for calls to `gtag('config', ...)`
    this.ID = customer.analyticsId
  },
  /**
   * Set the user ID to track the user across sessions.
   */
  setUser(emailAddr: string) {
    // Do not waste analytics on the feature probe user
    if (ENABLED && IGNORE.some((domain) => emailAddr.endsWith(domain))) {
      window.gtag = function noop() {}
    }
    gtag('config', this.ID, { user_id: emailAddr, update: true })
  },
  /**
   * Sends a "page_view" event.
   * @see https://developers.google.com/analytics/devguides/collection/ga4/single-page-applications?implementation=event#gtag.js
   */
  logPageView(to: Route) {
    gtag('config', this.ID, {
      page_title: to.name?.toString() || to.hash.slice(1),
      page_location: window.location.href,
      page_path: to.hash.slice(1),
      update: true,
    })
    gtag('event', 'page_view')
  },
  /**
   * Sends an event indicating the user shows or hides a map layer.
   */
  logMapLayer(layer: Layer, visible: boolean) {
    gtag('event', 'map_layer', {
      layer_id: layer,
      layer_on: visible,
    })
  },

  /**
   * Sends an event indicating the user adjusts a time range.
   */
  logSelectTimeRange(interval: Interval) {
    gtag('event', 'select_time_range', {
      length: Math.round(interval.length('days')),
    })
  },
  logSelectTimePeriod(period: CalendarPeriod) {
    gtag('event', 'select_time_range', {
      length: period === CalendarPeriod.YEAR ? 365 : 30,
    })
  },

  logTableAction(name: string) {
    gtag('event', 'table_action', { action_name: name })
  },
  logTableFilter(fields: string[]) {
    gtag('event', 'table_filter', { field_names: fields })
  },
  logTableSort(field: string) {
    gtag('event', 'table_sort', { field_name: field })
  },
}

function downloadGTagJS(measurementID: string) {
  const scriptEl = document.createElement('script')

  scriptEl.async = true
  scriptEl.src = `https://www.googletagmanager.com/gtag/js?id=${measurementID}`

  document.head.appendChild(scriptEl)
}

<template>
  <v-menu :nudge-bottom="5" offset="6">
    <!-- Activator -->
    <template v-slot:activator="{ props }">
      <v-avatar
        v-bind="props"
        color="white"
        style="cursor: pointer"
        size="large"
        role="button"
        aria-label="User"
      >
        <v-icon color="black">mdi-account-circle</v-icon>
      </v-avatar>
    </template>

    <!-- Content -->
    <div class="bg-white rounded elevation-5" style="width: 12.5rem">
      <dl aria-label="User & App information">
        <!-- Signed in as -->
        <div class="py-2 px-2" role="none">
          <dt class="d-block text-caption mb-n1">Signed in as:</dt>
          <dd class="d-block truncate text-subtitle-2">
            {{ userEmail }}
          </dd>
        </div>
        <!-- Times shown in -->
        <div class="py-2 px-2" role="none">
          <dt class="d-block text-caption mb-n1">Times shown in:</dt>
          <dd class="d-block truncate text-subtitle-2">
            {{ timeZoneName }}
          </dd>
        </div>
      </dl>

      <v-divider></v-divider>

      <v-list>
        <!-- List items -->
        <v-list-item
          v-for="item of items"
          :key="item.text"
          :href="item.href"
          :disabled="item.disabled"
          :target="item.target"
        >
          <v-list-item-title>
            <span class="text-subtitle-2">{{ item.text }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <!-- Build version -->
      <div class="d-flex py-2 px-2 text-caption" style="align-items: baseline">
        <v-icon
          v-if="isProd && !hasEqualBuildVersions"
          color="red darken-1"
          size="small"
          class="mr-2"
        >
          mdi-alert-circle
        </v-icon>
        <span class="d-block">
          {{ buildID }}

          <!-- RFS -->
          <template v-if="rfsBuildVersion && !hasEqualBuildVersions">
            <br />
            Build {{ rfsBuildVersion }} (RFS)
          </template>
        </span>
      </div>

      <template v-if="showCamusConfigs">
        <div class="py-2 px-2 text-caption">
          {{ servingCollection }}
        </div>

        <div class="py-2 px-2 text-caption">
          {{ forecast12CPVersion }}
        </div>
      </template>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Settings, Zone } from 'luxon'
import { getEnvironment } from '@/utils/environment'
import { CeUser } from '@/model/user'

type DropdownItems = {
  text: string
  href?: string
  disabled?: boolean
  target?: string
}

export default defineComponent({
  name: 'UserDropdownMenu',
  data() {
    const items: DropdownItems[] = [
      {
        text: 'User Guide',
        href: this.$rittaConfig.customer.userGuide,
        target: '_blank',
      },
      {
        text: 'Contact us',
        href: 'mailto:support@camus.energy?subject=Requesting%20Assistance',
      },
      // { text: 'Settings', disabled: true },
      // { text: 'Sign out', disabled: true },
    ]

    if (!this.$rittaConfig.customer.userGuide) {
      items.splice(0, 1) // Remove the 'User Guide' menu item
    }

    return {
      items,
      userEmail: '...',
      timeZone: Settings.defaultZone as Zone,
      rfsBuildVersion: '',
    }
  },
  computed: {
    isProd(): boolean {
      return getEnvironment() === 'production'
    },
    rfaBuildVersion(): string {
      return import.meta.env.VITE_GIT_VERSION ?? ''
    },
    hasEqualBuildVersions(): boolean {
      return this.rfaBuildVersion === this.rfsBuildVersion
    },
    buildID(): string {
      if (!this.rfaBuildVersion) {
        return 'Build version not available'
      } else {
        return `Build ${this.rfaBuildVersion}`
      }
    },
    servingCollection(): string {
      return (
        this.$rittaConfig.servingCollection ||
        'Serving collection not available'
      )
    },
    showCamusConfigs(): boolean {
      return this.userEmail.endsWith('@camus.energy')
    },
    forecast12CPVersion(): undefined | string {
      const version =
        this.$rittaConfig.analysis?.forecastDashboard?.historicalContextChart
          ?.version12CP

      return version ? `Version12CP (${version})` : 'Version12CP not available'
    },
    timeZoneName(): string {
      return this.timeZone.offsetName(Date.now(), { format: 'long' })
    },
  },
  async created() {
    await Promise.all([this.getUserEmail(), this.fetchRfsBuildVersion()])
  },
  methods: {
    async getUserEmail(): Promise<void> {
      try {
        this.userEmail = await CeUser.getUserEmail()
      } catch (err) {
        console.error('UserDropdownMenu.getUserEmail:%o', err)

        if (
          ((err as any).message as string).includes(
            `Unexpected token 'I', "IDENTITY`
          )
        ) {
          // Likely an authentication error; reloading the page prompts the user
          // to re-authenticate.
          window.location.reload()
        }
      }
    },
    async fetchRfsBuildVersion(): Promise<void> {
      try {
        this.rfsBuildVersion = (
          await (await fetch('/ritta.main/statusz')).json()
        ).stableReleaseId
      } catch (err) {
        console.error('UserDropdownMenu.fetchRfsBuildVersion: %o', err)
      }
    },
  },
})
</script>

import { setSentryUser } from '@/plugins/sentry'

export type GcpIapIdentityResponse = {
  cookie_name: string
  /**
   * The value looks like: securetoken.google.com/hce-mimo:james@camus.energy
   */
  email: string
  gcp_resource_id: string
  gcp_resource_project_number: number
  gcp_resource_type: string
  sub: string
}

const DEV_ENV_EMAIL = 'someone@camus.energy'

let gcpIapEmailCache: null | string = null

async function getUserEmailFromGcpIap(): Promise<string> {
  if (gcpIapEmailCache) return gcpIapEmailCache

  const resp = await fetch('/?gcp-iap-mode=IDENTITY', {
    credentials: 'include',
  })
  const data: GcpIapIdentityResponse = await resp.json()

  const parts = data.email.split(':')

  if (parts.length !== 2) throw new Error('unexpected string format')

  const email = parts[1]

  gcpIapEmailCache = email
  setSentryUser(gcpIapEmailCache)

  return email
}

export const CeUser = {
  async getUserEmail(): Promise<string> {
    if (!import.meta.env.PROD) {
      return DEV_ENV_EMAIL
    } else {
      return getUserEmailFromGcpIap()
    }
  },

  /** A version of getUserEmail that doesn't throw, for use in `main.ts`. */
  async getUserEmailNoThrow(): Promise<string> {
    try {
      return await this.getUserEmail()
    } catch (e) {
      console.error('[CeUser] getUserEmail failed:', e)
      return ''
    }
  },
}

<template>
  <div>
    <!-- Table -->
    <ce-data-table
      :headers="headers"
      :table="table"
      :options="options"
      :bg-color="bgColor"
      :is-loading="isLoading"
      :sticky-offset="TOP_BAR_HEIGHT"
      sticky
      @new-options="handleNewOptions"
    >
      <!-- Custom <td> content for "Policy" -->
      <template v-slot:[`item.${Columns.POLICY}`]="all">
        <!-- Exception: specific for LPEA Nuvve ('Events deleted') -->
        <template v-if="typeof all.item[Columns.POLICY] === 'string'">
          {{ all.item[Columns.POLICY] }}
        </template>
        <!-- Policy badge -->
        <policy-badge
          v-else-if="all.item[Columns.POLICY]"
          :current-policy="all.item[Columns.POLICY]"
          :current-policy-params="all.item[Columns.POLICY_PARAMS]"
        />
        <!-- Fallback -->
        <template v-else>
          {{ all.column.value(all.item) }}
        </template>
      </template>

      <!-- Custom <td> content for "Params" -->
      <template v-slot:[`item.${Columns.POLICY_PARAMS}`]="all">
        <!-- See details btn -->
        <v-btn
          v-if="all.item.infoColumnGroup || all.item.scheduleId"
          color="primary"
          variant="outlined"
          size="small"
          @click="() => openModal(all.item)"
        >
          See Details
        </v-btn>
        <!-- Fallback -->
        <template v-else>
          {{ all.column.value(all.item) }}
        </template>
      </template>
    </ce-data-table>

    <!-- Modal -->
    <v-dialog
      v-model="isDialogOpen"
      max-width="430"
      @click:outside="closeModal"
    >
      <v-card
        v-if="dialogRow"
        class="position-relative"
        style="min-height: 13rem"
      >
        <!-- Close btn -->
        <v-btn
          icon="mdi-close"
          size="small"
          elevation="0"
          aria-label="Close"
          style="position: absolute; top: 0.5rem; right: 0.5rem"
          @click="closeModal"
        />

        <!-- Loading Recurring Schedule -->
        <centered-spinner v-if="isLoadingRecurringSchedule" />

        <!-- Content -->
        <template v-else>
          <!-- Top bar -->
          <v-card-title tag="header" class="d-block pb-8 pr-12">
            <!-- Name -->
            <span aria-label="Name">
              {{ dialogRow[Columns.GROUP_NAME] }}
            </span>

            <!-- Policy -->
            <span
              v-if="dialogRow[Columns.POLICY]"
              class="d-block body-2"
              aria-label="Policy"
            >
              {{ (headerPolicy.value as Function)(dialogRow) }}
            </span>

            <!-- Created At -->
            <span class="d-block body-2" aria-label="Created At">
              {{
                `Created At: ${formatDateTime(dialogRow[Columns.CREATED_AT])}`
              }}
            </span>
          </v-card-title>

          <!-- Body -->
          <v-card-text>
            <info-column-group
              v-if="infoColumnGroup"
              :group="infoColumnGroup"
              class="pb-6"
            />
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { WHITE } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import {
  headers,
  Columns,
  createInitialOptions,
  createScheduleInfoColumnGroup,
  formatDateTime,
  headerPolicy,
  type CustomOptions,
  type HistoryDataTable,
  type LogRow,
} from '@/model/control/history'
import CeDataTable from '@/components/common/CeDataTable.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import InfoColumnGroup from '@/components/InfoColumnGroup.vue'
import PolicyBadge from '@/components/control/PolicyBadge.vue'
import type { Group as InfoColumnGroupProp } from '@/components/InfoColumnGroup'

export default defineComponent({
  name: 'HistoryDataTable',
  props: {
    table: {
      type: Object as PropType<HistoryDataTable>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CeDataTable, CenteredSpinner, InfoColumnGroup, PolicyBadge },
  setup() {
    return {
      TEXT_NO_VALUE,
      TOP_BAR_HEIGHT,
      headers,
      headerPolicy,
      Columns,
      formatDateTime,
      bgColor: WHITE.hex,
    }
  },
  data() {
    return shallowReactive({
      options: createInitialOptions(),
      isDialogOpen: false,
      dialogRow: null as null | LogRow,
      isLoadingRecurringSchedule: false,
      scheduleInfoColumnGroup: null as null | InfoColumnGroupProp,
    })
  },
  computed: {
    infoColumnGroup(): undefined | InfoColumnGroupProp {
      // Combine "Temporary Schedule" with the async Schedule info column, if both exist.
      if (this.dialogRow?.infoColumnGroup && this.scheduleInfoColumnGroup) {
        return [
          ...this.dialogRow.infoColumnGroup,
          ...this.scheduleInfoColumnGroup,
        ]
      } else {
        // Fallback for legacy behavior:
        // Return either the "Temporary Schedule" (legacy) or the Schedule info column for "Recurring Schedule".
        return this.scheduleInfoColumnGroup || this.dialogRow?.infoColumnGroup
      }
    },
  },
  methods: {
    handleNewOptions(newValue: CustomOptions): void {
      this.options = newValue
    },
    openModal(row: LogRow): void {
      this.isDialogOpen = true
      this.dialogRow = row

      // "Recurring Schedule" logs always require a schedule ID, while
      // "Temporary Schedule" logs may include it in the "nextPolicy" parameters.
      if (row.scheduleId) this.fetchRecurringSchedule(row.scheduleId)
    },
    closeModal(): void {
      this.isDialogOpen = false
      this.dialogRow = null
      this.scheduleInfoColumnGroup = null
    },
    async fetchRecurringSchedule(scheduleId: bigint): Promise<void> {
      this.isLoadingRecurringSchedule = true

      try {
        const { schedule } = await this.$services.control.getSchedule({
          id: scheduleId,
        })

        if (!schedule) throw new Error('No schedule returned')

        this.scheduleInfoColumnGroup = createScheduleInfoColumnGroup(
          scheduleId,
          schedule.metric,
          schedule.waypoints
        )
      } catch (err) {
        this.closeModal()
        console.error('HistoryDataTable.fetchSchedule: %o', err)
      } finally {
        this.isLoadingRecurringSchedule = false
      }
    },
  },
})
</script>

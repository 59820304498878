<template>
  <time-series-chart-group
    v-if="interval"
    :charts
    :dataSource
    :interval
    hide-date-range-picker
    disable-scroll
  />
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { DateTime, Interval } from 'luxon'
import { CILANTRO, PACIFIC, WALLABY } from '@/constants/colors'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import { TimeSeriesDataSource } from '@/model/charts'
import { TimeSeriesChartGroup } from '@/components/common'
import { ChartType, type ChartDefinition } from '@/types/charts'
import type { Notification } from 'rfs/frontend/proto/notification_pb'
import type { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'NotificationCharts',
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
    resource: {
      type: Object as PropType<Resource>,
      required: false,
    },
  },
  components: { TimeSeriesChartGroup },
  computed: {
    startMillis(): undefined | number {
      return this.notification.startTime?.toMillis()
    },
    endMillis(): undefined | number {
      return this.notification.endTime?.toMillis()
    },
    powerRating(): undefined | number {
      return this.resource?.ratings?.power?.apparent
    },
    transformerChart(): ChartDefinition {
      const def: ChartDefinition = {
        id: 'transformer-chart',
        type: ChartType.ApparentPower,
        title: '',
      }

      if (this.powerRating !== undefined) {
        def.annotations = {
          threshold: { yValue: this.powerRating, label: 'kVA Rating' },
        }
        def.additionalYAxis = {
          metric: Metric.RATINGS_POWER_APPARENT,
          type: ChartType.Load,
          multiplier: 1 / this.powerRating,
        }
      }

      if (this.startMillis && this.endMillis) {
        def.annotations = {
          ...def.annotations,
          highlightRanges: [
            { x1: this.startMillis, x2: this.endMillis, color: CILANTRO.hex },
          ],
        }
      }

      return def
    },
    charts(): ChartDefinition[] {
      return [this.transformerChart]
    },
    dataSource() {
      const transformerId = this.notification.resourceId ?? ''

      const ds = new TimeSeriesDataSource((request) => {
        request.aggregation = ResourceType.METER_ELECTRICAL
        return this.$services.chartsService.fetchTimeSeries(
          transformerId,
          request
        )
      })

      if (this.powerRating !== undefined) {
        ds.addChartSeries(this.transformerChart, {
          metric: Metric.RATINGS_POWER_APPARENT,
          config: { seriesName: 'Load %', seriesColor: PACIFIC.hex },
        })
      }

      ds.addChartSeries(this.transformerChart, {
        metric: Metric.COND_POWER_APPARENT,
        config: { seriesName: 'Net load (via AMI)', seriesColor: WALLABY.hex },
      })

      // TODO(rafael): Add forecast time series.

      return ds
    },
    interval(): undefined | Interval {
      if (!this.startMillis || !this.endMillis) return undefined

      return Interval.fromDateTimes(
        DateTime.fromMillis(this.startMillis).minus({ hours: 24 }),
        DateTime.fromMillis(this.endMillis).plus({ hours: 12 })
      )
    },
  },
})
</script>

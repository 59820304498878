<template>
  <div :class="hasDefaultSlot ? null : 'd-flex'">
    <!-- Add -->
    <button-segmented
      data-test="btn-add"
      :ref="REF_BTN_ADD"
      :disabled="isFormDisabled"
      icon="mdi-plus-circle-outline"
      class="highlight-on-focus"
      :class="hasDefaultSlot ? 'mb-4' : null"
      :label="btnAddLabel"
      size="default"
      @click="$emit('add')"
    />

    <v-spacer v-if="!hasDefaultSlot" />

    <!--  Default slot -->
    <slot></slot>

    <div :class="hasDefaultSlot ? 'd-flex pt-4' : null">
      <v-spacer v-if="hasDefaultSlot" />

      <!-- Cancel -->
      <v-btn
        data-test="btn-cancel"
        color="primary"
        :disabled="isSubmitting"
        variant="outlined"
        class="mr-4"
        @click="$emit('cancel')"
      >
        {{ btnCancelLabel }}
      </v-btn>

      <!-- Submit -->
      <v-btn
        data-test="btn-submit"
        color="primary"
        :disabled="isBtnSubmitDisabled"
        :loading="isSubmitting"
        flat
        @click="$emit('submit')"
      >
        {{ btnSubmitLabel }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { getWaypointLabel, type Metric } from '@/model/control/waypoint'
import { titleCase } from '@/utils/formatText'
import ButtonSegmented from '@/components/common/ButtonSegmented.vue'
import type { DeviceType } from 'rfs/control/proto/model_pb'
import { hasSlotContent } from '@/utils/vue'

export default defineComponent({
  name: 'FormActions',
  props: {
    metric: {
      type: Number as PropType<Metric>,
      required: false,
    },
    deviceType: {
      type: Number as PropType<DeviceType>,
      required: false,
    },
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditting: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTouched: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasOverlaps: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { ButtonSegmented },
  setup() {
    return {
      btnCancelLabel: 'Cancel and set no schedule',
      btnSubmitLabel: 'Set controls',
    }
  },
  data() {
    return {
      REF_BTN_ADD: 'REF_BTN_ADD',
    }
  },
  computed: {
    btnAddLabel(): string {
      return titleCase(
        `Add ${getWaypointLabel({
          metric: this.metric,
          deviceType: this.deviceType,
        })}`
      )
    },
    isFormDisabled(): boolean {
      return this.isSubmitting || this.isEditting
    },
    isBtnSubmitDisabled(): boolean {
      return !this.isTouched || this.isFormDisabled || this.hasOverlaps
    },
    hasDefaultSlot(): boolean {
      return hasSlotContent(this.$slots.default)
    },
  },
  methods: {
    /**
     * Exposed to parent component.
     */
    focusBtnAdd(): void {
      const el = (this.$refs[this.REF_BTN_ADD] as any).$el as HTMLElement

      this.$nextTick(() => {
        el.focus()
      })
    },
  },
})
</script>

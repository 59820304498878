import { Interceptor, UnaryResponse, StreamResponse } from '@connectrpc/connect'
import { AnyMessage } from '@bufbuild/protobuf'

import { Query as IQueryService } from 'rfs/frontend/proto/query_connect'
import { Control as IControlService } from 'rfs/control/proto/control_service_connect'
import { GridAnalysis as IGridAnalysis } from 'rfs/frontend/proto/analysis_connect'

/**
 * Services for which the cache interceptor is allowed to cache.
 */
export const SHOULD_CACHE_SERVICE: string[] = [
  // Control service.
  IControlService.typeName,
  // Query service
  IQueryService.typeName,
  //
  IGridAnalysis.typeName,
]

/**
 * Methods for which the cache interceptor is allowed to cache.
 */
export const SHOULD_CACHE_METHOD: string[] = [
  // Control service methods.
  IControlService.methods.getSchedule.name,
  IControlService.methods.getGroupSupportedMetricTypes.name,
  // Query service methods
  IQueryService.methods.getResource.name,
  IQueryService.methods.getResources.name,
  IQueryService.methods.searchResources.name,
  //
  IGridAnalysis.methods.getUpline.name,
]

export function isAsyncIterable(obj: any): obj is AsyncIterable<AnyMessage> {
  return obj && typeof obj[Symbol.asyncIterator] === 'function'
}

/**
 * NOTE(rafael): The ".toJsonString()" method may not provide consistent results.
 */
export function createCacheKey(
  serviceTypeName: string,
  reqMethodName: string,
  reqMessage: AnyMessage
) {
  return `${serviceTypeName}|${reqMethodName}|${reqMessage.toJsonString()}`
}

export const cacheMap = new Map<
  string,
  Promise<UnaryResponse<AnyMessage> | StreamResponse<AnyMessage, AnyMessage>>
>()

/**
 * This interceptor only caches responses from RFS that are specified in the
 * configuration arrays above.
 */
export const CacheInterceptor: Interceptor = (next) => async (req) => {
  // Pass free.
  if (isAsyncIterable(req.message)) return next(req)

  const serviceTypeName = req.service.typeName
  const reqMethodName = req.method.name

  // Check if response shold be cached.
  if (
    SHOULD_CACHE_SERVICE.includes(serviceTypeName) &&
    SHOULD_CACHE_METHOD.includes(reqMethodName)
  ) {
    const key = createCacheKey(serviceTypeName, reqMethodName, req.message)

    // Has pending promise.
    const pendingPromise = cacheMap.get(key)
    if (pendingPromise) return pendingPromise

    // Create new promise.
    const promise = next(req)

    // Save promise.
    cacheMap.set(key, promise)

    // Return promise.
    return promise
  }

  // Pass free.
  return next(req)
}

<template>
  <button
    class="c-UplineControl gmaps-box-shadow"
    draggable="false"
    aria-label="Toggle upline path"
    type="button"
    @click="toggleUplineVisibility"
  >
    <v-progress-circular
      v-if="isLoading"
      :size="20"
      :color="iconColor"
      indeterminate
    />
    <img
      v-else
      :src="iconGrid"
      :style="{
        filter: isUplineVisible ? 'url(#tint-primary)' : 'none',
      }"
      width="24px"
    />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { Analytics } from '@/utils/analytics'
import { UPLINE_LAYER_ID, useAncestryStore } from '@/stores/ancestry'
import { useGridMapStore } from '@/stores/gridMap'
import iconGrid from '@/assets/imgs/albert_ic_path--black.svg'

const GMAPS_ICON_GREY = 'rgb(102,102,102)'

export default defineComponent({
  name: 'UplineControl',
  setup() {
    return { iconGrid, gridMapStore: useGridMapStore() }
  },
  computed: {
    ...mapState(useAncestryStore, ['isLoading', 'isUplineVisible']),
    iconColor(): string {
      return this.isUplineVisible ? 'primary' : GMAPS_ICON_GREY
    },
  },
  watch: {
    isUplineVisible(newValue: boolean): void {
      Analytics.logMapLayer(UPLINE_LAYER_ID, newValue)
    },
  },
  methods: {
    ...mapActions(useAncestryStore, ['toggleUplineVisibility']),
  },
})
</script>

<style lang="scss">
.c-UplineControl {
  background: none padding-box rgb(255, 255, 255);
  height: 48px;
  width: 48px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: none padding-box rgb(235, 235, 235);
  }
}
</style>

<template>
  <component
    :is="component"
    :headers="computedHeaders"
    :items="table.rows"
    :row-props="generateRowProps"
    :expanded="expandedRows"
    :items-per-page-options="OPTION_ITEMS_PER_PAGE"
    :page="computedOptions.page"
    :items-per-page="computedOptions.itemsPerPage"
    :sort-by="computedOptions.sortBy"
    :no-data-text="table.noDataText"
    :items-length="serverItemsLength"
    :page-text="pageText"
    :loading="isLoading ? 'primary' : undefined"
    :density="dense ? 'compact' : 'default'"
    :fixed-header="fixedHeader"
    class="c-CeDataTable"
    :class="{
      'c-CeDataTable__child': isChildTable,
      'c-CeDataTable__sticky': sticky,
      'c-CeDataTable__dense': dense,
      'c-CeDataTable__ultraDense': ultraDense,
      'c-CeDataTable__borderBottom': borderBottom,
      'c-CeDataTable__lastPageBtnDisabled': hasUndeterminedNumOfPages,
    }"
    :style="{
      '--sticky-top-table-headers': stickyTopTableHeaders,
      '--bg-color': bgColor,
    }"
    @click:row="clickRow"
    @update:options="handleNewOptions"
  >
    <!-- Top Section -->
    <template v-if="showTopSection" v-slot:top>
      <div
        class="c-CeDataTable__top"
        :style="{ '--sticky-top-top-section': stickyOffset }"
      >
        <!-- Slot: above actions -->
        <slot name="above-actions" />

        <div
          v-if="showActionSection"
          aria-label="Search & Actions"
          class="d-flex py-6"
        >
          <!-- Slot: left of search -->
          <div class="flex-1">
            <slot name="left-of-search" />
          </div>

          <!-- Search -->
          <data-table-search
            v-if="search !== undefined"
            class="pr-4"
            :model-value="search"
            :is-loading="isLoading"
            :min-length="searchMinLength"
            :search-hint="searchHint"
            @update:model-value="(v) => $emit('update:search', v)"
            @search="() => $emit('search')"
          />

          <!-- Slot: left of actions -->
          <div style="flex: 1">
            <slot name="left-of-actions" />
          </div>

          <!-- Actions -->
          <data-table-actions v-if="showDataTableActions" :actions="actions" />
        </div>

        <!-- Applied filters -->
        <data-table-applied-filters
          v-if="!isChildTable && filters"
          :headers="headers"
          :filters="filters"
          @new-filters="emitNewFilters"
          @vue:updated="updateTopSectionHeight"
        />
      </div>

      <!-- Filters -->
      <data-table-filters
        v-if="!isChildTable && filters"
        v-model="showFilters"
        :headers="headers"
        :filters="filters"
        @new-filters="emitNewFilters"
      />

      <!-- Column Selector -->
      <data-table-column-selector
        v-if="!isChildTable && visibleHeaders"
        v-model="showColumnSelector"
        :headers="headers"
        :visible-headers="visibleHeaders"
        @new-visible-headers="handleNewVisibleHeaders"
      />
    </template>

    <!-- Custom <th> content for "mainHeader" -->
    <template
      v-slot:[`header.${mainHeader.key}`]="{ column, getSortIcon, isSorted }"
    >
      <data-table-custom-header :column :get-sort-icon :is-sorted>
        <template v-slot:before-title>
          <!-- Expand icon space -->
          <template v-if="!hideExpandIcon">
            <span class="pl-6" />
          </template>

          <!-- Select all control -->
          <template v-if="showSelect && table.selectable && !isChildTable">
            <div class="d-inline position-relative">
              <menu-checkbox
                :model-value="allSelected"
                :indeterminate="someRowsSelected"
                :items="table.selectMenu"
                style="position: absolute; top: -50%; left: 0"
                @update:model-value="handleClickSelectAll"
                @select="emitCustomSelect"
              />
              <span class="pl-16"></span>
            </div>
          </template>
        </template>
      </data-table-custom-header>
    </template>

    <!-- Custom <td> content for "mainHeader" -->
    <template v-slot:[`item.${mainHeader.key}`]="all">
      <div class="d-flex align-center">
        <!-- @vue-ignore: Left spacing -->
        <template v-if="parents">
          <!-- @vue-ignore -->
          <div v-for="(pr, idx) in parents" :key="idx" style="width: 1.5rem">
            <!--  -->
          </div>
        </template>

        <!-- Expand icon -->
        <template v-if="!hideExpandIcon">
          <!-- @vue-ignore -->
          <v-icon v-if="all.item.childTable" size="24px">
            {{
              isRowExpanded(all.item) ? 'mdi-chevron-down' : 'mdi-chevron-right'
            }}
          </v-icon>
          <div v-else style="width: 24px" />
        </template>

        <!-- Row selection -->
        <template v-if="showSelect && table.selectable">
          <v-checkbox
            class="d-inline-flex pr-2"
            :model-value="isRowSelected(all.item)"
            :density="dense ? 'compact' : 'default'"
            :style="{ marginLeft: dense ? '6px' : undefined }"
            style="flex: 0"
            hide-details
            @click.stop
            @update:model-value="
              (newValue) =>
                selectRow({ row: all.item, newValue: Boolean(newValue) })
            "
          />
        </template>

        <!-- Custom slot for "mainHeader" -->
        <template v-if="$slots[`item.${mainHeader.key}`]">
          <slot :name="`item.${mainHeader.key}`" v-bind="all"></slot>
        </template>

        <!-- Or just print the row's "mainHeader" value -->
        <data-table-cell v-else :row="all.item" :header="mainHeader" />
      </div>
    </template>

    <!-- Custom <th> content for some of the headers -->
    <template
      v-for="header of headersWithCustomFeatures"
      :key="header.key"
      v-slot:[`header.${header.key}`]="{ column, getSortIcon, isSorted }"
    >
      <data-table-custom-header :column :get-sort-icon :is-sorted />
    </template>

    <!-- Custom <td> content for the other headers -->
    <template
      v-for="header of otherHeaders"
      :key="header.key"
      v-slot:[`item.${header.key}`]="{ item: row }"
    >
      <data-table-cell :row :header />
    </template>

    <!-- Time Series Columns -->

    <!-- Custom <td> content for time series chart -->
    <template v-slot:[`item.${TimeSeriesColumns.TIME_SERIES}`]="{ item }">
      <v-lazy>
        <sparkline-chart
          :is-loading="item[TimeSeriesColumns.TIME_SERIES] === IS_LOADING"
          :time-series="
            item[TimeSeriesColumns.TIME_SERIES] === null ||
            item[TimeSeriesColumns.TIME_SERIES] === IS_LOADING
              ? undefined
              : item[TimeSeriesColumns.TIME_SERIES]
          "
          @vue:mounted="() => emitSparklineChartMounted(item)"
        />
      </v-lazy>
    </template>

    <!--  -->

    <!-- Expanded slot -->
    <template v-slot:expanded-row="all">
      <tr
        data-test="expanded-slot"
        v-if="isRowExpanded(all.item) && all.item.childTable"
        :key="all.item.id"
        class="white pa-0"
        style="border-bottom: thin solid rgba(0, 0, 0, 0.12) !important"
      >
        <td :colspan="all.columns.length" class="pa-0">
          <ce-data-table
            :headers="computedHeaders"
            :table="all.item.childTable"
            :show-select="showSelect"
            :expanded="expanded"
            :selected="selected"
            @new-selected="emitNewSelected"
            @new-expanded="emitNewExpanded"
            @sparkline-chart-mounted="emitSparklineChartMounted"
          >
            <!-- Pass down the custom slots -->
            <template
              v-for="key of Object.keys($slots)"
              v-slot:[`${key}`]="// @ts-ignore allProps mean any props the vuetify slot may have to share
              allProps"
            >
              <slot :name="key" v-bind="allProps"></slot>
            </template>
          </ce-data-table>
        </td>
      </tr>
    </template>

    <!-- Custom slots -->
    <template v-for="key of filteredScopedSlotKeys" v-slot:[`${key}`]="all">
      <slot :name="key" v-bind="all"></slot>
    </template>

    <!-- Hide Footer -->
    <template v-if="hideDefaultFooter" v-slot:bottom></template>
  </component>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'
import { isEqual } from 'lodash-es'
import { VDataTable, VDataTableServer } from 'vuetify/components/VDataTable'
import {
  DataOptions as VuetityDataTableOptions,
  SortItem as VuetifySortItem,
} from '@/types/vuetify3'
import {
  AnyRow,
  DataTable,
  DataTableAction,
  Header,
  Row,
  RowWithChildTable,
  RowIDSet,
  Filters,
  Options,
  NewOptionsContext,
  VisibleHeaders,
} from '@/model/tables/DataTable'
import {
  applyStickyCssClass,
  filterVisibleHeaders,
} from '@/model/tables/header'
import {
  allRowIDs,
  createVuetifyOptions,
  IS_LOADING,
  TimeSeriesColumns,
} from '@/model/tables/helper'
import DataTableSearch from '@/components/common/DataTableSearch.vue'
import DataTableCell from '@/components/common/DataTableCell.vue'
import DataTableCustomHeader from '@/components/common/DataTableCustomHeader.vue'
import DataTableActions from '@/components/common/DataTableActions.vue'
import DataTableColumnSelector from '@/components/common/DataTableColumnSelector.vue'
import DataTableFilters from '@/components/common/DataTableFilters.vue'
import DataTableAppliedFilters from '@/components/common/DataTableAppliedFilters.vue'
import MenuCheckbox from '@/components/common/MenuCheckbox.vue'
import SparklineChart from '@/components/SparklineChart.vue'
import { OPTION_ITEMS_PER_PAGE } from '@/constants/table'

type ProvideParents = undefined | DataTable[]

export default defineComponent({
  name: 'CeDataTable',
  props: {
    table: {
      type: Object as PropType<DataTable<Row>>,
      required: true,
    },
    /**
     * The first header will be used as the main header. The main header
     * is used to show the expand icon.
     */
    headers: {
      type: Array as PropType<Header[]>,
      required: true,
    },
    /** * Optional. */
    visibleHeaders: {
      type: Map as PropType<VisibleHeaders>,
      required: false,
    },
    /** * Optional. */
    options: {
      type: Object as PropType<Options>,
      required: false,
    },
    /**
     * Total count of table items available on the server.
     * Necessary with async tables.
     * Upon setting this property, VDataTable will no longer handle the row sorting.
     * Use `Number.MAX_SAFE_INTEGER` if the total number of items is not returned by the server.
     */
    serverItemsLength: {
      type: Number as PropType<number>,
      required: false,
    },
    /** * `selected` must be an external state of the selected rows. */
    selected: {
      type: Set as PropType<RowIDSet>,
      required: false,
      default: () => new Set() as RowIDSet,
    },
    /** * `expanded` must be an external state of the expanded rows. */
    expanded: {
      type: Set as PropType<RowIDSet>,
      required: false,
      default: () => new Set() as RowIDSet,
    },
    /** * `filters` must be an external state of the current filters state. */
    filters: {
      type: Map as PropType<Filters>,
      required: false,
    },
    /** * When `true`, displays checkboxes to allow the rows to be selected. */
    showSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    /** Hides or shows the footer with page size and back/forward controls. */
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    ultraDense: {
      type: Boolean,
      required: false,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Activates the "sticky" feature on the table header.
     * Important: don't forget setting the "bgColor" prop.
     */
    sticky: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Specifies the vertical offset at which the table will
     * remain fixed (sticky).
     * Accepts any valid CSS unit (e.g., 'px', '%', 'em').
     */
    stickyOffset: {
      type: String,
      required: false,
      default: '0px',
    },
    /**
     * Important: the background color is required for when the "sticky" feature
     * is activated. If you don't set a background color, the table rows
     * will still show when scrolling the table.
     * It can be any CSS value.
     */
    bgColor: {
      type: String,
      required: false,
      default: 'transparent',
    },
    /**
     * Activates the "sticky" feature on the fist column.
     * Note: this doesn't work with "sticky" headers.
     * Important: don't forget setting the "bgColor" prop.
     */
    stickyFirstColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
    downloadHref: {
      type: String as PropType<string>,
      required: false,
    },
    additionalActions: {
      type: Array as PropType<DataTableAction[]>,
      required: false,
      default: () => [],
    },
    search: {
      type: String,
      required: false,
    },
    searchMinLength: {
      type: Number,
      required: false,
    },
    searchHint: {
      type: String,
      required: false,
    },
    fixedHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'new-options',
    'new-filters',
    'reset-filters',
    'custom-select',
    'new-selected',
    'new-expanded',
    'new-visible-headers',
    'sparkline-chart-mounted',
    'update:search',
    'search',
  ],
  components: {
    VDataTable,
    VDataTableServer,
    DataTableCell,
    DataTableCustomHeader,
    DataTableActions,
    DataTableColumnSelector,
    DataTableAppliedFilters,
    DataTableFilters,
    MenuCheckbox,
    DataTableSearch,
    SparklineChart,
  },
  provide() {
    return {
      sortBy: computed(() => this.computedOptions.sortBy),
      parents: computed(() =>
        this.parents
          ? [...(this.parents as DataTable[]), this.table]
          : [this.table]
      ),
    }
  },
  inject: {
    sortBy: { default: undefined as undefined | VuetifySortItem[] },
    parents: { default: undefined as ProvideParents },
  },
  setup() {
    return {
      IS_LOADING,
      OPTION_ITEMS_PER_PAGE,
      TimeSeriesColumns,
      roTopEl: null as null | ResizeObserver,
      roHeaders: null as null | ResizeObserver,
    }
  },
  data() {
    return {
      internalOptions: createVuetifyOptions({
        page: 1,
        itemsPerPage: OPTION_ITEMS_PER_PAGE[0],
        orderBy: { column: this.headers[0].key, descending: false },
      }),
      showColumnSelector: false,
      topSectionHeight: '0px',
      headersHeight: '0px',
      showFilters: false,
      allSelected: false,
    }
  },
  computed: {
    component(): string {
      return this.serverItemsLength === undefined
        ? 'v-data-table'
        : 'v-data-table-server'
    },
    /**
     * For child tables, modifying headers is unnecessary since they are already
     * computed by the parent table.
     *
     * This computed property performs two actions:
     * 1) It filters the headers based on the selected columns, and
     * 2) It then applies CSS to make the first column sticky to the left.
     */
    computedHeaders(): Header[] {
      if (this.isChildTable) {
        return this.headers
      }

      const filteredHeaders = this.visibleHeaders
        ? filterVisibleHeaders(this.headers, this.visibleHeaders)
        : this.headers

      return this.stickyFirstColumn
        ? applyStickyCssClass(filteredHeaders)
        : filteredHeaders
    },
    mainHeader(): Header {
      return this.computedHeaders[0]
    },
    /**
     * All slots are forwarded to Vuetify's Data Table.
     * The "mainHeader" of the table and the "top" slot will be controlled and
     * rendered by us.
     */
    filteredScopedSlotKeys(): string[] {
      return Object.keys(this.$slots).filter(
        (key) => key !== `item.${this.mainHeader.key}` && key !== 'top'
      )
    },
    otherHeaders(): Header[] {
      return (
        this.computedHeaders
          // Remove the main header.
          .slice(1)
          // Remove the custom cell for Sparkline chart.
          .filter((h) => h.key !== TimeSeriesColumns.TIME_SERIES)
          // Remove custom cells controlled by the parent component.
          .filter((h) => !this.filteredScopedSlotKeys.includes(`item.${h.key}`))
      )
    },
    headersWithCustomFeatures(): Header[] {
      return (
        this.computedHeaders
          // Remove the main header.
          .slice(1)
          // Headers with tooltips.
          .filter((h) => !!h.tooltip)
      )
    },
    computedOptions(): VuetityDataTableOptions {
      if (this.isChildTable) {
        // Child tables don't do pagination, so pass options to show all rows.
        return {
          page: 1,
          itemsPerPage: -1,
          // @ts-ignore
          sortBy: this.sortBy,
          groupBy: [],
        }
      } else if (this.options) {
        return createVuetifyOptions(this.options)
      } else {
        return this.internalOptions
      }
    },
    isChildTable(): boolean {
      return !!(this.parents as ProvideParents)
    },
    /** * The ids of all expanded rows. */
    expandedRows(): string[] {
      return this.table.rows
        .filter((r) => this.expanded.has(r.id))
        .map((r) => r.id)
    },
    /** * Don't display the expand icon when all rows don't have child tables. */
    hideExpandIcon(): boolean {
      return (
        !this.isChildTable &&
        this.table.rows.every(
          (r) => !(r as RowWithChildTable<DataTable>).childTable
        )
      )
    },
    allRowsSelected(): boolean {
      return this.table.rows.every(
        (row) => this.isRowSelected(row) && this.childRowAllSelected(row)
      )
    },
    someRowsSelected(): boolean {
      if (this.allRowsSelected) return false
      // Check both the row and the rows children, since we want to indicate that
      // something is selected at any level.
      return this.table.rows.some(
        (row) => this.isRowSelected(row) || this.childRowSelected(row)
      )
    },
    hideDefaultFooter(): boolean {
      return this.isChildTable || !this.showPagination
    },
    pageText(): undefined | string {
      return !this.isChildTable &&
        this.serverItemsLength === Number.MAX_SAFE_INTEGER
        ? '{0}-{1}+'
        : undefined
    },
    hasUndeterminedNumOfPages(): boolean {
      return this.serverItemsLength === Number.MAX_SAFE_INTEGER
    },
    /**
     * The "top" value for the table headers used when the sticky feature
     * is enabled.
     */
    stickyTopTableHeaders(): string {
      return `calc(${this.stickyOffset} + ${this.topSectionHeight})`
    },
    actions(): DataTableAction[] {
      return this.isChildTable
        ? []
        : [
            ...this.additionalActions,
            {
              show: !!this.visibleHeaders,
              tooltip: 'Column Selector',
              icon: 'mdi-view-week',
              callback: () =>
                (this.showColumnSelector = !this.showColumnSelector),
              callbackRestore: () => (this.showColumnSelector = false),
            },
            {
              show: Boolean(this.downloadHref),
              tooltip: 'Download CSV',
              href: this.downloadHref,
              icon: 'mdi-download',
            },
            {
              show: Boolean(this.filters),
              tooltip: 'Show/hide filters',
              icon: 'mdi-filter-variant',
              callback: () => (this.showFilters = !this.showFilters),
              callbackRestore: () => (this.showFilters = false),
            },
          ]
    },
    showActionSection(): boolean {
      return this.showDataTableActions || this.search !== undefined
    },
    showDataTableActions(): boolean {
      return this.actions.some((a) => a.show)
    },
    showTopSection(): boolean {
      const hasSlotAboveActions = Boolean(this.$slots['above-actions'])
      const hasSlotLeftOfActions = Boolean(this.$slots['left-of-actions'])

      return (
        !this.isChildTable &&
        (hasSlotAboveActions ||
          hasSlotLeftOfActions ||
          this.search !== undefined ||
          this.showDataTableActions)
      )
    },
  },
  mounted(): void {
    this.instantiateResizeObservers()
  },
  beforeUnmount(): void {
    this.destroyResizeObservers()
  },
  methods: {
    handleNewOptions(newOptions: VuetityDataTableOptions): void {
      // Child tables must not participate with changes.
      if (this.isChildTable) return

      // Same options, skip.
      if (
        isEqual(
          JSON.parse(JSON.stringify(newOptions)),
          JSON.parse(JSON.stringify(this.computedOptions))
        )
      ) {
        return
      }

      const hasNoSortBy = !newOptions.sortBy.length
      const oldSortBy =
        this.computedOptions?.sortBy[0].key ?? this.mainHeader.key

      const options: Options = {
        itemsPerPage: newOptions.itemsPerPage,
        page: newOptions.page,
        orderBy: {
          column: hasNoSortBy ? oldSortBy : newOptions.sortBy[0].key,
          descending: hasNoSortBy
            ? false
            : newOptions.sortBy[0].order === 'desc',
        },
      }

      if (this.options) {
        // Only emit after the next tick so there's time for the sort icon
        // to change before the parent component responds to the new
        // option. Specific for server-side tables.
        this.$nextTick(() => {
          this.emitNewOptions(options)
        })
      } else {
        this.internalOptions = createVuetifyOptions(options)
      }
    },
    emitNewOptions(newOptions: Options, ctx?: NewOptionsContext): void {
      this.$emit('new-options', newOptions, ctx)
    },
    emitNewFilters(newFilters?: Filters): void {
      // Always reset the page.
      if (this.options) {
        this.emitNewOptions(
          { ...this.options, page: 1 },
          { triggeredByFilter: true }
        )
      } else {
        this.internalOptions = { ...this.internalOptions, page: 1 }
      }

      if (newFilters !== undefined) {
        this.$emit('new-filters', newFilters)
      } else {
        this.$emit('reset-filters')
      }
    },
    handleNewVisibleHeaders(newVisibleHeaders: VisibleHeaders): void {
      this.$emit('new-visible-headers', newVisibleHeaders)
    },
    isRowExpanded(row: Row): boolean {
      return this.expandedRows.some((rId) => rId === row.id)
    },
    isRowHighlighted(row: Row): boolean {
      return row.id === this.table.highlightedRowId
    },
    isRowSelected(row: Row): boolean {
      return this.selected.has(row.id)
    },
    childRowSelected(row: Row | RowWithChildTable<DataTable>): boolean {
      if ('childTable' in row) {
        return row.childTable.rows.some((row) => this.selected.has(row.id))
      } else {
        return false
      }
    },
    childRowAllSelected(row: Row | RowWithChildTable<DataTable>): boolean {
      if ('childTable' in row) {
        return row.childTable.rows.every((row) => this.selected.has(row.id))
      } else {
        return false
      }
    },
    generateRowProps(row: { item: AnyRow }): { [key: string]: any } {
      const classes: string[] = []

      if (this.isRowExpanded(row.item)) {
        classes.push('is-row-expanded')
      }

      if (this.isRowHighlighted(row.item)) {
        classes.push('is-row-highlighted')
      }

      if ((row.item as RowWithChildTable<DataTable>).childTable) {
        classes.push('cursor-pointer')
      }

      if ((row.item as RowWithChildTable<DataTable>).showAsGroup) {
        classes.push('show-as-group')
      }

      return { class: classes }
    },
    clickRow(_evt: any, row: { item: AnyRow }): void {
      if (!(row.item as RowWithChildTable<DataTable>).childTable) return

      const newExpanded = new Set(this.expanded)

      if (newExpanded.has(row.item.id)) {
        newExpanded.delete(row.item.id)
      } else {
        newExpanded.add(row.item.id)
      }

      this.emitNewExpanded(newExpanded)
    },
    selectRow({ row, newValue }: { row: Row; newValue: boolean }): void {
      const newSelected = new Set(this.selected)

      if (newValue) {
        newSelected.add(row.id)
      } else {
        newSelected.delete(row.id)
      }

      this.emitNewSelected(newSelected)
    },
    handleClickSelectAll(newValue: boolean): void {
      this.allSelected = newValue
      this.emitNewSelected(this.allSelected ? allRowIDs(this.table) : new Set())
    },
    emitCustomSelect(item: any): void {
      this.$emit('custom-select', item)
    },
    emitNewSelected(newSelected: RowIDSet): void {
      this.$emit('new-selected', newSelected)
    },
    emitNewExpanded(newExpanded: RowIDSet): void {
      this.$emit('new-expanded', newExpanded)
    },
    emitSparklineChartMounted(row: AnyRow): void {
      this.$emit('sparkline-chart-mounted', row)
    },
    instantiateResizeObservers(): void {
      if (this.isChildTable) return

      const topEl = document.querySelector('.c-CeDataTable__top')

      if (topEl) {
        this.roTopEl = new ResizeObserver(this.updateTopSectionHeight)
        this.roTopEl.observe(topEl)
      }

      const headers = document.querySelector('table > thead > tr:nth-child(1)')

      if (headers) {
        this.roHeaders = new ResizeObserver(this.updateHeadersHeight)
        this.roHeaders.observe(headers)
      }
    },
    destroyResizeObservers(): void {
      if (this.isChildTable) return

      this.roTopEl?.disconnect()
      this.roHeaders?.disconnect()
    },
    updateTopSectionHeight(): void {
      const topEl = document.querySelector('.c-CeDataTable__top')
      if (topEl) {
        const newHeight = `${topEl.getBoundingClientRect().height}px`
        if (newHeight !== this.topSectionHeight) {
          this.topSectionHeight = newHeight
        }
      }
    },
    updateHeadersHeight(): void {
      const headers = document.querySelector('table > thead > tr:nth-child(1)')
      if (headers) {
        const newHeight = `${headers.getBoundingClientRect().height}px`
        if (newHeight !== this.headersHeight) {
          this.headersHeight = newHeight
        }
      }
    },
  },
})
</script>

<style lang="scss">
.c-CeDataTable {
  background-color: var(--bg-color, transparent);
  border-color: var(--bg-color, transparent);

  @mixin rowBackground {
    background: linear-gradient(
        var(--ce-primary-color-very-low-opacity),
        var(--ce-primary-color-very-low-opacity)
      ),
      var(--bg-color, transparent);
  }

  tbody tr:not(.c-DataTableFilters) {
    // Keep the original properties but remove "background" property.
    td {
      transition-property: box-shadow, opacity, height !important;
    }
    &:hover {
      // We need to target the "td" element so it works for sticky
      // horizontal columns.
      td {
        // Applies a semi-transparent overlay color over the configured
        // bg-color so the sticky column doesn't get translucent.
        @include rowBackground;
      }
    }
  }

  .stick-to-the-left--header {
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--bg-color);
    z-index: 3 !important;
  }

  .stick-to-the-left--cell {
    position: sticky;
    top: 0;
    left: 0;
    background-color: var(--bg-color);
    z-index: 1;
  }

  .is-row-expanded {
    background-color: white;
  }

  .is-row-highlighted {
    @include rowBackground;
  }

  .show-as-group {
    // "1" is the "mainHeader" <td>
    td:not(:nth-of-type(1)) {
      // hides the text but keeps the <td> style properties as bottom border.
      color: transparent;

      // hides any other type of content injected in the <td> slot.
      & > * {
        display: none;
      }
    }
  }

  &__child {
    // Hide the entire header row.
    // Don't use `display: none`. `display: none` breaks the table layout.
    .v-table__wrapper > table > thead {
      visibility: collapse;
    }

    tr {
      background-color: white;
    }
  }

  &__sticky {
    position: relative;

    .v-table__wrapper {
      overflow: visible;
      background-color: var(--bg-color);

      // Includes the header cells and the progress bar.
      thead {
        background-color: var(--bg-color);
        position: sticky;
        top: var(--sticky-top-table-headers);
        z-index: 2;

        // For when the table is loading.
        opacity: 1 !important;
      }
    }

    .c-CeDataTable__top {
      background-color: var(--bg-color);
      position: sticky;
      top: var(--sticky-top-top-section);
      z-index: 2;
    }
  }

  &__dense {
    thead th span {
      // NOTE(rafael): text-caption
      font-size: 0.75rem !important;
    }
  }

  &__ultraDense {
    thead th span {
      // NOTE(rafael): text-caption
      font-size: 0.75rem !important;
    }

    thead tr th,
    tbody tr td {
      height: 24px !important;
    }

    // Loading bar.
    thead tr.v-data-table-progress th {
      height: 0px !important;
    }
  }

  &__borderBottom {
    table tbody tr:last-of-type td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
    }
  }

  &.v-table--fixed-header {
    height: 100%;

    .v-table__wrapper > table > thead > tr > th {
      background-color: var(--bg-color);
    }
  }

  // Vuetify 3 started adding this class to all rows when "@click:row" event.
  // We want to control when to add "cursor: pointer" ourselves, so make the
  // css class below ineffective.
  tr.v-data-table__tr--clickable {
    cursor: unset;
  }

  // Required, since the "cursor-pointer" CSS class has less specificity
  // than the change applied above for the "clickable" CSS class.
  .cursor-pointer {
    cursor: pointer !important;
  }

  // Override default loading style: reset cell opacity to 1 for full visibility
  // during loading state.
  &.v-data-table--loading {
    .v-data-table__td {
      opacity: unset;
    }
  }

  &__lastPageBtnDisabled {
    .v-pagination {
      .v-pagination__last {
        button {
          pointer-events: none;
          opacity: 0.26;
        }
      }
    }
  }

  // Loading bar. Keep it above everything else (Sticky headers and sticky left column).
  tr.v-data-table-progress {
    position: relative;
    z-index: 4;
  }

  // Fix the width of the page size dropdown.
  .v-data-table-footer__items-per-page > .v-select {
    width: 96px;
  }
}
</style>

import { Interval } from 'luxon'
import type { Services } from '@/services'
import type { ChartsService } from '@/services/charts.service'
import {
  BUBBLEGUM,
  ORANGE_CAMUS_ENERGY,
  PACIFIC,
  VELVET,
  VIOLET_500,
} from '@/constants/colors'
import { STANDARD_COLORS } from '@/constants/colorPalette'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import { getUnqualifiedId } from '@/model/resource'
import { transformerLoadingTimeSeriesConfigs } from '@/model/transformer'
import { getTimeSeriesWithin } from '@/model/charts/TimeSeriesDataSource'
import { type ChartDefinition, ChartType, NumberOrNull } from '@/types/charts'
import type { Resource } from 'rfs/pb/resource_pb'
import {
  AggregateDataSource,
  TimeSeriesDataSource,
  type ITimeSeriesDataSource,
} from '@/model/charts'
import type { ResourceImpactResponse } from 'rfs/frontend/proto/analysis_pb'

export const loadDurationChart: ChartDefinition = {
  id: 'load-duration',
  type: ChartType.LoadDuration,
  title: 'Device loading',
  xAxis: { title: 'Percent of Time', range: [0, 1.0] },
  persistVisibility: true,
}

export const deviceLoadingChart: ChartDefinition = {
  id: 'device-loading',
  type: ChartType.ApparentPower,
  title: 'Device loading',
  persistVisibility: true,
}

export const derDemandAtPeakLoad: ChartDefinition = {
  id: 'der-demand-at-peak-load',
  type: ChartType.Power,
  title: 'DER demand at peak load',
  persistVisibility: true,
}

export const derGenerationAtMinLoadChart: ChartDefinition = {
  id: 'der-generation-at-min-load',
  type: ChartType.Power,
  title: 'DER generation at min load',
  persistVisibility: true,
}

export const voltageViolationsChart: ChartDefinition = {
  id: 'voltage-violations',
  type: ChartType.Violations,
  title: 'Voltage violations',
  isStackedBar: true,
  persistVisibility: true,
  yAxis: { suggested: { min: 0, max: 4 } },
}

export const downlineMetersChart: ChartDefinition = {
  id: 'downline-meters',
  type: ChartType.Power,
  title: 'Downline Meters',
  seriesColors: STANDARD_COLORS,
}

/**
 * The device loading charts have features that are dependent on the resource's ratings.
 */
export function updateChartForResource(
  def: ChartDefinition,
  r: Resource
): ChartDefinition {
  const rating = r.ratings?.power?.apparent
  // Make a copy of the definition so we don't modify the original
  def = Object.assign({}, def)

  if (rating) {
    def.annotations = { threshold: { yValue: rating, label: 'kVA Rating' } }
  }

  return def
}

/**
 * Updates the chart definition by adding a circle annotation at the peak load.
 */
export function updateChartWithPeakLoad(
  def: ChartDefinition,
  impact?: ResourceImpactResponse
): ChartDefinition {
  const xValue = impact?.data?.peakTime?.toMillis()

  return xValue !== undefined
    ? { ...def, annotations: { timeMarkers: [{ xValue, label: 'Peak' }] } }
    : def
}

/**
 * Updates the chart definition by adding a circle annotation at the min load.
 */
export function updateChartWithMinLoad(
  def: ChartDefinition,
  impact?: ResourceImpactResponse
): ChartDefinition {
  const xValue = impact?.data?.minTime?.toMillis()

  return xValue !== undefined
    ? {
        ...def,
        annotations: { timeMarkers: [{ xValue, label: 'Min' }] },
      }
    : def
}

/**
 * Creates a new TimeSeriesDataSource for use in a Downline Meters chart.
 * The data source supports zooming and panning
 */
export function newMeterDataSource(
  chartsService: ChartsService,
  meterId: string
) {
  const dataSource = new TimeSeriesDataSource((request) => {
    return chartsService.fetchTimeSeries(meterId, request)
  })

  dataSource.addChartSeries(downlineMetersChart, {
    metric: Metric.POWER_CONSUMED_NET,
    resource: meterId,
    config: { seriesName: getUnqualifiedId(meterId), seriesColor: '' },
  })

  return dataSource
}

export function newTransformerMeterDataSource(
  chartsService: ChartsService,
  meterId: string
) {
  const dataSource = new TimeSeriesDataSource((request) => {
    return chartsService.fetchTimeSeries(meterId, request)
  })

  dataSource.addChartSeries(downlineMetersChart, {
    metric: Metric.POWER_CONSUMED_NET,
    config: { seriesName: getUnqualifiedId(meterId), seriesColor: '' },
  })

  return dataSource
}

export function newResourceImpactDataSource(
  resource: Resource,
  services: Services
): ITimeSeriesDataSource {
  const ds1 = new TimeSeriesDataSource((request) => {
    request.aggregation = ResourceType.METER_ELECTRICAL
    return services.chartsService.fetchTimeSeries(resource.id, request)
  })
  // This data source is for the DER demand chart
  const ds2 = new TimeSeriesDataSource((request) => {
    request.aggregation = ResourceType.CHARGER
    return services.chartsService.fetchTimeSeries(resource.id, request)
  })
  // This data source is for the DER generation chart
  const ds3 = new TimeSeriesDataSource((request) => {
    request.aggregation = ResourceType.SOLAR_DISTRIBUTED
    return services.chartsService.fetchTimeSeries(resource.id, request)
  })

  // Device Loading chart.
  for (const seriesConfig of transformerLoadingTimeSeriesConfigs) {
    ds1.addChartSeries(deviceLoadingChart, seriesConfig)
  }

  ds1
    .addChartSeries(loadDurationChart, {
      metric: Metric.COND_POWER_APPARENT,
      config: { seriesName: 'Net load (via AMI)', seriesColor: PACIFIC.hex },
    })
    .addChartSeries(derDemandAtPeakLoad, {
      metric: Metric.COND_POWER_REAL,
      aggregation: ResourceType.METER_ELECTRICAL,
      config: { seriesName: 'Net load', seriesColor: PACIFIC.hex },
    })
    .addChartSeries(derGenerationAtMinLoadChart, {
      metric: Metric.COND_POWER_REAL,
      aggregation: ResourceType.METER_ELECTRICAL,
      config: { seriesName: 'Net load', seriesColor: PACIFIC.hex },
    })
    .addChartSeries(voltageViolationsChart, {
      metric: Metric.AGGREGATION_COUNT_VOLTAGE_MIN,
      aggregation: ResourceType.METER_ELECTRICAL,
      config: {
        seriesName: 'Under voltage',
        seriesColor: VIOLET_500.hex,
        minBarLength: 2,
      },
    })
    .addChartSeries(voltageViolationsChart, {
      metric: Metric.AGGREGATION_COUNT_VOLTAGE_MAX,
      aggregation: ResourceType.METER_ELECTRICAL,
      config: {
        seriesName: 'Over voltage',
        seriesColor: ORANGE_CAMUS_ENERGY.hex,
        minBarLength: 2,
      },
    })

  ds2.addChartSeries(derDemandAtPeakLoad, {
    metric: Metric.COND_POWER_REAL,
    aggregation: ResourceType.CHARGER,
    config: { seriesName: 'EV load', seriesColor: VELVET.hex },
  })
  ds3.addChartSeries(derGenerationAtMinLoadChart, {
    metric: Metric.COND_POWER_REAL,
    unit: '-W',
    aggregation: ResourceType.SOLAR_DISTRIBUTED,
    config: { seriesName: 'DPV generation', seriesColor: BUBBLEGUM.hex },
  })

  // if (hasForecastData(resource)) {
  //   ds1.addChartSeries(deviceLoadingChart, {
  //     metric: Metric.FORECAST_LATEST_COND_POWER_APPARENT,
  //     config: {
  //       seriesName: 'Forecast',
  //       seriesColor: PACIFIC.hex,
  //       seriesLine: 'dashed',
  //     },
  //   })
  // }

  return new AggregateDataSource(ds1, ds2, ds3)
}

// function hasForecastData(r: Resource): boolean {
//   return r.type === ResourceType.TRANSFORMER
// }

/**
 * Get the max over and under voltage violation counts for a given interval.
 */
export function getVoltageViolationStats(
  interval: Interval,
  dataSource: ITimeSeriesDataSource
): {
  maxOverVoltage: NumberOrNull
  maxUnderVoltage: NumberOrNull
} {
  const data = getTimeSeriesWithin(interval, dataSource)

  const voltageData = data.get(voltageViolationsChart.id)
  const maxOverVoltage =
    voltageData?.find((x) => x.metric === Metric.AGGREGATION_COUNT_VOLTAGE_MAX)
      ?.max?.y ?? null
  const maxUnderVoltage =
    voltageData?.find((x) => x.metric === Metric.AGGREGATION_COUNT_VOLTAGE_MIN)
      ?.max?.y ?? null

  return { maxOverVoltage, maxUnderVoltage }
}

<template>
  <div class="c-InfoPanel">
    <!-- Tabs -->
    <div
      class="tabs"
      :class="{ 'tabs--centered': centerTabs }"
      :style="{ '--justify-content': centerTabs ? 'center' : 'unset' }"
    >
      <v-tooltip
        v-for="(tab, idx) in tabs"
        :key="tab.id"
        :open-delay="OPEN_DELAY"
        location="bottom"
        class="d-block"
      >
        <template v-slot:activator="{ props }">
          <div
            class="tab"
            :class="{
              'tab--selected': isTabSelected(tab),
              'tab--disabled': tab.disabled,
              'tab--no-border':
                noLastTabBorder && isTabSelected(tab) && isLastTab(idx),
            }"
            :style="{
              backgroundColor: isTabSelected(tab) ? bgColorHex : undefined,
            }"
            @click="selectTab(tab)"
            v-bind="props"
          >
            <!-- Icon -->
            <img v-if="tab.icon" :src="tab.icon" width="24px" height="24px" />

            <!-- Text -->
            <span
              v-else
              class="d-block body-2 font-weight-medium text-uppercase"
              :class="{ 'primary--text': !isTabSelected(tab) && !tab.disabled }"
            >
              {{ tab.text }}
            </span>
          </div>
        </template>

        <!-- Tooltip text -->
        {{ tab.text }}
      </v-tooltip>
    </div>

    <!-- Content -->
    <div
      class="content"
      :class="{ 'content--with_borders': !noContentBorder }"
      :style="{ backgroundColor: bgColorHex }"
    >
      <slot v-if="modelValue" :name="modelValue" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Color, WHITE } from '@/constants/colors'
import { OPEN_DELAY } from '@/constants/tooltip'

export type InfoPanelTab = {
  id: string
  text: string
  icon?: string
  disabled?: boolean
}

/**
 * InfoPanel Component
 *
 * Renders a panel with tabs for navigation. The selected tab controls which slot content is displayed.
 *
 * Props:
 * - `tabs` (required): An array of `InfoPanelTab` objects representing each tab.
 * - `modelValue` (required): A two-way binding prop representing the currently selected tab's ID.
 * - `bgColor` (optional): Sets the background color of the panel.
 * - `centerTabs` (optional, default: false): Centers the tabs within the panel.
 * - `noContentBorder` (optional, default: false): Hides borders around the content section.
 * - `noLastTabBorder` (optional, default: false): Removes the right border for the last selected tab.
 *
 * `InfoPanelTab` object:
 * - `id` (string): Unique identifier for the tab; also determines the slot to render.
 * - `text` (string): Tab label. Used as tooltip text when an icon is also present.
 * - `icon` (optional, string): URL for an icon displayed on the tab.
 * - `disabled` (optional, boolean): If true, the tab is not clickable.
 *
 * Events:
 * - `update:modelValue`: Emitted when a tab is selected, updating the bound value.
 *
 * Usage Example:
 * ```html
 * <info-panel :tabs="[{ id: 'location', text: 'Location' }]" v-model="selectedTab">
 *   <template v-slot:location>
 *     <!-- Content for the 'location' tab -->
 *   </template>
 * </info-panel>
 * ```
 *
 * In this example, selecting a tab updates `selectedTab` with the tab's `id`.
 */
export default defineComponent({
  name: 'InfoPanel',
  props: {
    tabs: {
      type: Array as PropType<InfoPanelTab[]>,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    bgColor: {
      type: Object as PropType<undefined | Color>,
      required: false,
    },
    /**
     * Centers the tabs on the screen.
     * By default, the tabs are aligned to the left.
     */
    centerTabs: {
      type: Boolean,
      required: false,
      default: false,
    },
    noContentBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    noLastTabBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return { OPEN_DELAY }
  },
  computed: {
    bgColorHex(): string {
      return this.bgColor?.hex ?? WHITE.hex
    },
  },
  methods: {
    selectTab(tab: InfoPanelTab): void {
      if (tab.disabled) return
      this.$emit('update:model-value', tab.id)
    },
    isTabSelected(tab: InfoPanelTab): boolean {
      return tab.id === this.modelValue
    },
    isLastTab(tabIndex: number): boolean {
      return this.tabs.length - 1 === tabIndex
    },
  },
})
</script>

<style lang="scss" scoped>
.c-InfoPanel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  --border-color: #dddddd;
  --border-width: 1px;
  --border-style: solid;
  --tab-height: 3rem;
  --tab-max-width: 16.625rem;
  --tab-disabled-opacity: 0.2;
  --content-padding: 1.5rem;

  .tabs {
    display: flex;
    justify-content: var(--justify-content, unset);
    height: var(--tab-height);
    border-bottom: var(--border-width) var(--border-style) var(--border-color);
  }

  .tabs--centered {
    .tab {
      &--selected {
        &:nth-of-type(1) {
          border-left: var(--border-width) var(--border-style)
            var(--border-color);
        }
      }
    }
  }

  .content {
    width: 100%;
    overflow-y: auto; /* Ensures scrollability when the content height exceeds the panel's height, e.g., in the "Grid" tab. */
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: var(--content-padding);

    &--with_borders {
      border-right: var(--border-width) var(--border-style) var(--border-color);
      border-bottom: var(--border-width) var(--border-style) var(--border-color);
    }
  }

  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-top: var(--border-width) var(--border-style) transparent;
    border-right: var(--border-width) var(--border-style) transparent;
    border-left: var(--border-width) var(--border-style) transparent;
    max-width: var(--tab-max-width);

    &--selected {
      border-top: var(--border-width) var(--border-style) var(--border-color);
      border-right: var(--border-width) var(--border-style) var(--border-color);
      border-left: var(--border-width) var(--border-style) var(--border-color);
      margin-bottom: calc(-1 * var(--border-width));

      &:nth-of-type(1) {
        border-left: var(--border-width) var(--border-style) transparent;
      }
    }

    &--disabled {
      opacity: var(--tab-disabled-opacity);
      cursor: default;
    }

    &--no-border {
      border-right: none;
    }
  }
}
</style>

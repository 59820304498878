<template>
  <section
    aria-label="Schedule expiration options"
    class="rounded px-4 py-5"
    :style="{ border: `1px solid ${strokeColor}`, backgroundColor: bgColor }"
  >
    <p class="text-subtitle-1 font-weight-medium pb-4">
      {{ computedTitle }}
    </p>
    <v-radio-group
      :model-value="modelValue"
      class="py-1"
      @update:model-value="emitNewOption"
    >
      <v-radio
        v-for="option in computedOptions"
        :key="option.value"
        :value="option.value"
        :label="option.label"
        :disabled="isSubmitting"
      >
        <template v-if="option.moreInfo" v-slot:label>
          <span class="d-flex flex-column">
            {{ option.label }}
            <span class="d-block text-caption">
              {{ option.moreInfo }}
            </span>
          </span>
        </template>
      </v-radio>
    </v-radio-group>

    <p v-if="showRequired" class="text-caption text-error pt-6">
      Please select one option.
    </p>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { GRAY_COOL_50, NEUTRAL_200 } from '@/constants/colors'
import {
  LABEL_CONTROLS_OFF,
  LABEL_ENVELOPES_ONLY,
  LABEL_RECU_SCHEDULE,
} from '@/model/control/labels'
import { isRecurringSchedule } from '@/model/control/group/helper'
import { Group } from 'rfs/control/proto/model_pb'

export const RADIO_OPTION_CONTROLS_OFF = 'CONTROLS_OFF' as const
export const RADIO_OPTION_ENVELOPES_ONLY = 'ENVELOPES_ONLY' as const
export const RADIO_OPTION_REPLACE_WITH_RECURR = 'REPLACE' as const

const allOptions = [
  RADIO_OPTION_CONTROLS_OFF,
  RADIO_OPTION_ENVELOPES_ONLY,
  RADIO_OPTION_REPLACE_WITH_RECURR,
] as const

export type RadioOption = (typeof allOptions)[number]

export default defineComponent({
  name: 'ExpirationRadioGroup',
  props: {
    /* v-model */
    modelValue: {
      type: String as PropType<RadioOption>,
      required: false,
    },
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    hasOEEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasNoWaypoints: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return { strokeColor: NEUTRAL_200.hex, bgColor: GRAY_COOL_50.hex }
  },
  data() {
    return shallowReactive({ showRequired: false })
  },
  computed: {
    computedTitle(): string {
      // When the user deletes all existing temporary waypoints and wants to
      // cancel the "Temporary Schedule" policy.
      if (this.hasNoWaypoints) {
        return 'Group should revert to:'
      } else {
        return 'Upon schedule expiration, group will revert to:'
      }
    },
    options(): RadioOption[] {
      // "Controls off" option should always be displayed.
      const options: RadioOption[] = [RADIO_OPTION_CONTROLS_OFF]

      // "Envelopes only" option only when Operating Envelopes feature is enabled.
      if (this.hasOEEnabled) {
        options.push(RADIO_OPTION_ENVELOPES_ONLY)
      }

      // "Recurring Schedule" option only when current or next policy.
      if (
        isRecurringSchedule(
          this.group.currentPolicy,
          this.group.currentPolicyParams
        ) ||
        isRecurringSchedule(
          this.group.nextPolicy,
          this.group.nextPolicyParameters
        )
      ) {
        options.push(RADIO_OPTION_REPLACE_WITH_RECURR)
      }

      return options
    },
    computedOptions() {
      return this.options.map((option) => {
        return {
          value: option,
          label: this.generateRadioLabel(option),
          moreInfo: this.generateMoreInfo(option),
        }
      })
    },
  },
  watch: {
    modelValue(newValue: undefined | RadioOption): void {
      // Hide the validation message as soon as the user selects an option.
      if (newValue !== undefined) {
        this.showRequired = false
      }
    },
    options(): void {
      // Automatically unselect the option if there's no "Recurring Schedule"
      // available anymore to be used as "nextPolicy".
      if (
        this.modelValue === RADIO_OPTION_REPLACE_WITH_RECURR &&
        !this.options.includes(RADIO_OPTION_REPLACE_WITH_RECURR)
      ) {
        this.emitNewOption(undefined)
      }
    },
  },
  methods: {
    generateRadioLabel(option: RadioOption): string {
      switch (option) {
        case RADIO_OPTION_CONTROLS_OFF:
          return `${LABEL_CONTROLS_OFF}: All group members will enter “local” control mode and will not receive commands from Camus.`
        case RADIO_OPTION_ENVELOPES_ONLY:
          return LABEL_ENVELOPES_ONLY
        case RADIO_OPTION_REPLACE_WITH_RECURR:
          return `${LABEL_RECU_SCHEDULE}: The recurring schedule that this temporary schedule will replace.`
        default:
          throw new Error(
            'ExpirationRadioGroup.generateRadioLabel: unexpected option'
          )
      }
    },
    generateMoreInfo(option: RadioOption): undefined | string {
      if (option === RADIO_OPTION_REPLACE_WITH_RECURR && this.hasOEEnabled) {
        return 'Note that the envelope status from this schedule will persist.'
      } else {
        return undefined
      }
    },
    /**
     * Exposed to parent component.
     */
    showValidationMsg(): void {
      this.showRequired = true
    },
    emitNewOption(newValue: undefined | null | RadioOption): void {
      // Vuetify says it can emit `null` so protect from this case.
      this.$emit('update:model-value', !newValue ? undefined : newValue)
    },
  },
})
</script>

<style lang="scss">
section[aria-label='Schedule expiration options'] {
  .v-radio-group {
    .v-selection-control {
      align-items: start;
    }
  }
}
</style>

<template>
  <div class="position-relative min-height-100 pa-6">
    <!-- Title -->
    <ce-title class="pb-12" big>Forecast</ce-title>

    <!-- Net load chart -->
    <time-series-chart-group
      v-if="netLoadChartEnabled"
      :charts
      :data-source
      :interval
      :max-end-time
      @new-interval="interval = $event"
    />

    <template v-if="historicalChartEnabled">
      <!-- 12CP -->
      <div v-if="cp12Enabled" class="pb-16">
        <!-- Historical Context chart -->
        <h3 class="pr-2">
          {{ chartTitle12P }}
          <ce-tooltip z-index="10" :text="chartTooltip12CP" type="info" />
        </h3>
        <forecast-historical-context-chart
          v-if="balancingArea12CP"
          :balancing-area="balancingArea12CP"
          :number-of-years="config?.historicalContextChart?.numberOfYears"
          :reference-period="calendarPeriod12CP"
          :product-id="config?.historicalContextChart?.products12CP?.megawatt"
        />

        <!-- System Peak Demand table -->
        <peak-demand-data-table
          :response="systemPeakDemand12cp.response"
          :is-loading="systemPeakDemand12cp.isLoading"
        />
      </div>

      <!-- 1CP -->
      <template v-if="cp1Enabled">
        <!-- Historical Context chart -->
        <h3 class="pr-2">
          {{ chartTitle1P }}
          <ce-tooltip z-index="10" :text="chartTooltip1CP" type="info" />
        </h3>
        <forecast-historical-context-chart
          v-if="balancingArea1CP"
          :balancing-area="balancingArea1CP"
          :number-of-years="config?.historicalContextChart?.numberOfYears"
          :reference-period="calendarPeriod1CP"
          :product-id="config?.historicalContextChart?.products1CP?.megawatt"
        />

        <!-- System Peak Demand table -->
        <peak-demand-data-table
          yearly
          :response="systemPeakDemand1cp.response"
          :is-loading="systemPeakDemand1cp.isLoading"
        />
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { isEmpty } from 'lodash-es'
import CeTooltip from '@/components/CeTooltip.vue'
import { TimeSeriesChartGroup } from '@/components/common'
import type { AnalyzeConfig_ForecastDashboard as ForecastDashboard } from 'rfs/frontend/proto/config_pb'
import type { ITimeSeriesDataSource } from '@/model/charts'
import { SystemPeakDemandResponse } from 'rfs/frontend/proto/forecast_pb'
import { PeakPeriod } from '@/services/forecast.service'
import { intervalLast7Days } from '@/utils/time'
import {
  createNetLoadChart,
  createNetLoadDataSource,
} from './ForecastChartData'
import CeTitle from '@/components/CeTitle.vue'
import ForecastHistoricalContextChart from './ForecastHistoricalContextChart.vue'
import PeakDemandDataTable from './PeakDemandDataTable.vue'

export default defineComponent({
  name: 'ForecastDashboard',
  components: {
    CeTitle,
    CeTooltip,
    TimeSeriesChartGroup,
    ForecastHistoricalContextChart,
    PeakDemandDataTable,
  },
  setup() {
    return {
      calendarPeriod12CP: PeakPeriod.TWELVE_CP,
      calendarPeriod1CP: PeakPeriod.ONE_CP,
    }
  },
  data() {
    const now = this.$observationTime()
    // Last 7 days plus two days ahead
    let interval = intervalLast7Days(now)
    interval = interval.set({ end: interval.end.plus({ days: 2 }) })

    return shallowReactive({
      now,
      interval,
      // Max is also two days ahead
      maxEndTime: interval.end,

      systemPeakDemand12cp: shallowReactive({
        isLoading: false,
        response: new SystemPeakDemandResponse(),
      }),
      systemPeakDemand1cp: shallowReactive({
        isLoading: false,
        response: new SystemPeakDemandResponse(),
      }),
    })
  },
  computed: {
    config(): undefined | ForecastDashboard {
      return this.$rittaConfig.analysis?.forecastDashboard
    },
    cp1Enabled(): boolean {
      return !isEmpty(this.config?.historicalContextChart?.products1CP)
    },
    cp12Enabled(): boolean {
      return !isEmpty(this.config?.historicalContextChart?.products12CP)
    },
    historicalChartEnabled(): boolean {
      return this.config?.historicalContextChart?.enabled ?? false
    },
    netLoadChartEnabled(): boolean {
      return this.config?.netLoadChart?.enabled ?? false
    },
    balancingArea1CP(): undefined | string {
      return this.config?.historicalContextChart?.products1CP?.balancingArea
    },
    balancingArea12CP(): undefined | string {
      return this.config?.historicalContextChart?.products12CP?.balancingArea
    },
    netLoadChart() {
      return createNetLoadChart(this.$rittaConfig)
    },
    charts() {
      return [this.netLoadChart]
    },
    dataSource(): ITimeSeriesDataSource {
      return createNetLoadDataSource(
        this.$rittaConfig,
        this.$services,
        this.netLoadChart,
        this.maxEndTime
      )
    },
    chartTooltip1CP(): string {
      return this.chartTooltip(
        this.config?.historicalContextChart?.products1CP
          ?.balancingAreaDisplay ?? ''
      )
    },
    chartTooltip12CP(): string {
      return this.chartTooltip(
        this.config?.historicalContextChart?.products12CP?.balancingArea ?? ''
      )
    },
    // To distinguish between Coincident Peak and Self Peak
    chartTitle1P(): string {
      const model = this.config?.historicalContextChart?.products1CP?.megawatt
      const modelType = model?.split('-').at(-1)?.toUpperCase()
      return `${modelType} System Peak Demand - ${this.now.year}`
    },
    chartTitle12P(): string {
      let chartTitle =
        this.config?.historicalContextChart?.products12CP?.chartTitle
      if (!chartTitle) {
        const model =
          this.config?.historicalContextChart?.products12CP?.megawatt
        const modelType = model?.split('-').at(-1)?.toUpperCase()
        chartTitle = `${modelType} System Peak Demand`
      }
      return `${chartTitle} - ${this.now.monthLong}`
    },
  },
  created(): void {
    this.fetchData()
  },
  methods: {
    async fetchData(): Promise<void> {
      if (!this.historicalChartEnabled) return
      this.fetch12CP()
      this.fetch1CP()
    },
    async fetch12CP(): Promise<void> {
      if (!this.cp12Enabled) return

      this.systemPeakDemand12cp.isLoading = true

      try {
        this.systemPeakDemand12cp.response =
          await this.$services.forecastService.getSystemPeakDemand(
            this.now,
            PeakPeriod.TWELVE_CP,
            this.config!.historicalContextChart!.products12CP
          )
      } catch (err) {
        console.error('ForecastDashboard.fetch12CP: %o', err)
      } finally {
        this.systemPeakDemand12cp.isLoading = false
      }
    },
    async fetch1CP(): Promise<void> {
      if (!this.cp1Enabled) return

      this.systemPeakDemand1cp.isLoading = true

      try {
        this.systemPeakDemand1cp.response =
          await this.$services.forecastService.getSystemPeakDemand(
            this.now,
            PeakPeriod.ONE_CP,
            this.config!.historicalContextChart!.products1CP
          )
      } catch (err) {
        console.error('ForecastDashboard.fetch1CP: %o', err)
      } finally {
        this.systemPeakDemand1cp.isLoading = false
      }
    },
    chartTooltip(balancingArea: string): string {
      const forecastDays = this.config?.historicalContextChart?.forecastDays
      const numberOfYears = this.config?.historicalContextChart?.numberOfYears
      const peakDescription =
        this.config?.historicalContextChart?.peakDescription ??
        'native daily peak demand'
      const graphDescription = `This graph displays the range of data observed during the previous
              ${numberOfYears} years for ${balancingArea}'s ${peakDescription}.`
      return `${graphDescription}
              The min/max for each day are presented as bars. The top 5 values
              for each year are presented as squares (peak day) and circles (top 2-5 days),
              and are colored by year. The actuals for the month to date are shown
              in the blue trend line, and the forecasts for the next ${forecastDays} days
              are shown as a dashed trend line.`
    },
  },
})
</script>

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { RittaConfig, TabId } from '@/config/types'
import { setLeftPanel } from '@/utils/router'
import { Analytics } from '@/utils/analytics'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import * as RouteNames from './routeNames'

import { analyzeRoutes } from './analyzeRoutes'
import { controlRoutes } from './controlRoutes'
import { meterRoutes } from './meterRoutes'
import { voltageMappingRoutes } from './voltageMappingRoutes'
import { substationRoutes } from './substationRoutes'
import { fleetRoutes } from './fleetRoutes'
import { transformerRoutes } from './transformerRoutes'
import { siteRoutes } from './siteRoutes'
import { dersRoutes } from './dersRoutes'
import { resourceRoutes } from './resourceRoutes'
import { notificationRoutes } from './notificationRoutes'

import Home from '@/views/Home.vue'

export const TITLE_OVERVIEW = 'Overview'

export function newRouter(config: Readonly<RittaConfig>) {
  const initialRoute = config.initialRoute ?? RouteNames.OVERVIEW

  let routes: Array<RouteRecordRaw> = [
    {
      path: '/overview',
      component: Home,
      name: RouteNames.OVERVIEW,
      meta: {
        tab: TabId.MONITOR,
        resetMapCenterAndZoom: true,
        drawerLeftDisabled: true,
        pageTitle: TITLE_OVERVIEW,
      },
    },
    ...meterRoutes(config),
    ...siteRoutes(config),
    ...voltageMappingRoutes(config),
    ...substationRoutes(config),
    ...fleetRoutes(config),
    ...analyzeRoutes(config),
    ...controlRoutes(config),
    ...dersRoutes(config),
    ...transformerRoutes(config),
    ...resourceRoutes(config, initialRoute),
    ...notificationRoutes(config),
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      redirect: () => ({
        name: initialRoute,
        // https://github.com/vuejs/router/issues/1617#issuecomment-1320941431
        params: {},
      }),
    },
  ]

  if (config.featureFlags.flexKit) {
    // Remove all the monitor routes so the tab is disabled
    routes = routes.filter((route) => route.meta?.tab !== TabId.MONITOR)
  }

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
  })

  router.beforeEach((to, _from, next) => {
    // Remove the hash (#) from the URL and perform a redirect. This is
    // necessary because we were using the "createWebHashHistory" mode.
    // We want to ensure backward compatibility.
    if (to.hash) {
      return next(to.hash.slice(1))
    }

    setLeftPanel(to)
    next()
  })
  router.afterEach((to) => {
    useNavigationControlsStore().setPageTitle(
      typeof to.meta.pageTitle === 'function'
        ? to.meta.pageTitle(to.params)
        : to.meta.pageTitle
    )
    Analytics.logPageView(to)
  })

  return router
}

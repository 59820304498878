// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/query.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetResourceRequest, GetResourceResponse, GetResourcesRequest, GetResourcesResponse, SearchRequest, SearchResponse } from "./query_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Query Service locates records that match some criteria, partitions them into groupings, and
 * returns information about them
 *
 * @generated from service ritta.frontend.proto.Query
 */
export const Query = {
  typeName: "ritta.frontend.proto.Query",
  methods: {
    /**
     * GetResource accepts a resource id and returns a Resource message if the resource can be found;
     * if the resource cannot be found, the `resource` field will not be populated.
     *
     * @generated from rpc ritta.frontend.proto.Query.GetResource
     */
    getResource: {
      name: "GetResource",
      I: GetResourceRequest,
      O: GetResourceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * GetResources returns a list of resources associated with a given identifier. If no resources
     * are associated with a given identifier, the `resources` field of the response will be a zero
     * length list.
     *
     * @generated from rpc ritta.frontend.proto.Query.GetResources
     */
    getResources: {
      name: "GetResources",
      I: GetResourcesRequest,
      O: GetResourcesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SearchResources returns resources using a text query
     *
     * @generated from rpc ritta.frontend.proto.Query.SearchResources
     */
    searchResources: {
      name: "SearchResources",
      I: SearchRequest,
      O: SearchResponse,
      kind: MethodKind.Unary,
    },
  }
};


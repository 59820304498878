// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/capacity.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { SupplyDemandRequest } from "./capacity_pb.js";
import { TimeSeriesRequest, TimeSeriesResponse } from "./tsdb_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.CapacityService
 */
export const CapacityService = {
  typeName: "ritta.frontend.proto.CapacityService",
  methods: {
    /**
     * Supply and Demand BigT
     *
     * @generated from rpc ritta.frontend.proto.CapacityService.SupplyDemandTimeSeries
     */
    supplyDemandTimeSeries: {
      name: "SupplyDemandTimeSeries",
      I: SupplyDemandRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Emissions Intensity TimeSeries
     *
     * @generated from rpc ritta.frontend.proto.CapacityService.EmissionsIntensityTimeSeries
     */
    emissionsIntensityTimeSeries: {
      name: "EmissionsIntensityTimeSeries",
      I: TimeSeriesRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.Unary,
    },
  }
};


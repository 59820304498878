import { DateTime, Duration, Interval } from 'luxon'
import { CILANTRO, COPPER, PURPLE1 } from '@/constants/colors'
import { ControlMetric, PlanScheduleMetric } from '@/constants/metrics'
import type { Services } from '@/services'
import type { TimeSeriesFetch } from '@/services/charts.service'
import { Timestamp } from '@/services/timestamp_pb'
import { ChartType } from '@/types/charts'
import { Format } from '@/utils/format'
import { titleCase } from '@/utils/formatText'
import {
  restrictToFloatInput,
  restrictToPositiveIntegersInput,
} from '@/utils/input'
import { createDateTimeFromISOTime, mergeIntervals } from '@/utils/time'
import { chartFormattersByChartType } from '@/model/charts/formatters'
import type { TimeSeriesConfig } from '@/model/charts/TimeSeriesDataSource'
import { Metric, type DeviceScheduleCommand } from 'rfs/device/proto/proxy_pb'
import { Waypoint } from 'rfs/control/proto/waypoints_pb'
import type { ScheduleWaypoint } from 'rfs/control/proto/control_service_pb'
import { DeviceType } from 'rfs/control/proto/model_pb'

export { Metric } from 'rfs/device/proto/proxy_pb'

export function getWaypointLabel(opts: {
  metric?: Metric
  deviceType?: DeviceType
  plural?: boolean
}): string {
  if (
    // Chargers.
    opts.metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS ||
    // Nuvve EVSE (LPEA).
    (opts.metric === Metric.METRIC_ACTIVE_POWER_WATTS &&
      opts.deviceType === DeviceType.NUVVE_EVSE)
  ) {
    return opts?.plural ? 'events' : 'event'
  } else {
    return opts?.plural ? 'waypoints' : 'waypoint'
  }
}

export const confirmationTitle = 'Are you sure?'

export function getSubmitScheduleConfirmationMessage(opts: {
  metric: Metric
  deviceType: DeviceType
  hasWaypoints: boolean
}): string {
  const label = getWaypointLabel({
    metric: opts.metric,
    deviceType: opts.deviceType,
    plural: true,
  })
  return opts.hasWaypoints
    ? `You're about to schedule new ${label}.`
    : `You're about to delete all scheduled ${label}.`
}

export function getScheduleSuccessMessage(opts: {
  metric: Metric
  deviceType: DeviceType
  isRecurring?: boolean
}): string {
  const label = getWaypointLabel({
    metric: opts.metric,
    deviceType: opts.deviceType,
    plural: true,
  })
  const type = opts.isRecurring ? 'Recurring' : 'Temporary'
  return `${type} ${label} successfully scheduled.`
}

export const changeRouteMessage =
  'This schedule has not been submitted. Navigating away from this page will lose any unsaved changes.'

export const METRIC_WAYPOINT_FUTURE = 'rfa.metric.waypoint.future'

export function createWaypointFutureTimeSeriesConfig(opts: {
  metric: Metric
  deviceType: DeviceType
}): TimeSeriesConfig {
  const config: TimeSeriesConfig = {
    metric: METRIC_WAYPOINT_FUTURE,
    config: { seriesName: '', seriesColor: COPPER.hex },
  }

  const label = titleCase(
    getWaypointLabel({
      metric: opts.metric,
      deviceType: opts.deviceType,
      plural: true,
    })
  )
  config.config.seriesName = `${label} (scheduled)`
  config.config.seriesLine = 'thin-dashed'
  config.config.seriesLineEdgePoints = true

  return config
}

/**
 * Removes the Waypoints metric from the time series fetch request object.
 * Since we'll append our own series after receiving the server's
 * response, we don't need to request data for it.
 */
export function removeWaypointMetric(
  tsFetch: TimeSeriesFetch
): TimeSeriesFetch {
  tsFetch.metrics = tsFetch.metrics.filter(
    (metric) => metric.metric !== METRIC_WAYPOINT_FUTURE
  )
  return tsFetch
}

export function createSocHistoricalTimeSeriesConfig(
  seriesName = 'SoC (historical)',
  opt?: { useEnergyFormatter: boolean }
): TimeSeriesConfig {
  return {
    metric: ControlMetric.CONTROL_BATTERY_SOC_ACTUAL,
    config: {
      seriesName,
      seriesColor: PURPLE1.hex,
      ...(opt?.useEnergyFormatter && {
        formatter: chartFormattersByChartType[ChartType.Energy].yaxis,
      }),
    },
  }
}

export function createSocFutureTimeSeriesConfig(opts?: {
  seriesName?: string
  useEnergyFormatter?: boolean
  useSocPercentMetric?: boolean
}): TimeSeriesConfig {
  return {
    metric: opts?.useSocPercentMetric
      ? PlanScheduleMetric.CONTROL_BATTERY_SOC_PERCENT
      : PlanScheduleMetric.CONTROL_BATTERY_SOC,
    config: {
      seriesName: opts?.seriesName ?? 'SoC (scheduled)',
      seriesColor: PURPLE1.hex,
      seriesLine: 'dashed',
      ...(opts?.useEnergyFormatter && {
        formatter: chartFormattersByChartType[ChartType.Energy].yaxis,
      }),
    },
  }
}

export function createDispatchHistoricalTimeSeriesConfig(
  seriesName = 'Dispatch (historical)'
): TimeSeriesConfig {
  return {
    metric: ControlMetric.CONTROL_BATTERY_DISPATCH_ACTUAL,
    config: {
      seriesName,
      seriesColor: CILANTRO.hex,
      seriesFill: 'origin',
    },
  }
}

export function createDispatchFutureTimeSeriesConfig(
  seriesName = 'Dispatch (scheduled)'
): TimeSeriesConfig {
  return {
    metric: PlanScheduleMetric.CONTROL_BATTERY_DISPATCH,
    config: {
      seriesName,
      seriesColor: CILANTRO.hex,
      seriesLine: 'dashed',
      seriesFill: 'diagonal',
    },
  }
}

/**
 * Calculates the maximum DateTime up to which a user is allowed to set waypoints.
 */
export function getMaxAllowedDateTime(now: DateTime): DateTime {
  return now.endOf('day').plus({ days: 7 })
}

export const CUT_OFF = Duration.fromObject({ minutes: 2 }) // Now + CUT_OFF.

export function formatSoc(targetValue?: number): string {
  if (targetValue == null || Number.isNaN(targetValue)) return ''
  // 0.55 * 100 === 55.00000000000001
  return Math.trunc(targetValue * 100).toString()
}

export function formatPower(value?: number): string {
  return Format.fmtWatts(value).slice(0, -3)
}

export function formatEnergy(value?: number): string {
  return Format.fmtEnergy(value).slice(0, -4)
}

/**
 * IMPORTANT: The `now` parameter is crucial as a reference point. This is because
 * the function may be executed in a loop that spans the transition from one
 * day to the next. Thus, `now` serves as a fixed anchor point to maintain
 * the accuracy of interval creation across day boundaries.
 */
function createIntervalFromRecurringWaypoint(
  w: ScheduleWaypoint,
  now: DateTime
): null | Interval {
  const startDt = createDateTimeFromISOTime(w.startTime, now)
  const endDt = createDateTimeFromISOTime(w.endTime, now)

  // Not valid.
  if (!startDt?.isValid || !endDt?.isValid) return null

  // End bigger than start.
  if (endDt < startDt) {
    console.error(
      'createIntervalFromRecurringWaypoint: end is earlier (smaller) than the start time'
    )
    return null
  }

  return Interval.fromDateTimes(startDt, endDt)
}

/**
 * Checks a list of recurring waypoints in search for overlapping ones.
 */
export function validateRecurringOverlapping(
  waypoints: ScheduleWaypoint[],
  now = DateTime.now()
): string[] {
  const errorMsgs: string[] = []

  const overlapPairs: [number, number][] = []

  waypoints.forEach((w1, index) => {
    waypoints.forEach((w2, index2) => {
      // It's the same waypoint, skip.
      if (index === index2) return

      // It was already detected, skip.
      if (
        overlapPairs.some(
          (pair) => pair.includes(index) && pair.includes(index2)
        )
      ) {
        return
      }
      const wInterval01 = createIntervalFromRecurringWaypoint(w1, now)
      const wInterval02 = createIntervalFromRecurringWaypoint(w2, now)

      if (wInterval01 && wInterval02 && wInterval01.intersection(wInterval02)) {
        overlapPairs.push([index, index2])
        errorMsgs.push(`"${index}" overlaps with "${index2}".`)
      }
    })
  })

  return errorMsgs
}

/**
 * Given a Luxon.Interval, generates Waypoints for all days within the interval
 * based on an array of `ScheduleWaypoint` (recurring waypoints).
 */
export function convertScheduleWaypointsToWaypoints(
  scheduleWaypoints: ScheduleWaypoint[],
  interval: Interval,
  targetMetric: Metric
): Waypoint[] {
  const days = Array.from({ length: interval.count('days') }, (_, i) =>
    interval.start.plus({ days: i }).startOf('day')
  )

  const result: Waypoint[] = []

  for (const sw of scheduleWaypoints) {
    for (const day of days) {
      const startDt = createDateTimeFromISOTime(sw.startTime, day)
      const endDt = createDateTimeFromISOTime(sw.endTime, day)

      if (!startDt || !endDt) continue
      if (endDt < interval.start) continue

      result.push(
        new Waypoint({
          targetMetric,
          startTime: Timestamp.fromDateTime(startDt),
          endTime: Timestamp.fromDateTime(endDt),
          targetValue: sw.value,
        })
      )
    }
  }

  return sortByStartTime(result)
}

export function sortByStartTime<T extends Waypoint | DeviceScheduleCommand>(
  list: T[]
): T[] {
  // don't mutate the original array.
  return [...list].sort((a, b) => {
    const aStartTime = a.startTime
    const bStartTime = b.startTime
    return aStartTime === undefined || bStartTime === undefined
      ? -1
      : aStartTime.toMillis() - bStartTime.toMillis()
  })
}

export function sortRecurringByStartTime(
  list: ScheduleWaypoint[]
): ScheduleWaypoint[] {
  const now = DateTime.now()

  // don't mutate the original array.
  return [...list].sort((a, b) => {
    const aStartTime = createDateTimeFromISOTime(a.startTime, now)
    const bStartTime = createDateTimeFromISOTime(b.startTime, now)
    return !aStartTime || !bStartTime
      ? -1
      : aStartTime.toMillis() - bStartTime.toMillis()
  })
}

export function getStartAndEndTimes<T extends Waypoint | DeviceScheduleCommand>(
  item: T
): { start?: DateTime; end?: DateTime } {
  return {
    start: item.startTime
      ? DateTime.fromMillis(item.startTime.toMillis())
      : undefined,
    end: item.endTime
      ? DateTime.fromMillis(item.endTime.toMillis())
      : undefined,
  }
}

/**
 * A waypoint may still being executed and need to be filtered out.
 * Re-computes the given interval based on still running waypoint.
 */
export function computeAllowedIntervalAndFilter<
  T extends Waypoint | DeviceScheduleCommand
>(
  givenInterval: Interval,
  items: T[]
): {
  allowedInterval: Interval
  filtered: T[]
} {
  const filtered: T[] = []

  let discardedInterval: null | Interval = null

  for (const w of sortByStartTime(items)) {
    const { start, end } = getStartAndEndTimes(w)

    if (!start || !end) continue

    const wInterval = Interval.fromDateTimes(start, end)

    // The waypoint fits within the given interval, it's safe.
    if (givenInterval.engulfs(wInterval)) {
      filtered.push(w)
      continue
    }

    // The waypoint is still running.
    discardedInterval =
      discardedInterval === null
        ? wInterval
        : discardedInterval.union(wInterval)
  }

  return {
    allowedInterval:
      discardedInterval && discardedInterval.end > givenInterval.start
        ? // Re-compute the interval.
          Interval.fromDateTimes(discardedInterval.end, givenInterval.end)
        : // Just return the given interval.
          givenInterval,
    filtered,
  }
}

export function createIntervalFrom<T extends Waypoint | DeviceScheduleCommand>(
  w: T
): undefined | Interval {
  const startMillis = w.startTime?.toMillis()
  const startDt =
    startMillis !== undefined ? DateTime.fromMillis(startMillis) : undefined

  const endMillis = w.endTime?.toMillis()
  const endDt =
    endMillis !== undefined ? DateTime.fromMillis(endMillis) : undefined

  return startDt && endDt ? Interval.fromDateTimes(startDt, endDt) : undefined
}

/**
 * Checks a list of temporary waypoints in search for overlapping ones.
 */
export function validateTemporaryOverlapping<
  T extends Waypoint | DeviceScheduleCommand
>(items: T[]): string[] {
  const errorMsgs: string[] = []

  const overlapPairs: [number, number][] = []

  items.forEach((item01, index) => {
    const interval01 = createIntervalFrom(item01)

    if (!interval01) return

    items.forEach((waypoint2, index2) => {
      // It's the same waypoint, skip.
      if (index === index2) return

      // It was already detected, skip.
      if (
        overlapPairs.some(
          (pair) => pair.includes(index) && pair.includes(index2)
        )
      ) {
        return
      }

      const interval02 = createIntervalFrom(waypoint2)

      if (!interval02) return

      if (interval01.overlaps(interval02)) {
        overlapPairs.push([index, index2])
        errorMsgs.push(`"${index}" overlaps with "${index2}".`)
      }
    })
  })

  return errorMsgs
}

export const CHARGER_INPUT_FIELD_TOOLTIP_TEXT =
  'Enter the max charger power that will be applied to each charger in the group. This value is scaled to the whole group in the chart below, but the entry here is for a single charger.'

export function getKeydownInputValidation(metric: Metric) {
  switch (metric) {
    case Metric.METRIC_MAX_CHARGE_POWER_WATTS: // Charger
    case Metric.METRIC_SOC_WATT_HOURS: // Battery (Emulate) - watt-hour
    case Metric.METRIC_ACTIVE_POWER_WATTS: // Nuvve EVSE (LPEA) and Battery (VEC)
      return restrictToFloatInput
    case Metric.METRIC_SOC_PERCENT: // Battery - SoC
      return restrictToPositiveIntegersInput
    default:
      throw new Error('unexpected metric')
  }
}

export function manualFormLabel(metric: Metric): string {
  switch (metric) {
    case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
      return 'Max power'
    case Metric.METRIC_SOC_PERCENT:
    case Metric.METRIC_SOC_WATT_HOURS:
    case Metric.METRIC_ACTIVE_POWER_WATTS:
      return 'Power setpoint'
    default:
      throw new Error('unexpected metric')
  }
}

export function manualFormSetpointLabel(metric: Metric): string {
  switch (metric) {
    case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
    case Metric.METRIC_SOC_WATT_HOURS:
    case Metric.METRIC_SOC_PERCENT:
    case Metric.METRIC_ACTIVE_POWER_WATTS:
      return 'kW'
    default:
      throw new Error('unexpected metric')
  }
}

export function manualFormTooltipText(metric: Metric): null | string {
  return metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS
    ? CHARGER_INPUT_FIELD_TOOLTIP_TEXT
    : 'Enter positive values to charge and negative values to discharge.'
}

export function manualFormSubmitLabel(metric: Metric): string {
  switch (metric) {
    case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
    case Metric.METRIC_SOC_PERCENT:
    case Metric.METRIC_SOC_WATT_HOURS:
    case Metric.METRIC_ACTIVE_POWER_WATTS:
      return 'Set Controls'
    default:
      return 'Submit'
  }
}

function createWaypointKey(w: Waypoint): string {
  const items: string[] = []

  if (w.startTime) items.push(`start:${w.startTime.toJsonString()}`)
  if (w.endTime) items.push(`end:${w.endTime.toJsonString()}`)
  if (w.priority) items.push(`priority:${w.priority.toString()}`)
  if (w.targetMetric) items.push(`targetMetric:${w.targetMetric.toString()}`)

  // It can be zero (falsy), so check if undefined.
  if (w.targetValue !== undefined)
    items.push(`targetValue:${w.targetValue.toString()}`)

  return items.join('|')
}

export function areWaypointsEqual(
  waypoints1: Waypoint[],
  waypoints2: Waypoint[]
): boolean {
  if (waypoints1.length !== waypoints2.length) return false

  return (
    sortByStartTime(waypoints1)
      .map(createWaypointKey)
      .filter((key) => !!key)
      .join(',') ===
    sortByStartTime(waypoints2)
      .map(createWaypointKey)
      .filter((key) => !!key)
      .join(',')
  )
}

/**
 * Checks if a given waypoint exists in the list of waypoints.
 */
function waypointExists(allWaypoints: Waypoint[], waypoint: Waypoint): boolean {
  const wKey = createWaypointKey(waypoint)
  return allWaypoints.some((w) => createWaypointKey(w) === wKey)
}

/**
 * Returns the earliest start time and latest end time among the provided waypoints.
 */
function getBoundary(
  ...waypoints: Waypoint[]
): undefined | { start: DateTime; end: DateTime } {
  const allStartTimes = waypoints
    .map((w) => w.startTime?.toMillis())
    .filter((v) => v !== undefined)

  const allEndTimes = waypoints
    .map((w) => w.endTime?.toMillis())
    .filter((v) => v !== undefined)

  if (allStartTimes.length === 0 || allEndTimes.length === 0) return undefined

  const earliestStart = Math.min(...allStartTimes)
  const latestEnd = Math.max(...allEndTimes)

  return {
    start: DateTime.fromMillis(earliestStart),
    end: DateTime.fromMillis(latestEnd),
  }
}

/**
 * Submits multiple times to "SubmitWaypoints" removing existing and unused
 * waypoints and sending new ones, one request per each new waypoint.
 */
export async function submitTemporaryWaypointsInChunks(
  services: Services,
  groupId: string,
  initialWaypoints: Waypoint[],
  newWaypoints: Waypoint[]
): Promise<void> {
  // Nothing to do, skip.
  if (areWaypointsEqual(initialWaypoints, newWaypoints)) return

  // First step, remove unused waypoints.
  // Second step, submit new wayppints. IMPORTANT: One request per Waypoint.

  // Remove all existing waypoints in a single call since there's nothing
  // new to schedule.
  if (!newWaypoints.length) {
    const { start, end } = getBoundary(...initialWaypoints) ?? {}

    const fullInterval =
      start && end ? Interval.fromDateTimes(start, end) : undefined

    // It was not possible to compute an interval, skip.
    if (!fullInterval) return

    // When there's no new waypoints, erase the full interval.
    await services.control.submitWaypoints({
      groupId,
      startTime: Timestamp.fromDateTime(fullInterval.start),
      endTime: Timestamp.fromDateTime(fullInterval.end),
      waypointsList: { waypoints: [] },
    })

    return
  }

  // Create intervals for waypoints being removed by the user.
  const cleanupIntervals = initialWaypoints.reduce<Interval[]>(
    (acc, initialWaypoint) => {
      // Do not remove waypoints that are being kept by the user.
      if (waypointExists(newWaypoints, initialWaypoint)) return acc

      const wInterval = createIntervalFrom(initialWaypoint)

      if (wInterval) acc.push(wInterval)

      return acc
    },
    []
  )

  // Optimize to reduce the number of requests.
  const mergedIntervals = mergeIntervals(cleanupIntervals)

  // Delete waypoints.
  for (const interval of mergedIntervals) {
    await services.control.submitWaypoints({
      groupId,
      startTime: Timestamp.fromDateTime(interval.start),
      endTime: Timestamp.fromDateTime(interval.end),
      waypointsList: { waypoints: [] }, // An empty array clears existing waypoints in the interval.
    })
  }

  // Filter out existing waypoints to avoid resubmitting them.
  const reallyNewWaypoints = newWaypoints.reduce((acc, newWaypoint) => {
    // Skip waypoints that already exist.
    if (waypointExists(initialWaypoints, newWaypoint)) return acc
    acc.push(newWaypoint)
    return acc
  }, [] as Waypoint[])

  // Finally, submit the new waypoints.
  for (const waypoint of sortByStartTime(reallyNewWaypoints)) {
    await services.control.submitWaypoints({
      groupId,
      startTime: waypoint.startTime,
      endTime: waypoint.endTime,
      waypointsList: { waypoints: [waypoint] },
    })
  }
}
/**
 * Combines and unifies temporary and recurring waypoints into a single sorted
 * list, ensuring temporal alignment and avoiding overlapping conflicts.
 */
export function unifyWaypoints(opts: {
  tempWaypoints: Waypoint[]
  recurrWaypoints: Waypoint[]
}): Waypoint[] {
  const sortedTempWaypoints = sortByStartTime(opts.tempWaypoints)
  const sortedRecurrWaypoints = sortByStartTime(opts.recurrWaypoints)

  // When no temp. waypoint, skip.
  if (!sortedTempWaypoints.length) {
    return sortedRecurrWaypoints
  }

  const latestTempWaypoint = sortedTempWaypoints.at(-1)

  if (!latestTempWaypoint?.startTime || !latestTempWaypoint?.endTime) {
    throw new Error(
      'unifyWaypoints: the latest temp. waypoint has no "startTime" and/or "endTime"'
    )
  }

  const latestStartDt = DateTime.fromMillis(
    latestTempWaypoint.startTime.toMillis()
  )
  const latestEndDt = DateTime.fromMillis(latestTempWaypoint.endTime.toMillis())
  const latestInterval = Interval.fromDateTimes(latestStartDt, latestEndDt)

  const filteredRecurrWaypoints: Waypoint[] = []

  // Filter the recurring waypoints.
  for (const rw of sortedRecurrWaypoints) {
    if (!rw.startTime || !rw.endTime) continue

    const rwStartDt = DateTime.fromMillis(rw.startTime.toMillis())
    const rwEndDt = DateTime.fromMillis(rw.endTime.toMillis())

    // 1. Remove all recurring waypoints that are completely BEFORE the latest
    // temp. waypoint.
    if (rwEndDt <= latestEndDt) continue

    // 2. Recurring waypoints that overlap the latest temp. waypoint should
    // be editted to start right after the latest temp. waypoint.
    if (Interval.fromDateTimes(rwStartDt, rwEndDt).overlaps(latestInterval)) {
      filteredRecurrWaypoints.push(
        new Waypoint({ ...rw, startTime: latestTempWaypoint.endTime })
      )
    } else {
      // 3. The recurring waypoint is safe, use it.
      filteredRecurrWaypoints.push(rw)
    }
  }

  return sortByStartTime([...sortedTempWaypoints, ...filteredRecurrWaypoints])
}

// gRPC services decribing subsets of an electrical system

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/grid.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DownlineRequest, DownlineResponse, Resources, ResourceStats, Scope, SummaryStats, SummaryStatsAll, TableRequest, TableResponse } from "./grid_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.Grid
 */
export const Grid = {
  typeName: "ritta.frontend.proto.Grid",
  methods: {
    /**
     * Compute aggregate stats for the given scope.
     *
     * @generated from rpc ritta.frontend.proto.Grid.Summarize
     */
    summarize: {
      name: "Summarize",
      I: Scope,
      O: SummaryStats,
      kind: MethodKind.Unary,
    },
    /**
     * Return aggregate stats for the given scope (e.g. "substation" or "feeder")
     *
     * @generated from rpc ritta.frontend.proto.Grid.SummarizeAll
     */
    summarizeAll: {
      name: "SummarizeAll",
      I: Scope,
      O: SummaryStatsAll,
      kind: MethodKind.Unary,
    },
    /**
     * Return statistics about resource types
     *
     * @generated from rpc ritta.frontend.proto.Grid.SummarizeResources
     */
    summarizeResources: {
      name: "SummarizeResources",
      I: Resources,
      O: ResourceStats,
      kind: MethodKind.Unary,
    },
    /**
     * Return resource data for tabular views
     *
     * @generated from rpc ritta.frontend.proto.Grid.ResourcesTable
     */
    resourcesTable: {
      name: "ResourcesTable",
      I: TableRequest,
      O: TableResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Downline resources, based on a time range
     *
     * @generated from rpc ritta.frontend.proto.Grid.DownlineResourcesTable
     */
    downlineResourcesTable: {
      name: "DownlineResourcesTable",
      I: DownlineRequest,
      O: DownlineResponse,
      kind: MethodKind.Unary,
    },
  }
};


import { TextureSource } from '@deck.gl/core'
import { IconMapping } from '@deck.gl/layers/dist/icon-layer/icon-manager'
import { load } from '@loaders.gl/core'
import { ImageLoader, ImageLoaderOptions } from '@loaders.gl/images'

import { ResourceType } from '@/constants/resourceType'

import iconBatteryDistributed from '@/assets/imgs/albert_ic_battery_distributed--black.svg'
import iconBatteryUtility from '@/assets/imgs/albert_ic_battery_utility--black.svg'
import iconBreaker from '@/assets/imgs/albert_ic_breaker--black.svg'
import iconCapacitor from '@/assets/imgs/albert_ic_capacitor--black.svg'
import iconEvCharger from '@/assets/imgs/albert_ic_evse--black.svg'
import iconFuse from '@/assets/imgs/albert_ic_fuse--black.svg'
import iconHydro from '@/assets/imgs/albert_ic_hydro--black.svg'
import iconMethane from '@/assets/imgs/albert_ic_biogas--black.svg'
import iconRecloser from '@/assets/imgs/albert_ic_recloser--black.svg'
import iconRegulator from '@/assets/imgs/albert_ic_regulator--black.svg'
import iconSectionalizer from '@/assets/imgs/albert_ic_sectionalizer--black.svg'
import iconSensor from '@/assets/imgs/albert_ic_sensor--black.svg'
import iconSolarDistributed from '@/assets/imgs/albert_ic_solar_distributed--black.svg'
import iconSolarUtility from '@/assets/imgs/albert_ic_solar_utility--black.svg'
import iconSubstation from '@/assets/imgs/albert_ic_substation--black.svg'
import iconSwitch from '@/assets/imgs/albert_ic_switch--black.svg'
import iconTransformer from '@/assets/imgs/albert_ic_transformer--black.svg'
import iconVehicle from '@/assets/imgs/icon-trucks__black-bg.svg'
import iconWind from '@/assets/imgs/albert_ic_wind--black.svg'

// The image size of 96x96 can be scaled down to 32x32 or 24x24s
const IMAGE_SIZE = 96
export const LOADER_OPTIONS: ImageLoaderOptions = {
  imagebitmap: {
    // https://github.com/visgl/deck.gl/issues/2169#issuecomment-704585080
    premultiplyAlpha: 'none',
    resizeWidth: IMAGE_SIZE,
    resizeHeight: IMAGE_SIZE,
    resizeQuality: 'high',
  },
}

const iconAtlas = document.createElement('canvas')
const iconMapping: IconMapping = {}

/** Return the images and a mapping object for use with IconLayer */
export function getTintIconAtlas(): [TextureSource, IconMapping] {
  return [iconAtlas, iconMapping]
}

export const resourceIcons = [
  [ResourceType.BATTERY_DISTRIBUTED, iconBatteryDistributed],
  [ResourceType.BATTERY_STATION, iconBatteryUtility],
  [ResourceType.BREAKER, iconBreaker],
  [ResourceType.CAPACITOR, iconCapacitor],
  [ResourceType.CHARGER, iconEvCharger],
  [ResourceType.FUSE, iconFuse],
  [ResourceType.HYDRO, iconHydro],
  [ResourceType.METHANE, iconMethane],
  [ResourceType.RECLOSER, iconRecloser],
  [ResourceType.REGULATOR, iconRegulator],
  [ResourceType.SECTIONALIZER, iconSectionalizer],
  [ResourceType.SENSOR_ELECTRICAL, iconSensor],
  [ResourceType.SOLAR_DISTRIBUTED, iconSolarDistributed],
  [ResourceType.SOLAR_FARM, iconSolarUtility],
  [ResourceType.SUBSTATION, iconSubstation],
  [ResourceType.SWITCH, iconSwitch],
  [ResourceType.TRANSFORMER, iconTransformer],
  [ResourceType.VEHICLE, iconVehicle],
  [ResourceType.WIND, iconWind],
]

/**
 * Load all resource icons into the icon atlas texture.
 * This should be called once when the map is initialized.
 */
export async function generateTintIconAtlas(): Promise<TextureSource> {
  // If the iconAtlas has already been generated, return it
  if (Object.keys(iconMapping).length > 0) {
    return iconAtlas
  }

  // Convert the SVG imports into Image objects
  const allImageData = await Promise.all(
    resourceIcons.map(([_, icon]) => load(icon, ImageLoader, LOADER_OPTIONS))
  )

  // Size the canvas for all images
  iconAtlas.height = IMAGE_SIZE
  iconAtlas.width = IMAGE_SIZE * allImageData.length

  // Render into a canvas
  const context = iconAtlas.getContext('2d')
  if (context == null) throw new Error('Cannot access canvas 2D context')

  for (let i = 0; i < allImageData.length; i++) {
    const [resourceType] = resourceIcons[i]
    // Draw the image into the canvas at the correct position
    context.drawImage(allImageData[i] as CanvasImageSource, IMAGE_SIZE * i, 0)
    // Add an entry to the mapping object.
    iconMapping[resourceType] = makeMapping(i, true)
  }

  // For debugging...
  // document.body.appendChild(iconAtlas)

  return iconAtlas
}

function makeMapping(index: number, mask: boolean) {
  return {
    x: IMAGE_SIZE * index,
    y: 0,
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    mask,
  }
}

/**
 * The list of known metrics, taken from the canonicalization module.
 * Some of the metrics are not in Bigtable but are computed by RFS.
 * @see https://github.com/CamusEnergy/bando/blob/master/canonicalization/constants.go
 */
export enum Metric {
  ENERGY_CONSUMED = 'energy.consumed',
  ENERGY_PRODUCED = 'energy.produced',
  ENERGY_REACTIVE_CONSUMED = 'energy.reactive.consumed',
  ENERGY_REACTIVE_PRODUCED = 'energy.reactive.produced',

  COND_VOLTAGE = 'conditions.voltage',
  COND_CURRENT = 'conditions.current.magnitude',
  COND_NEUTRAL_CURRENT = 'conditions.neutral.current',
  COND_VOLTAGE_A = 'conditions.A.voltage',
  COND_VOLTAGE_B = 'conditions.B.voltage',
  COND_VOLTAGE_C = 'conditions.C.voltage',
  COND_CURRENT_A = 'conditions.A.current.magnitude',
  COND_CURRENT_B = 'conditions.B.current.magnitude',
  COND_CURRENT_C = 'conditions.C.current.magnitude',
  COND_TEMP_DEVICE = 'conditions.temperature.device',
  COND_POWER_REAL = 'conditions.power.real',
  COND_POWER_APPARENT = 'conditions.power.apparent',
  COND_POWER_REACTIVE = 'conditions.power.reactive',
  COND_CHARGE_LEVEL = 'conditions.charge.level',
  COND_CHARGE_STATE = 'conditions.charge.state',
  COND_CONTACTS_CLOSED = 'conditions.contacts.closed',

  COND_POWER_FACTOR_A = 'conditions.A.power.factor',
  COND_POWER_FACTOR_B = 'conditions.B.power.factor',
  COND_POWER_FACTOR_C = 'conditions.C.power.factor',
  COND_POWER_REAL_A = 'conditions.A.power.real',
  COND_POWER_REAL_B = 'conditions.B.power.real',
  COND_POWER_REAL_C = 'conditions.C.power.real',
  COND_POWER_APPARENT_A = 'conditions.A.power.apparent',
  COND_POWER_APPARENT_B = 'conditions.B.power.apparent',
  COND_POWER_APPARENT_C = 'conditions.C.power.apparent',
  COND_POWER_REACTIVE_A = 'conditions.A.power.reactive',
  COND_POWER_REACTIVE_B = 'conditions.B.power.reactive',
  COND_POWER_REACTIVE_C = 'conditions.C.power.reactive',

  COND_DOWNLINE_GEN_POWER_REAL = 'conditions.downline.generation.power.real',

  FORECAST_ACTUAL_COND_POWER_REAL = 'forecast.actual.conditions.power.real',
  FORECAST_LATEST_COND_POWER_REAL = 'forecast.latest.conditions.power.real',
  FORECAST_ACTUAL_COND_POWER_APPARENT = 'forecast.actual.conditions.power.apparent',
  FORECAST_LATEST_COND_POWER_APPARENT = 'forecast.latest.conditions.power.apparent',

  FORECAST_ENERGY_CONSUMED = 'forecast.latest.energy.consumed',
  FORECAST_ENERGY_PRODUCED = 'forecast.latest.energy.produced',

  /** Feeder Operations Metrics */
  // For all resources but conductors
  // Upline voltage
  FORECAST_UPLINE_COND_VOLTAGE_A = 'forecast.latest.conditions.A.voltages.upline.pu',
  FORECAST_UPLINE_COND_VOLTAGE_B = 'forecast.latest.conditions.B.voltages.upline.pu',
  FORECAST_UPLINE_COND_VOLTAGE_C = 'forecast.latest.conditions.C.voltages.upline.pu',

  // Downline voltage
  FORECAST_DOWNLINE_COND_VOLTAGE_A = 'forecast.latest.conditions.A.voltages.downline.pu',
  FORECAST_DOWNLINE_COND_VOLTAGE_B = 'forecast.latest.conditions.B.voltages.downline.pu',
  FORECAST_DOWNLINE_COND_VOLTAGE_C = 'forecast.latest.conditions.C.voltages.downline.pu',

  // Voltage - capacitor
  FORECAST_COND_VOLTAGE_A = 'forecast.latest.conditions.A.voltage.pu',
  FORECAST_COND_VOLTAGE_B = 'forecast.latest.conditions.B.voltage.pu',
  FORECAST_COND_VOLTAGE_C = 'forecast.latest.conditions.C.voltage.pu',

  // Current - For conductors
  FORECAST_COND_CURRENT = 'forecast.latest.conditions.current.magnitude',
  FORECAST_COND_CURRENT_A = 'forecast.latest.conditions.A.current.magnitude',
  FORECAST_COND_CURRENT_B = 'forecast.latest.conditions.B.current.magnitude',
  FORECAST_COND_CURRENT_C = 'forecast.latest.conditions.C.current.magnitude',

  /** Power Intention Metrics used by Capacity Service */
  INTENTIONS_POWER_REAL = 'intentions.power.real',
  INTENTIONS_POWER_LIMITS_CONSUMING = 'intentions.power.limits.consuming',

  /** Carbon Emissions Metrics used by Capacity Service */
  COND_POWER_EMISSIONS = 'conditions.power.emissions',
  EMISSIONS_INTENSITY_CONSUMED_CO2E = 'emissions.intensity.consumed.co2e',
  FORECAST_LATEST_EMISSIONS_INTENSITY_GENERATED_CO2E = 'forecast.latest.emissions.intensity.generated.co2e',

  /** Computed from `energy.consumed` */
  POWER_CONSUMED = 'power.consumed',
  /** Computed from `energy.consumed - energy.produced` */
  POWER_CONSUMED_NET = 'power.consumed.net',
  /** Computed from `energy.produced` */
  POWER_PRODUCED = 'power.produced',
  /** Computed from `forecast.latest.energy.consumed` */
  FORECAST_POWER_CONSUMED_NET = 'forecast.latest.power.consumed.net',

  /** Computed using the component's rating / power */
  RATINGS_POWER_APPARENT = 'ratings.power.apparent',

  SENSOR_SITE_POWER_REAL = 'sensor.site.power.real',
  SENSOR_SOLAR_POWER_REAL = 'sensor.solar.power.real',

  // TODO(rafael): stop using this metric in favor of 'ratings.power.apparent'.
  TRANSFORMER_LOAD_PERCENT = 'load.percent',

  ENVELOPE_POWER_CONSUMING = 'envelope.power.consuming',
  COMPUTED_ENVELOPE_POWER_CONSUMING = 'computed.envelope.power.consuming',

  // Voltage violations (over and under)
  AGGREGATION_COUNT_VOLTAGE_MAX = 'aggregation.count.violating.voltage.max',
  AGGREGATION_COUNT_VOLTAGE_MIN = 'aggregation.count.violating.voltage.min',

  CONTROLLABLE_COND_POWER_APPARENT = 'controllable.conditions.power.apparent',
}

/**
 * ControlMetric is a enum of metrics used to map
 * the control server data to the canonical-like metrics.
 * They are prefixed with `control.*`.
 */
export enum ControlMetric {
  /**
   * These metrics are used for HistoricalScheduleTimeSeries RPC.
   * The are suffixed with `actual` to indicate that they are recorded historical values.
   */
  CONTROL_BATTERY_DISPATCH_ACTUAL = 'control.battery.dispatch.actual',
  CONTROL_BATTERY_SOC_ACTUAL = 'control.battery.soc.actual',
  CONTROL_BATTERY_SOC_PERCENT_ACTUAL = 'control.battery.soc.percent.actual',
  CONTROL_GENERATION_LOAD_RATIO_ACTUAL = 'control.generation.load.ratio.actual',
  CONTROL_GROSS_LOAD_ACTUAL = 'control.gross.load.actual',
  CONTROL_NET_LOAD_ACTUAL = 'control.net.load.actual',
  CONTROL_PCC_ACTUAL = 'control.pcc.actual',
  CONTROL_PV_ACTUAL = 'control.pv.actual',
}

/**
 * These metrics are used for PlanScheduleTimeSeries RPC.
 */
export enum PlanScheduleMetric {
  CONTROL_BATTERY_DISPATCH = 'control.battery.dispatch',
  CONTROL_BATTERY_SOC = 'control.battery.soc',
  CONTROL_BATTERY_SOC_PERCENT = 'control.battery.soc.percent',
  CONTROL_CURTAILED_PV = 'control.curtailed.pv',
  CONTROL_GENERATION_LOAD_RATIO = 'control.generation.load.ratio',
  CONTROL_GROSS_LOAD = 'control.gross.load',
  CONTROL_MAX_POWER_CONSUMED_WATTS = 'control.max.power.consumed.watts',
  CONTROL_NET_LOAD = 'control.net.load',
  CONTROL_PCC = 'control.pcc',
  CONTROL_PV_FORECAST = 'control.pv.forecast',
}

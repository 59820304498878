import { Layer as DeckglLayer } from '@deck.gl/core'
import { GoogleMapsOverlay } from '@deck.gl/google-maps'

import {
  DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL,
  DEFAULT_GMAPS_TILT,
  GMAPS_BOTTOM_CONTROL_HEIGHT,
} from './constants'
import { ControlPosition, makeSize, setMapStyleVisibility } from './gmaps'
import {
  FocusLocations,
  MapManagerOptions,
  CurrentLayers,
  MapManagerLayer,
  LatLng,
  InfoWindowHandler,
  ResourceClickHandler,
  UpdateLayerProps,
} from './types'
import DEFAULT_STYLES from './maptypestyle.json'

export class MapManager {
  private options: MapManagerOptions
  private gmap: google.maps.Map
  private deckglGmapsOverlay: GoogleMapsOverlay
  private currentLayers: CurrentLayers = new Map()
  private independentLayers: CurrentLayers = new Map()
  private isGmapReady = false
  private isRightClickLocationConfigured = false
  private isStreetViewConfigured = false
  private isStreetViewActive = false
  private infoWindowRightClickInstance: null | google.maps.InfoWindow = null
  private currentRedPins: google.maps.Marker[] = []
  private pendingFocusLocations: null | FocusLocations = null

  private infoWindow = {
    handler: <null | InfoWindowHandler>null,
    instance: <null | google.maps.InfoWindow>null,
  }

  constructor(gmap: google.maps.Map, options: MapManagerOptions) {
    this.options = options
    this.gmap = gmap
    this.setUpGMapInteractivity()

    const deckglGmapsOverlay = new GoogleMapsOverlay({
      onHover: (info) => {
        // Configure the mouse cursors.
        // The `object` property is non-null when hovering over a pickable object.
        const draggableCursor = info.object ? 'pointer' : 'grab'
        this.gmap.setOptions({ draggableCursor })

        // Info window.
        this.infoWindow.handler?.(
          info,
          (el, coordinate) => this.openInfoWindow(el, coordinate),
          () => this.closeInfoWindow()
        )
      },
    })
    deckglGmapsOverlay.setMap(gmap)
    this.deckglGmapsOverlay = deckglGmapsOverlay
  }

  /**
   * Monitors the Gmap instance and triggers pending map interactions.
   *
   * NOTE(rafael): The `tilesloaded` is more accurately to signalize when the
   * map is fully rendered:
   * https://groups.google.com/g/google-maps-js-api-v3/c/F4TJvjQ_qc4/m/2XVeSN89hDIJ
   */
  private setUpGMapInteractivity(): void {
    google.maps.event.addListenerOnce(this.gmap, 'tilesloaded', () => {
      this.isGmapReady = true

      if (this.pendingFocusLocations) {
        this.focusLocations({
          ...this.pendingFocusLocations,
          alwaysCenter: true,
        })
        this.pendingFocusLocations = null
      }
    })
  }

  private getCurrentLayers(): DeckglLayer[] {
    return Array.from(this.currentLayers.entries()).map(
      ([_id, mapManagerLayer]) => mapManagerLayer
    )
  }

  private getIndependentLayers(): DeckglLayer[] {
    return Array.from(this.independentLayers.entries()).map(
      ([_id, mapManagerLayer]) => mapManagerLayer
    )
  }

  private refreshLayers(): void {
    const layers = [...this.getCurrentLayers(), ...this.getIndependentLayers()]
    this.deckglGmapsOverlay.setProps({ layers })
  }

  private openInfoWindow(el: HTMLElement, coordinate: LatLng): void {
    const infoWindow = new google.maps.InfoWindow({
      content: el,
      position: coordinate,
      pixelOffset: makeSize(this.options.infoWindowOffset),
      disableAutoPan: true,
    })

    infoWindow.open({ shouldFocus: false, map: this.gmap })

    this.infoWindow.instance = infoWindow
  }

  private closeInfoWindow(): void {
    if (this.infoWindow.instance) {
      this.infoWindow.instance.close()
      this.infoWindow.instance = null
    }
  }

  /**
   * Set a listener that will be called when a Resource-based feature is clicked.
   * This replaces any previously set listener.
   */
  setResourceClickListener(listener: ResourceClickHandler): void {
    this.deckglGmapsOverlay.setProps({
      onClick(info) {
        // If the pickable object is a Tile Feature, it will have a "resource" property.
        if (info.object?.resource) listener(info.object.resource)
      },
    })
  }

  /** * Configures an info window that shows the lat/lng of the click. */
  setUpRightClickLocation(): void {
    if (this.isRightClickLocationConfigured) return

    google.maps.event.addListener(
      this.gmap,
      'rightclick',
      (event: google.maps.MapMouseEvent) => {
        // Only one instance should be visible over the map.
        if (this.infoWindowRightClickInstance) {
          this.infoWindowRightClickInstance.close()
          this.infoWindowRightClickInstance = null
        }

        const location = event.latLng?.toJSON()

        if (location) {
          const { lat, lng } = location
          this.infoWindowRightClickInstance = new google.maps.InfoWindow({
            content: `
            <div>Lat=${lat}</div>
            <div>Lng=${lng}</div>
          `,
            position: event.latLng,
          })
          this.infoWindowRightClickInstance.open(this.gmap)
        }
      }
    )

    this.isRightClickLocationConfigured = true
  }

  /** * Configures GMaps Street View feature  */
  setUpStreetView(isActiveCb: (newValue: boolean) => void): void {
    if (this.isStreetViewConfigured) return

    const streetView = this.gmap.getStreetView()

    google.maps.event.addListener(streetView, 'visible_changed', () => {
      const newValue = streetView.getVisible()
      this.isStreetViewActive = newValue
      isActiveCb(newValue)
    })

    // https://developers.google.com/maps/documentation/javascript/reference/street-view#StreetViewPanorama.resize
    // Developers should trigger this event on the panorama when the map's <div>
    // changes size.
    //
    // NOTE(rafael): For us, it's related to the left panel component.
    // The left panel gets hidden after Street View is activated
    // (changing the size of the map's <div>). If we don't
    // resize the streetView feature, it'll keep the
    // _old_ size.
    this.gmap.addListener('idle', () => {
      if (this.isStreetViewActive) {
        google.maps.event.trigger(streetView, 'resize')
      }
    })

    this.isStreetViewConfigured = true
  }

  closeStreetView(): void {
    this.gmap.getStreetView().setVisible(false)
  }

  /**
   * Appends HTML-based map controls to the bottom-right area of the map view.
   */
  setMapControls(...controls: HTMLElement[]): void {
    // Put the controls in the bottom right, above the Google Maps copyrights
    controls.reverse().forEach((el, index) => {
      this.gmap.getDiv().appendChild(el)
      // Update the style like GMaps does when adding controls
      el.style.position = 'absolute'
      el.style.bottom = `${GMAPS_BOTTOM_CONTROL_HEIGHT}px`
      el.style.right = `${156 * index + 60}px`
    })
  }

  /**
   * Sets up a handler function that manages a info window instance on the map.
   */
  setUpInfoWindow(handler: InfoWindowHandler): void {
    this.infoWindow.handler = handler
  }

  /** * Resets the map position based on defaults and configured options. */
  resetMapPosition(): void {
    this.gmap.setZoom(DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL)
    if (this.options.default?.center) {
      this.gmap.panTo(this.options.default.center)
    }
    this.gmap.setTilt(DEFAULT_GMAPS_TILT)
    this.gmap.setHeading(0)
  }

  addCustomControl(el: HTMLElement, position: ControlPosition): void {
    this.gmap.controls[position].push(el)
  }

  removeCustomControl(element: HTMLElement, position: ControlPosition): void {
    const index = this.gmap.controls[position]
      .getArray()
      .findIndex((e) => e === element)

    if (index !== -1) {
      this.gmap.controls[position].removeAt(index)
    } else {
      console.debug('MapManager.removeCustomControl: custom control not found')
    }
  }

  /**
   * Set the visibility of various markers for busineses, landmarks, and roads.
   */
  setLandmarkVisibility(visible: boolean): void {
    this.gmap.setOptions({
      styles: DEFAULT_STYLES.map((s) => setMapStyleVisibility(s, visible)),
    })
  }

  focusLocations(focus: FocusLocations) {
    if (!this.isGmapReady) {
      this.pendingFocusLocations = focus
      return
    }

    // Reset current focus.
    this.clearFocus()

    const { alwaysCenter, locations, showRedPins, minZoom, zoom } = focus

    if (!locations.length) return

    // Multiple locations.
    if (locations.length > 1) {
      this.gmap.fitBounds(
        locations.reduce((bounds, latLng) => {
          bounds.extend(latLng)
          return bounds
        }, new google.maps.LatLngBounds())
      )
    } else {
      // Min zoom.
      const currentZoom = this.gmap.getZoom() ?? 0
      if (minZoom !== undefined && currentZoom < minZoom) {
        this.gmap.setZoom(minZoom)
      }

      // Zoom.
      if (zoom !== undefined) {
        this.gmap.setZoom(zoom)
      }

      // Pan.
      const loc = locations[0]
      if (alwaysCenter || !this.isPointInSafeView(loc)) {
        this.gmap.panTo(loc)
      }
    }

    // Red pins.
    if (showRedPins) {
      for (const loc of locations) {
        this.currentRedPins.push(
          new google.maps.Marker({ position: loc, map: this.gmap })
        )
      }
    }
  }

  clearFocus(): void {
    // Remove red pins.
    this.currentRedPins.forEach((rp) => rp.setMap(null))
    this.currentRedPins = []
  }

  /** * Adds layers to the map being managed. */
  addLayers(
    layerList: MapManagerLayer[],
    opts?: { independentLayers?: boolean }
  ): void {
    if (opts?.independentLayers) {
      for (const l of layerList) this.independentLayers.set(l.id, l)
    } else {
      for (const l of layerList) this.currentLayers.set(l.id, l)
    }
    this.refreshLayers()
  }

  /**
   * Remove one or more layers from the map.
   * This method _should not_ be used to temporarily hide layers because removing a layer
   * destroys all the state for that layer, including fetched data.
   */
  removeLayers(layerIds: string[]): void {
    const needsUpdate = layerIds.reduce(
      (result, layerId) => this.currentLayers.delete(layerId) || result,
      false
    )
    if (needsUpdate) this.refreshLayers()
  }

  /** * Makes the layers in the set visible; all others are hidden. */
  updateLayersVisibility(visibilityList: Set<string>): void {
    for (const [id, layer] of this.currentLayers) {
      const visible = visibilityList.has(id)
      this.currentLayers.set(id, layer.clone({ visible }))
    }

    this.refreshLayers()
  }

  /**
   * Updates Map Layer given a `MapLayerId` and the `updateTrigger` prop with an updated `value
   */
  private updateLayer(updateLayerProps: UpdateLayerProps) {
    const { layerId, newProps } = updateLayerProps

    const mapManagerLayer = this.currentLayers?.get(layerId)
    if (mapManagerLayer) {
      this.currentLayers.set(layerId, mapManagerLayer.clone({ ...newProps }))
    }

    // Independent layers.
    const independentLayer = this.independentLayers?.get(layerId)
    if (independentLayer) {
      this.independentLayers.set(
        layerId,
        independentLayer.clone({ ...newProps })
      )
    }
  }

  /**
   * Updates multiple Map Layers at a time
   */
  updateLayers(layersToUpdate: UpdateLayerProps[]) {
    for (const updateLayerProps of layersToUpdate) {
      this.updateLayer(updateLayerProps)
    }
    this.refreshLayers()
  }

  setCenterAndZoom(center: LatLng, zoom?: number) {
    this.gmap.panTo(center)

    if (zoom !== undefined) {
      this.gmap.setZoom(zoom)
    }
  }

  /**
   * Checks if a given point is within a reduced viewable area of the current
   * map bounds to account for map controls in the corners of the map.
   */
  private isPointInSafeView(point: LatLng): boolean {
    const bounds = this.gmap.getBounds()

    if (!bounds) return false

    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()

    const marginFactor = 0.1 // 10%
    const latDiff = (ne.lat() - sw.lat()) * marginFactor
    const lngDiff = (ne.lng() - sw.lng()) * marginFactor

    const newNE = new google.maps.LatLng(ne.lat() - latDiff, ne.lng() - lngDiff)
    const newSW = new google.maps.LatLng(sw.lat() + latDiff, sw.lng() + lngDiff)
    const reducedBounds = new google.maps.LatLngBounds(newSW, newNE)

    return reducedBounds.contains(point)
  }
}

<template>
  <control-template
    :isLoading
    :isRefreshing
    :breadcrumbs
    :navBarItems
    :currentPolicy
    :currentPolicyParams
    :currentOEStatus
    :communicationStatus
    :lastUpdate
  >
    <!-- Telemetry -->
    <device-telemetry
      v-if="showTelemetryContent && group && resource"
      :group
      :resource
      :isOperatingEnvelopeEnabled
      :intervalBroadcaster
      style="flex: 1"
    />
  </control-template>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { DateTime } from 'luxon'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import * as RouteNames from '@/router/routeNames'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import { getDisplayName } from '@/model/resource'
import {
  fetchCurrentCommunicationStatus,
  isCommunicationStatusEnabled,
  type CommunicationStatusType,
} from '@/model/control/communicationStatus'
import {
  getDeviceCurrentEnvelopeStatus,
  isOEEnabled,
  OperatingEnvelopeStatus,
} from '@/model/control/operatingEnvelope'
import type { NavBarItem } from '@/components/common/NavBar.vue'
import DeviceTelemetry from '@/components/control/device/DeviceTelemetry.vue'
import ControlTemplate from '@/components/control/ControlTemplate.vue'
import { BreadcrumbItem } from '@/components/ResourceHeader'
import type { Group } from 'rfs/control/proto/model_pb'
import { type Params, Policy } from 'rfs/control/proto/policy_pb'
import type { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'DeviceResource',
  props: {
    groupId: {
      type: String,
      required: true,
    },
    resourceId: {
      type: String,
      required: true,
    },
  },
  components: { ControlTemplate, DeviceTelemetry },
  setup() {
    return { intervalBroadcaster: new IntervalBroadcaster() }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      isRefreshing: false,
      group: null as null | Group,
      resource: null as null | Resource,
      communicationStatus: undefined as undefined | CommunicationStatusType,
      lastUpdate: undefined as undefined | DateTime,
    })
  },
  computed: {
    currentPolicy(): Policy {
      return this.group?.currentPolicy ?? Policy.UNSPECIFIED
    },
    currentPolicyParams(): undefined | Params {
      return this.group?.currentPolicyParams
    },
    isOperatingEnvelopeEnabled(): boolean {
      return isOEEnabled(this.$rittaConfig) && !!this.group?.supportsOes
    },
    currentOEStatus(): undefined | OperatingEnvelopeStatus {
      return this.isOperatingEnvelopeEnabled
        ? // TODO(rafael): What should we do for Resources that belong to a
          // "pool/" Device? The resources don't have a "oeEnabled" field,
          // so we'd need to use the "oeEnabled" field from the
          // parent Device?
          getDeviceCurrentEnvelopeStatus(null)
        : undefined
    },
    breadcrumbs(): BreadcrumbItem[] {
      return [
        {
          title: 'Groups',
          to: { name: RouteNames.CONTROL_OVERVIEW },
        },
        {
          title: this.group?.displayName ?? TEXT_NO_VALUE,
          to: {
            name: RouteNames.CONTROL_GROUP_DETAILS,
            params: { groupId: this.groupId },
          },
        },
        {
          title: this.resource ? getDisplayName(this.resource) : TEXT_NO_VALUE,
        },
      ]
    },
    navBarItemTelemetry(): NavBarItem {
      const label = 'Telemetry'
      return {
        label,
        id: label,
        to: {
          name: RouteNames.CONTROL_DEVICE_RESOURCE_TELEMETRY,
          params: { groupId: this.groupId, resourceId: this.resourceId },
        },
      }
    },
    navBarItemPerformance(): NavBarItem {
      const label = 'Performance'
      return {
        label,
        id: label,
        to: { name: RouteNames.OVERVIEW },
        disabled: true,
      }
    },
    navBarItemHistory(): NavBarItem {
      const label = 'History'
      return {
        label,
        id: label,
        to: { name: RouteNames.OVERVIEW },
        disabled: true,
      }
    },
    navBarItems(): NavBarItem[] {
      return [
        this.navBarItemTelemetry,
        this.navBarItemPerformance,
        this.navBarItemHistory,
      ]
    },
    showTelemetryContent(): boolean {
      return this.$route.name === this.navBarItemTelemetry.to.name
    },
    showStateOfCharge(): boolean {
      return (
        this.resource?.type === ResourceType.BATTERY_DISTRIBUTED ||
        this.resource?.type === ResourceType.BATTERY_STATION
      )
    },
    /* this computed property exists only to be watched */
    computedProps(): [string, string] {
      return [this.groupId, this.resourceId]
    },
  },
  watch: {
    computedProps: {
      immediate: true,
      handler: function (): void {
        this.fetchData()
      },
    },
    resource() {
      // When new resource.
      this.setNewPageTitle()
    },
    $route() {
      // When tab changes.
      this.setNewPageTitle()
    },
  },
  created() {
    this.intervalBroadcaster.subscribe(this.refreshData)
    this.intervalBroadcaster.start()
  },
  beforeUnmount(): void {
    this.intervalBroadcaster.unsubscribe(this.refreshData)
    this.intervalBroadcaster.stop()
  },
  methods: {
    setNewPageTitle(): void {
      if (!this.resource) return

      const resourceDisplayName = getDisplayName(this.resource)

      if (!resourceDisplayName) return

      const tabLabel = ((): undefined | string => {
        if (this.showTelemetryContent) {
          return this.navBarItemTelemetry.label
        } else {
          return undefined
        }
      })()

      const baseTitle = `Device: ${resourceDisplayName}`

      useNavigationControlsStore().setPageTitle(
        tabLabel ? `${baseTitle} - ${tabLabel}` : baseTitle
      )
    },
    async refreshData(): Promise<void> {
      this.fetchData({ refresh: true })
    },
    async fetchData({ refresh } = { refresh: false }): Promise<void> {
      if (refresh) {
        this.isRefreshing = true
      } else {
        this.isLoading = true
        this.group = null
        this.resource = null
      }

      // Fetch group and resource.
      try {
        const [group, resource] = await Promise.all([
          this.$services.control.getGroup({
            id: this.groupId,
          }),
          this.$services.queryService.getResource(this.resourceId),
        ])

        this.group = group
        this.resource = resource
      } catch (err) {
        console.error('DeviceResource.fetchData: %o', err)

        if (!refresh) {
          this.$router.replace({
            name: RouteNames.CONTROL_GROUP_DETAILS,
            params: { groupId: this.groupId },
          })
          return
        }
      }

      // Communication status.
      try {
        if (
          this.group &&
          this.resource &&
          isCommunicationStatusEnabled(this.$rittaConfig, this.group)
        ) {
          const now = this.$observationTime()
          this.communicationStatus = await fetchCurrentCommunicationStatus(
            this.$services,
            this.resource.id,
            now
          )
          this.lastUpdate = now
        }
      } catch (err) {
        console.error('DeviceResource.fetchData: %o', err)
      } finally {
        this.isLoading = false
        this.isRefreshing = false
      }
    },
  },
})
</script>

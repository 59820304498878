<template>
  <time-series-chart-group
    :charts="chartDefinitions"
    :data-source="tsDataSource"
    :interval="selectedInterval"
    :max-end-time="chartMaxEnd"
    @new-interval="handleNewInterval"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime, Interval } from 'luxon'

import { siteChartDefinitions } from '@/constants/siteCharts'
import { TimeSeriesDataSource } from '@/model/charts/index'
import { addForecastInterval } from '@/model/forecast/index'
import { isStorageResource } from '@/model/resource/index'
import { Resource } from 'rfs/pb/resource_pb'
import { ChartDefinition } from '@/types/charts'
import { intervalLast7Days } from '@/utils/time'
import { TimeSeriesChartGroup } from '../common'
import { newSiteChartDataSource } from './SiteChartData'

export default defineComponent({
  name: 'SiteCharts',
  props: {
    siteResource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    otherResources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
  },
  components: {
    TimeSeriesChartGroup,
  },
  setup() {
    return {
      tsDataSource: TimeSeriesDataSource.emptyDataSource(),
    }
  },
  data() {
    const baseInterval = intervalLast7Days(this.$observationTime())
    return {
      selectedInterval: addForecastInterval(baseInterval, this.$rittaConfig),
    }
  },
  computed: {
    chartDefinitions(): ChartDefinition[] {
      return siteChartDefinitions(this.$rittaConfig.monitor).filter((chart) => {
        // Only include storage if there's a battery
        if (chart.id === 'site-storage-chart') {
          return this.otherResources.some(isStorageResource)
        }
        return true
      })
    },
    chartMaxEnd(): DateTime {
      const baseInterval = intervalLast7Days(this.$observationTime())
      // Use the end of the initial interval as the max end time for the chart
      return addForecastInterval(baseInterval, this.$rittaConfig).end
    },
  },
  created() {
    this.initDataSource()
  },
  methods: {
    initDataSource(): void {
      this.tsDataSource = newSiteChartDataSource(
        this.chartDefinitions,
        this.siteResource,
        this.otherResources,
        this.$services,
        this.$rittaConfig
      )
    },
    handleNewInterval(interval: Interval, visible?: Interval): void {
      this.selectedInterval = interval
    },
  },
})
</script>

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file device/proto/proxy.proto (package ritta.device.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AllDevices, Command, CommandStatusResponse, DeviceSchedule, DeviceScheduleResponse, DeviceScheduleTimeRange, GetDeviceRequest, GetResourcesRequest, GetResourcesResponse, GetStreamingResourceMeasurementsRequest, TelemetryResponse } from "./proxy_pb.js";
import { Device } from "../../control/proto/model_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";
import { ResourceMeasurements } from "./pubsub_pb.js";

/**
 * @generated from service ritta.device.proto.Proxy
 */
export const Proxy = {
  typeName: "ritta.device.proto.Proxy",
  methods: {
    /**
     * Static info
     * NOTE: we are using the "device" definition in model.proto for now.
     *
     * @generated from rpc ritta.device.proto.Proxy.GetStaticInfo
     */
    getStaticInfo: {
      name: "GetStaticInfo",
      I: GetDeviceRequest,
      O: Device,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ritta.device.proto.Proxy.GetAllStaticInfo
     */
    getAllStaticInfo: {
      name: "GetAllStaticInfo",
      I: Empty,
      O: AllDevices,
      kind: MethodKind.Unary,
    },
    /**
     * Telemetry
     *
     * @generated from rpc ritta.device.proto.Proxy.GetTelemetry
     */
    getTelemetry: {
      name: "GetTelemetry",
      I: GetDeviceRequest,
      O: TelemetryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * TODO: GetStatus and GetAllStatuses?
     *
     * @generated from rpc ritta.device.proto.Proxy.GetAllTelemetry
     */
    getAllTelemetry: {
      name: "GetAllTelemetry",
      I: Empty,
      O: TelemetryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * I think this only makes sense as an get ALL data and making a
     * streaming RPC for a single device doesnt make sense so that would
     * still use the unary one.
     *
     * @generated from rpc ritta.device.proto.Proxy.GetStreamingTelemetry
     */
    getStreamingTelemetry: {
      name: "GetStreamingTelemetry",
      I: Empty,
      O: TelemetryResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * SendInstantCommand instructs the proxy to enact some behavior one one or more of the devices it
     * supports. It is roughly expected to be called periodically by the control server.
     *
     * A new command issued to a device will override an older command, or any behavior that the
     * device was previously engaged in.
     *
     * Proxies should attempt to honor a command, even if the client deadline for a command is
     * exceeded. If the proxy is in the middle of carrying out an operation on a device when it
     * receives a new command, it should return an UNAVAILABLE grpc error to indicate that the command
     * should be retried later.
     *
     * Commands may have a duration supplied, which indicates how long the command should be in effect.
     * If the duration is empty then the command is meant to last indefinitely.  If a device does not
     * support a command lasting for some amount of time, this can return an error.
     * Similarly, commands may have a start_time set.  If it is empty, the command is meant to start immediately.
     * if the time is in the past the proxy can return an error or truncate the duration appropriately.
     * if the time is in the future, and the device supports scheduling events the proxy should forward that along,
     * however, if the device does not support scheduling events, then the proxy can return COMMAND_STATUS_UNSUPPORTED_START_TIME
     * rather than implementing a scheduler (and the control-server will be responsible for retrying the command)
     *
     * The following metrics are supported by the following device types:
     *
     * battery
     * - METRIC_ACTIVE_POWER_WATTS: Active power, as defined by conditions.power.real in
     * resource.proto. Positive values represent charging the battery from the grid, and negative
     * values represent discharging the battery to the grid.
     *
     * @generated from rpc ritta.device.proto.Proxy.SendInstantCommand
     */
    sendInstantCommand: {
      name: "SendInstantCommand",
      I: Command,
      O: CommandStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Stream back resource measurements for the given device
     *
     * @generated from rpc ritta.device.proto.Proxy.GetStreamingResourceMeasurements
     */
    getStreamingResourceMeasurements: {
      name: "GetStreamingResourceMeasurements",
      I: GetStreamingResourceMeasurementsRequest,
      O: ResourceMeasurements,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Get back list of current resources
     *
     * @generated from rpc ritta.device.proto.Proxy.GetResources
     */
    getResources: {
      name: "GetResources",
      I: GetResourcesRequest,
      O: GetResourcesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Device Schedule Commands
     * NOTE: for these to work from rfa through the control server, they _must_
     * include a `device_id` field in the request so they can be routed to the
     * right proxy
     *
     * Takes a list of commands to schedule, returns an array with success/failure
     * for each. If there are any existing commands scheduled in this time range,
     * they are deleted before attempting to submit the new commands.
     *
     * The validation_only flag can be used to validate the schedule without actually writing it.
     *
     * @generated from rpc ritta.device.proto.Proxy.DeviceSendSchedule
     */
    deviceSendSchedule: {
      name: "DeviceSendSchedule",
      I: DeviceSchedule,
      O: DeviceScheduleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Deletes all scheduled commands in a time range. Returns a list of deleted commands.
     *
     * @generated from rpc ritta.device.proto.Proxy.DeviceDeleteSchedule
     */
    deviceDeleteSchedule: {
      name: "DeviceDeleteSchedule",
      I: DeviceScheduleTimeRange,
      O: DeviceSchedule,
      kind: MethodKind.Unary,
    },
    /**
     * Returns all scheduled commands in a time range.
     *
     * @generated from rpc ritta.device.proto.Proxy.DeviceGetSchedule
     */
    deviceGetSchedule: {
      name: "DeviceGetSchedule",
      I: DeviceScheduleTimeRange,
      O: DeviceSchedule,
      kind: MethodKind.Unary,
    },
  }
};


<template>
  <date-picker
    :model-value="dateValue"
    :max="maxDate"
    hide-details
    @change="handleNewValue"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime } from 'luxon'
import { FilterDate } from '@/model/tables/DataTable'
import DatePicker from '@/components/common/DatePicker.vue'

export default defineComponent({
  name: 'FilterDate',
  props: {
    fields: {
      type: Object as PropType<FilterDate>,
      required: true,
    },
  },
  components: { DatePicker },
  computed: {
    dateValue(): string {
      return this.fields.value?.toISODate() ?? ''
    },
    maxDate(): string {
      return DateTime.now().toISODate()
    },
  },
  methods: {
    handleNewValue(dateInput: string): void {
      const value = dateInput ? DateTime.fromISO(dateInput) : null

      this.$emit('new-fields', { ...this.fields, value })
    },
  },
})
</script>

import { RittaConfig } from '@/config'
import { GREEN1 } from '@/constants/colors'
import { DEFAULT_HEIGHT } from '@/constants/infiniteScrollChart'
import { Services } from '@/services'
import { ChartDefinition, ChartType } from '@/types/charts'
import { TimeSeriesDataSource } from '../charts'

export const forecastRMSEChart: ChartDefinition = {
  id: 'forecast-quality-rmse',
  type: ChartType.Errors,
  title: '',
  height: DEFAULT_HEIGHT * 1.5,
}

export function newForecastQualityDataSource(
  config: Readonly<RittaConfig>,
  { forecastService }: Services
) {
  const product = config.analysis!.forecastQuality!.product

  const ds = new TimeSeriesDataSource((request) => {
    return forecastService.getForecastQuality(product, request)
  })

  return ds
    .addChartSeries(forecastRMSEChart, {
      metric: 'forecast.runtime.analysis.error.p90',
      config: {
        seriesName: 'MAE - 90th percentile',
        seriesColor: GREEN1.hex,
        seriesLine: 'dashed',
        seriesFill: 'next',
      },
    })
    .addChartSeries(forecastRMSEChart, {
      metric: 'forecast.runtime.analysis.error.p50',
      config: {
        seriesName: 'MAE - 50th percentile',
        seriesColor: GREEN1.hex,
      },
    })
    .addChartSeries(forecastRMSEChart, {
      metric: 'forecast.runtime.analysis.error.p10',
      config: {
        seriesName: 'MAE - 10th percentile',
        seriesColor: GREEN1.hex,
        seriesLine: 'dashed',
        seriesFill: 'prev',
      },
    })
}

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/hosting.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { VoltageDeviationsRequest, VoltageDeviationsResponse } from "./hosting_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.Hosting
 */
export const Hosting = {
  typeName: "ritta.frontend.proto.Hosting",
  methods: {
    /**
     * @generated from rpc ritta.frontend.proto.Hosting.VoltageDeviations
     */
    voltageDeviations: {
      name: "VoltageDeviations",
      I: VoltageDeviationsRequest,
      O: VoltageDeviationsResponse,
      kind: MethodKind.Unary,
    },
  }
};


import {
  CompositeLayer,
  type LayerProps,
  type DefaultProps,
} from '@deck.gl/core'
import { PathStyleExtension } from '@deck.gl/extensions'
import { PathLayer, IconLayer } from '@deck.gl/layers'
import type { RGBRaw } from '@/constants/colors'
import { ResourceType } from '@/constants/resourceType'
import { ICON_SIZE } from '@/model/map'
import { COMMON_SIZE_DIVISOR, ICON_MAX_PIXELS } from '@/model/map/constants'
import { toPosition } from '@/components/maps/layers/extensions'
import { LOADER_OPTIONS, getTintIconAtlas } from './iconAtlasTint'
import type { Resource } from 'rfs/pb/resource_pb'

// TODO(rafael): make this come from `RittaConfig`.
const PINK_COLOR = [255, 0, 128]

// Lng, Lat.
export type Point = [number, number]
export type Line = Point[]

export interface AncestryLayerProps extends LayerProps {
  id: string
  resources?: Resource[]
  customColor?: RGBRaw
}

export class UplineLayer extends CompositeLayer<AncestryLayerProps> {
  static layerName = 'UplineLayer'
  static defaultProps: DefaultProps<AncestryLayerProps> = {}

  renderLayers() {
    // Expected the atlas to be already available.
    const [iconAtlas, iconMapping] = getTintIconAtlas()

    const onlyConductors: Resource[] = []
    const onlyTransformers: Resource[] = []
    const theRest: Resource[] = []

    for (const r of this.props.resources ?? []) {
      if (r.type === ResourceType.CONDUCTOR) {
        onlyConductors.push(r)
      } else if (r.type === ResourceType.TRANSFORMER) {
        onlyTransformers.push(r)
      } else {
        theRest.push(r)
      }
    }

    const pathData = extractLines(onlyConductors)

    const color = this.props.customColor ?? PINK_COLOR

    return [
      new PathLayer({
        id: `${this.props.id}-path`,
        data: pathData,
        //
        widthUnits: 'pixels',
        widthScale: 1,
        widthMinPixels: 4,
        widthMaxPixels: 4,
        getPath: (d) => d,
        // @ts-ignore
        getColor: (_d) => color,
        // @ts-ignore
        getDashArray: [3, 6],
        dashStart: 0,
        extensions: [new PathStyleExtension({ dash: true })],
      }),
      // Transformer icons.
      new IconLayer({
        id: `${this.props.id}-icon-transformers`,
        // @ts-ignore
        iconAtlas,
        iconMapping,
        data: onlyTransformers,
        pickable: false, // Important.
        //
        /* Using the "common" size unit allows the icon to get smaller as the user zooms out. */
        sizeUnits: 'common',
        sizeMinPixels: ICON_MAX_PIXELS / 2,
        sizeMaxPixels: ICON_MAX_PIXELS,
        getSize: (ICON_MAX_PIXELS * 0.75) / COMMON_SIZE_DIVISOR,
        //
        getPosition: (r: Resource) => {
          const location = r.location?.point
          return location ? toPosition(location) : [0, 0]
        },
        // @ts-ignore
        getColor: (_d) => color,
        getIcon: (r) => r.type,
        loadOptions: LOADER_OPTIONS,
      }),
      // The other Resource icons.
      new IconLayer({
        id: `${this.props.id}-icon-rest`,
        // @ts-ignore
        iconAtlas,
        iconMapping,
        data: theRest,
        pickable: false, // Important.
        getSize: ICON_SIZE,
        getPosition: (r: Resource) => {
          const location = r.location?.point
          return location ? toPosition(location) : [0, 0]
        },
        // @ts-ignore
        getColor: (_d) => color,
        getIcon: (r) => r.type,
        loadOptions: LOADER_OPTIONS,
      }),
    ]
  }
}

function extractLines(resources: Resource[]): Line[] {
  const lines: Line[] = []

  for (const resource of resources) {
    const lineString = resource?.location?.lineString

    if (!lineString?.length) continue

    const line = lineString.map((line): Point => [line.lng, line.lat])

    lines.push(line)
  }

  return lines
}

<template>
  <wide-left-panel class="c-SiteLeftPanel">
    <!-- Loading -->
    <centered-spinner v-if="isLoading && !didLoadingFail" />

    <!-- Left column -->
    <template v-if="showContent" v-slot:[SLOT_COLUMN_LEFT]>
      <div class="d-flex flex-column" style="height: 100%">
        <!-- Header -->
        <site-header
          :site-id="siteId"
          :site-meter="meterResource"
          class="pl-6 pb-6"
        />

        <!-- Tabs -->
        <site-info-panel
          v-if="siteResource"
          :model-value="selectedSiteTab"
          :site-resource="siteResource"
          :other-resources="otherResources"
          @update:model-value="setSelectedSiteTab"
        />
      </div>
    </template>

    <!-- Right column -->
    <template v-if="showContent && !onlyShowDetails" v-slot:[SLOT_COLUMN_RIGHT]>
      <!-- Charts -->
      <site-charts
        v-if="siteResource"
        :site-resource="siteResource"
        :other-resources="otherResources"
      />
    </template>
  </wide-left-panel>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { mapActions, mapState } from 'pinia'
import { ResourceType } from '@/constants/resourceType'
import { useAncestryStore } from '@/stores/ancestry'
import { useGridMapStore } from '@/stores/gridMap'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import * as RouteNames from '@/router/routeNames'
import { Resource } from 'rfs/pb/resource_pb'
import WideLeftPanel, {
  SLOT_COLUMN_LEFT,
  SLOT_COLUMN_RIGHT,
} from '@/components/WideLeftPanel.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SiteInfoPanel from '@/components/SiteInfoPanel.vue'
import { isSiteMeter } from '@/model/resource'
import SiteCharts from './site/SiteCharts.vue'

export default defineComponent({
  name: 'SiteLeftPanel',
  props: {
    siteId: {
      type: String,
      required: true,
    },
  },
  components: {
    WideLeftPanel,
    CenteredSpinner,
    SiteHeader,
    SiteInfoPanel,
    SiteCharts,
  },
  setup() {
    return {
      SLOT_COLUMN_LEFT,
      SLOT_COLUMN_RIGHT,
      gridMapStore: useGridMapStore(),
      ancestryStore: useAncestryStore(),
    }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      didLoadingFail: false,
      siteResource: null as Resource | null,
      otherResources: [] as Resource[],
    })
  },
  computed: {
    ...mapState(useNavigationControlsStore, ['selectedSiteTab']),
    onlyShowDetails(): boolean {
      return this.$rittaConfig.monitor?.site?.onlyShowDetails ?? false
    },
    showContent(): boolean {
      return !this.isLoading && !this.didLoadingFail
    },
    meterResource(): Resource | undefined {
      if (this.siteResource == null) {
        return undefined
      }
      return this.otherResources.find((r) => isSiteMeter(this.siteResource!, r))
    },
  },
  watch: {
    siteId: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
    siteResource() {
      this.handleMapInteractions()
    },
  },
  beforeRouteLeave(_to, _from, next): void {
    this.gridMapStore.clearFocus()
    this.ancestryStore.clearResource()
    next()
  },
  methods: {
    ...mapActions(useNavigationControlsStore, ['setSelectedSiteTab']),
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.didLoadingFail = false
      this.siteResource = null
      this.otherResources = []

      try {
        const [site, other] =
          await this.$services.queryService.getResourcesBySiteID(this.siteId)

        this.siteResource = site
        this.otherResources = other

        // Upline feature.
        if (this.$rittaConfig.map.uplineMapLayer?.enabled) {
          const meter = this.otherResources.find(
            (r) => r.type === ResourceType.METER_ELECTRICAL
          )
          if (meter) {
            this.ancestryStore.setResource(meter.id)
          }
        }
      } catch (error) {
        this.didLoadingFail = true
        console.error('SiteLeftPanel.fetchData:', error)

        // Replaces the route when no Resources found or any other error.
        this.$router.replace({ name: RouteNames.METERS })
      } finally {
        this.isLoading = false
      }
    },
    handleMapInteractions(): void {
      if (!this.siteResource) {
        this.gridMapStore.clearFocus()
        return
      }

      const location = this.siteResource.location?.point

      if (location) {
        this.gridMapStore.focusLocations({
          locations: [location],
          showRedPins: true,
          minZoom: 16,
        })
      }
    },
  },
})
</script>

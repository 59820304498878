import { type DateTime, Interval } from 'luxon'
import { CILANTRO, ORANGE_500, PURPLE1 } from '@/constants/colors'
import { Metric as ChartMetric, PlanScheduleMetric } from '@/constants/metrics'
import type { Services } from '@/services'
import { Timestamp } from '@/services/timestamp_pb'
import { MetricCalculation } from '@/services/charts.service'
import { ChartResolution, resolutionDurations } from '@/utils/charts'
import { NOW_MARKER } from '@/utils/chartjs/annotations'
import { limitInterval } from '@/utils/time'
import { FixedTimeDataSource, type ITimeSeriesDataSource } from '@/model/charts'
import { createCutOffDate } from '@/model/control'
import { createActivePowerTimeSeriesConfig } from '@/model/control/telemetry'
import {
  Metric,
  createWaypointFutureTimeSeriesConfig,
  createSocFutureTimeSeriesConfig,
  createDispatchFutureTimeSeriesConfig,
  removeWaypointMetric,
  getStartAndEndTimes,
} from '@/model/control/waypoint'
import {
  ONE_TILE_SIZE,
  type DataProvider,
  type TimeSeriesConfig,
} from '@/model/charts/TimeSeriesDataSource'
import { TimeSeriesWithRefreshDataSource } from '@/model/charts/TimeSeriesWithRefreshDataSource'
import { ChartType, type ChartDefinition } from '@/types/charts'
import {
  TimeSeriesResponse,
  TimeSeries as TsdbTimeSeries,
  TimeSeries_DataPoint as TimeSeriesDataPoint,
} from 'rfs/frontend/proto/tsdb_pb'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import type { ScheduleTimeSeriesResponse } from 'rfs/frontend/proto/controls_pb'
import { Waypoint } from 'rfs/control/proto/waypoints_pb'
import type { DeviceType, Group } from 'rfs/control/proto/model_pb'

export const waypointChartDefinition: ChartDefinition = {
  id: 'group-waypoint-chart',
  title: 'State of Charge',
  type: ChartType.EnergyLinePercentage,
  annotations: { timeMarkers: [NOW_MARKER], everyOtherDay: true },
  yAxis: { min: 0, max: 1 },
}

export const wattHourWaypointChartDefinition: ChartDefinition = {
  id: 'watt-hour-waypoint-chart',
  title: 'State of Charge',
  type: ChartType.EnergyLine,
  annotations: { timeMarkers: [NOW_MARKER], everyOtherDay: true },
}

export const dispatchChartDefinition: ChartDefinition = {
  id: 'group-dispatch-chart',
  title: 'Dispatch',
  type: ChartType.Power,
  annotations: { timeMarkers: [NOW_MARKER], everyOtherDay: true },
}

const socFutureTimeSeriesConfig = createSocFutureTimeSeriesConfig()

const dispatchFutureSeriesConfig = createDispatchFutureTimeSeriesConfig()

export function newGroupWaypointHistoricalDataSource(
  services: Services,
  group: Group,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) =>
    services.controlsData.fetchGroupTimeSeries(group.id, {
      ...request,
      resolution: Resolution.ONE_MINUTE,
    })

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow()),
    ONE_TILE_SIZE
  )

  // SoC (Historical).
  ds.addChartSeries(waypointChartDefinition, {
    metric: ChartMetric.COND_CHARGE_STATE,
    calculation: MetricCalculation.AVG,
    config: {
      seriesName: 'SoC (historical)',
      seriesColor: PURPLE1.hex,
    },
  })

  // Dispatch (Historical).
  ds.addChartSeries(dispatchChartDefinition, {
    metric: ChartMetric.COND_POWER_REAL,
    calculation: MetricCalculation.SUM,
    config: {
      seriesName: 'Dispatch (historical)',
      seriesColor: CILANTRO.hex,
      seriesFill: 'origin',
    },
  })

  return ds
}

export function newGroupWaypointFutureDataSource(
  services: Services,
  interval: Interval,
  groupId: string,
  metric: Metric,
  deviceType: DeviceType,
  schedule?: ScheduleTimeSeriesResponse,
  modifiedWaypoints?: Waypoint[]
): ITimeSeriesDataSource {
  const waypointSeriesConfig = createWaypointFutureTimeSeriesConfig({
    metric,
    deviceType,
  })

  const ds = new FixedTimeDataSource(interval, async (req) => {
    // NOTE(rafael): When a schedule is not available, fetch one with the
    // modified waypoints.
    const res =
      schedule ??
      (await services.controlsData.fetchPlanSchedule(
        groupId,
        removeWaypointMetric(req),
        modifiedWaypoints
      ))

    res.timeSeries = res.timeSeries ?? new TimeSeriesResponse()

    // Transform the list of Waypoints into a time series.
    if (metric === Metric.METRIC_SOC_PERCENT) {
      const batterySocPercent = res.timeSeries.series.find(
        (series) =>
          series.metric === PlanScheduleMetric.CONTROL_BATTERY_SOC_PERCENT
      )

      if (batterySocPercent) {
        res.timeSeries.series.push(
          transformWaypointsToLineSegments(
            waypointSeriesConfig.metric,
            res.waypointsList,
            batterySocPercent
          )
        )
      }
    } else {
      res.timeSeries.series.push(
        transformWaypointsToScatterData(
          waypointSeriesConfig.metric,
          res.waypointsList
        )
      )
    }

    return res.timeSeries
  })

  // SoC (Future).
  ds.addChartSeries(
    waypointChartDefinition,
    createSocFutureTimeSeriesConfig({ useSocPercentMetric: true })
  )

  // Dispatch (Future).
  ds.addChartSeries(dispatchChartDefinition, dispatchFutureSeriesConfig)

  // Waypoints (Future).
  // NOTE: when waypoints have power (kW) values, put them on
  // the "Dispatch" chart.
  if (metric === Metric.METRIC_ACTIVE_POWER_WATTS) {
    ds.addChartSeries(dispatchChartDefinition, waypointSeriesConfig)
  } else {
    ds.addChartSeries(waypointChartDefinition, waypointSeriesConfig)
  }

  return ds
}

export function transformWaypointsToScatterData(
  metric: string,
  waypoints: Waypoint[]
): TsdbTimeSeries {
  return new TsdbTimeSeries({
    metric,
    data: waypoints.flatMap((w) => {
      const y = w.targetValue
      return [
        new TimeSeriesDataPoint({ x: w.startTime?.toMillis(), y }),
        new TimeSeriesDataPoint({ x: w.endTime?.toMillis(), y }),
        new TimeSeriesDataPoint({ x: (w.endTime?.toMillis() ?? 0) + 1 }), // Divider data point.
      ]
    }),
  })
}

export function transformEventsToLineSegments(
  metric: string,
  waypoints: Waypoint[],
  numberOfDevices: number,
  resolution = ChartResolution.OneMin
): TsdbTimeSeries {
  const duration = resolutionDurations.get(resolution)

  return new TsdbTimeSeries({
    metric,
    data: duration
      ? waypoints.reduce<TimeSeriesDataPoint[]>((acc, w) => {
          const { start, end } = getStartAndEndTimes(w)

          if (start && end) {
            const y = (w.targetValue ?? 0) * numberOfDevices

            const interval = Interval.fromDateTimes(start, end)

            let cursor = interval.start

            while (cursor <= interval.end) {
              acc.push(new TimeSeriesDataPoint({ x: cursor.toMillis(), y }))
              cursor = cursor.plus(duration)
            }

            // Last data point.
            acc.push(new TimeSeriesDataPoint({ x: cursor.toMillis() }))
          }

          return acc
        }, [])
      : [],
  })
}

export function transformWaypointsToLineSegments(
  metric: string,
  waypoints: Waypoint[],
  socSeries: TsdbTimeSeries,
  resolution = ChartResolution.OneMin
): TsdbTimeSeries {
  const duration = resolutionDurations.get(resolution)

  return new TsdbTimeSeries({
    metric,
    data: duration
      ? waypoints.reduce<TimeSeriesDataPoint[]>((acc, w) => {
          const { start, end } = getStartAndEndTimes(w)

          if (start && end) {
            // Is expected that the scheduled SoC contains a value we can use
            // for the start value of the segment.
            const socAtStart = socSeries.data.find(
              (dp) => dp.x === start.toMillis()
            )

            // First data point.
            acc.push(
              new TimeSeriesDataPoint({
                x: start.toMillis(),
                y: socAtStart?.y,
              })
            )

            // Last data point.
            acc.push(
              new TimeSeriesDataPoint({
                x: end.toMillis(),
                y: w.targetValue ?? 0,
              })
            )

            // Divider data point. It's required so it divides the line sections
            // for each waypoint.
            acc.push(
              new TimeSeriesDataPoint({ x: end.plus(duration).toMillis() })
            )
          }

          return acc
        }, [])
      : [],
  })
}

export const powerWaypointChartDefinition: ChartDefinition = {
  id: 'power-waypoint-chart',
  title: 'Dispatch',
  type: ChartType.Power,
  annotations: { timeMarkers: [NOW_MARKER], everyOtherDay: true },
  yAxis: { min: 0 },
}

export function newGroupTelemetryDataSource(
  chart: ChartDefinition,
  services: Services,
  group: Group,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) =>
    services.controlsData.fetchGroupTimeSeries(group.id, {
      ...request,
      // TODO(rafael): remove this as soon as the server stops sending "future"
      // data for the chargers.
      interval: limitInterval(request.interval, getNow()),
      resolution: Resolution.ONE_MINUTE,
    })

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow()),
    ONE_TILE_SIZE
  )

  // Active Power.
  const activePowerSeriesConfig: TimeSeriesConfig = {
    ...createActivePowerTimeSeriesConfig(group),
    calculation: MetricCalculation.SUM,
  }
  ds.addChartSeries(chart, activePowerSeriesConfig)

  return ds
}

const powerWaypointFutureDispatchScheduledSeriesConfig: TimeSeriesConfig = {
  metric: PlanScheduleMetric.CONTROL_MAX_POWER_CONSUMED_WATTS,
  config: {
    seriesName: 'Dispatch (scheduled)',
    seriesColor: CILANTRO.hex,
    seriesLine: 'dashed',
  },
}

export function newGroupPowerWaypointFutureDataSource(
  services: Services,
  interval: Interval,
  groupId: string,
  metric: Metric,
  deviceType: DeviceType,
  numOfDevices: number,
  schedule?: ScheduleTimeSeriesResponse,
  modifiedWaypoints?: Waypoint[]
): ITimeSeriesDataSource {
  const waypointSeriesConfig = createWaypointFutureTimeSeriesConfig({
    metric,
    deviceType,
  })

  const ds = new FixedTimeDataSource(interval, async (req) => {
    // NOTE(rafael): When a schedule is not available, fetch one with the
    // modified waypoints.
    const res =
      schedule ??
      (await services.controlsData.fetchPlanSchedule(
        groupId,
        removeWaypointMetric(req),
        modifiedWaypoints
      ))

    res.timeSeries = res.timeSeries ?? new TimeSeriesResponse()

    // Transform the list of Waypoints into a time series.
    res.timeSeries.series.push(
      transformEventsToLineSegments(
        waypointSeriesConfig.metric,
        res.waypointsList,
        numOfDevices
      )
    )

    return res.timeSeries
  })

  // Dispatch (scheduled).
  ds.addChartSeries(
    powerWaypointChartDefinition,
    powerWaypointFutureDispatchScheduledSeriesConfig
  )

  // Events (scheduled).
  ds.addChartSeries(powerWaypointChartDefinition, waypointSeriesConfig)

  return ds
}

export function newWattHourHistoricalDataSource(
  services: Services,
  group: Group,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const cuttOff = createCutOffDate(getNow())

  const ds = new TimeSeriesWithRefreshDataSource(
    async (request) =>
      services.controlsData.fetchGroupTimeSeries(group.id, {
        ...request,
        resolution: Resolution.ONE_MINUTE,
      }),
    cuttOff,
    ONE_TILE_SIZE
  )

  // Dispatch (Historical).
  ds.addChartSeries(dispatchChartDefinition, {
    metric: ChartMetric.COND_POWER_REAL,
    calculation: MetricCalculation.SUM,
    config: {
      seriesName: 'Dispatch (historical)',
      seriesColor: CILANTRO.hex,
      seriesFill: 'origin',
    },
  })

  // SoC (Historical).
  ds.addChartSeries(wattHourWaypointChartDefinition, {
    metric: ChartMetric.COND_CHARGE_LEVEL,
    calculation: MetricCalculation.SUM,
    config: { seriesName: 'SoC (historical)', seriesColor: PURPLE1.hex },
  })

  // Min SoC (forecast).
  ds.addChartSeries(wattHourWaypointChartDefinition, {
    metric: 'limits.charge.level.min',
    calculation: MetricCalculation.SUM,
    config: {
      seriesName: 'Min SoC (forecast)',
      seriesLine: 'dashed',
      seriesColor: ORANGE_500.hex,
    },
  })

  // Max SoC (forecast).
  ds.addChartSeries(wattHourWaypointChartDefinition, {
    metric: 'limits.charge.level.max',
    calculation: MetricCalculation.SUM,
    config: {
      seriesName: 'Max SoC (forecast)',
      seriesLine: 'dashed',
      seriesColor: ORANGE_500.hex,
    },
  })

  // Min Power (forecast).
  ds.addChartSeries(dispatchChartDefinition, {
    metric: 'limits.power.producing',
    unit: '-Wh',
    calculation: MetricCalculation.SUM,
    config: {
      seriesName: 'Min Power (forecast)',
      seriesLine: 'dashed',
      seriesColor: ORANGE_500.hex,
    },
  })

  // Max Power (forecast).
  ds.addChartSeries(dispatchChartDefinition, {
    metric: 'limits.power.consuming',
    calculation: MetricCalculation.SUM,
    config: {
      seriesName: 'Max Power (forecast)',
      seriesLine: 'dashed',
      seriesColor: ORANGE_500.hex,
    },
  })

  return ds
}

export function newWattHourFutureDataSource(
  services: Services,
  interval: Interval,
  groupId: string,
  metric: Metric,
  deviceType: DeviceType,
  schedule?: ScheduleTimeSeriesResponse,
  modifiedWaypoints?: Waypoint[]
): ITimeSeriesDataSource {
  const waypointSeriesConfig = createWaypointFutureTimeSeriesConfig({
    metric,
    deviceType,
  })

  const ds = new FixedTimeDataSource(interval, async (req) => {
    // NOTE(rafael): When a schedule is not available, fetch one with the
    // modified waypoints.
    const res =
      schedule ??
      (await services.controlsData.fetchPlanSchedule(
        groupId,
        removeWaypointMetric(req),
        modifiedWaypoints
      ))

    res.timeSeries = res.timeSeries ?? new TimeSeriesResponse()

    const batterySoc = res.timeSeries.series.find(
      (series) => series.metric === PlanScheduleMetric.CONTROL_BATTERY_SOC
    )

    // Transform the list of Waypoints into a time series.
    if (batterySoc) {
      res.timeSeries.series.push(
        transformWaypointsToLineSegments(
          waypointSeriesConfig.metric,
          res.waypointsList,
          batterySoc
        )
      )
    }

    return res.timeSeries
  })

  // SoC (Future).
  ds.addChartSeries(wattHourWaypointChartDefinition, socFutureTimeSeriesConfig)

  // Dispatch (Future).
  ds.addChartSeries(dispatchChartDefinition, dispatchFutureSeriesConfig)

  // Waypoints (Future).
  ds.addChartSeries(wattHourWaypointChartDefinition, waypointSeriesConfig)

  return ds
}

/**
 * Fetch and generate a simulated time series to represent the "Manual" mode
 * on the chart.
 */
export async function fetchSimulatedManualModeSeries(
  services: Services,
  group: Group,
  targetMetric: Metric,
  now: DateTime
): Promise<null | ScheduleTimeSeriesResponse> {
  const setpoint =
    group.currentPolicyParams?.paramsOneof.case === 'manualControlEvent'
      ? group.currentPolicyParams.paramsOneof.value.activePowerSetpoint
      : undefined

  if (setpoint === undefined) return null

  // :00, :15, :30 or :45.
  const validMinutes = [0, 15, 30, 45]

  // NOTE(rafael): Using a fabricated waypoint requires truncating
  // its start and end times to meet server requirements.
  const truncated = now.set({ second: 0, millisecond: 0 })

  const nextValidMinute = validMinutes.find((m) => m > truncated.minute || 0)

  const start = truncated
    .set({ minute: nextValidMinute })
    .plus(nextValidMinute === 0 ? { hours: 1 } : {})

  const end = truncated.plus({ hours: 24 })

  const waypoint = new Waypoint({
    targetMetric,
    targetValue: setpoint,
    startTime: Timestamp.fromMillis(start.toMillis()),
    endTime: Timestamp.fromMillis(end.toMillis()),
  })

  const res = await services.controlsData.fetchPlanSchedule(
    group.id,
    {
      interval: Interval.fromDateTimes(start, end),
      // Ask for all plan scheule metrics.
      metrics: Object.values(PlanScheduleMetric).map((m) => ({
        metric: m,
      })),
    },
    [waypoint]
  )

  // Clear waypoints to prevent them from rendering on the chart.
  res.waypointsList = []

  return res
}

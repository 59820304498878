// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/controls.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ScheduleTimeSeriesRequest, ScheduleTimeSeriesResponse } from "./controls_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { TimeSeriesRequest, TimeSeriesResponse } from "./tsdb_pb.js";

/**
 * @generated from service ritta.frontend.proto.ControlsData
 */
export const ControlsData = {
  typeName: "ritta.frontend.proto.ControlsData",
  methods: {
    /**
     *  PlanScheduleTimeSeries sends a schedule plan to the controls server
     *
     * @generated from rpc ritta.frontend.proto.ControlsData.PlanScheduleTimeSeries
     */
    planScheduleTimeSeries: {
      name: "PlanScheduleTimeSeries",
      I: ScheduleTimeSeriesRequest,
      O: ScheduleTimeSeriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * HistoricalScheduleTimeSeries gets past schedule executions
     *
     * @generated from rpc ritta.frontend.proto.ControlsData.HistoricalScheduleTimeSeries
     */
    historicalScheduleTimeSeries: {
      name: "HistoricalScheduleTimeSeries",
      I: ScheduleTimeSeriesRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ritta.frontend.proto.ControlsData.GroupTimeSeries
     */
    groupTimeSeries: {
      name: "GroupTimeSeries",
      I: TimeSeriesRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.Unary,
    },
  }
};


// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/fleet.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { VehiclesRequest, VehiclesResponse } from "./fleet_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.Fleet
 */
export const Fleet = {
  typeName: "ritta.frontend.proto.Fleet",
  methods: {
    /**
     * Fleets
     *
     * @generated from rpc ritta.frontend.proto.Fleet.Vehicles
     */
    vehicles: {
      name: "Vehicles",
      I: VehiclesRequest,
      O: VehiclesResponse,
      kind: MethodKind.Unary,
    },
  }
};


import { type DateTime } from 'luxon'
import { PACIFIC, PURPLE1 } from '@/constants/colors'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import type { Services } from '@/services'
import { limitInterval } from '@/utils/time'
import {
  AggregateDataSource,
  TimeSeriesDataSource,
  type DataProvider,
  type ITimeSeriesDataSource,
} from '@/model/charts'
import { ONE_TILE_SIZE } from '@/model/charts/TimeSeriesDataSource'
import { createCutOffDate } from '@/model/control'
import {
  activePowerChartDefinition,
  reactivePowerChartDefinition,
  createActivePowerTimeSeriesConfig,
  createReactivePowerTimeSeriesConfig,
  socPercentageChartDefinition,
  socTimeSeriesConfig,
  transformerLoadingChartDefinition,
} from '@/model/control/telemetry'
import { getPowerRating } from '@/model/resource'
import { loadPercentConfig } from '@/model/transformer'
import { createOperatingEnvelpeDataSource } from '@/model/control/operatingEnvelope'
import { TimeSeriesWithRefreshDataSource } from '@/model/charts/TimeSeriesWithRefreshDataSource'
import type { Group } from 'rfs/control/proto/model_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import {
  TimeSeries as TsdbTimeSeries,
  TimeSeries_DataPoint as TsdbDataPoint,
  TimeSeriesResponse,
} from 'rfs/frontend/proto/tsdb_pb'

// NOTE(rafael): The resolution for device telemetry is raw.
export const DATA_POINT_AGE_LIMIT_MINUTES = 65

export function newDeviceTelemetryDataSource(
  services: Services,
  group: Group,
  resource: Resource,
  showSoc: boolean,
  showReactivePower: boolean,
  showOperatingEnvelope: boolean,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) => {
    request.interval = limitInterval(request.interval, getNow())
    // Truncates the timestamps, ensuring 1-minute intervals.
    request.resolution = Resolution.ONE_MINUTE
    return services.chartsService.fetchTimeSeries(resource.id, request)
  }

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow())
  )

  // Active Power.
  ds.addChartSeries(
    activePowerChartDefinition,
    createActivePowerTimeSeriesConfig(group)
  )

  // Reactive Power.
  if (showReactivePower) {
    ds.addChartSeries(
      reactivePowerChartDefinition,
      createReactivePowerTimeSeriesConfig()
    )
  }

  // SoC.
  if (showSoc) {
    ds.addChartSeries(socPercentageChartDefinition, socTimeSeriesConfig)
  }

  // Operating Envelope.
  if (showOperatingEnvelope) {
    return new AggregateDataSource(
      ds,
      createOperatingEnvelpeDataSource(
        services,
        [resource.id],
        activePowerChartDefinition,
        getNow
      )
    )
  }

  return ds
}

export function newTransformerDataSource(
  services: Services,
  transformerId: string,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const cutOff = createCutOffDate(getNow())

  const ds = new TimeSeriesWithRefreshDataSource((request) => {
    request.aggregation = ResourceType.METER_ELECTRICAL
    request.resolution = Resolution.ONE_HOUR
    return services.chartsService.fetchTimeSeries(transformerId, request)
  }, cutOff)

  // Load %.
  ds.addChartSeries(transformerLoadingChartDefinition, loadPercentConfig)

  // Uncontrollable load.
  ds.addChartSeries(transformerLoadingChartDefinition, {
    metric: Metric.FORECAST_ACTUAL_COND_POWER_APPARENT,
    config: {
      seriesName: 'Uncontrollable load',
      seriesColor: PACIFIC.hex,
      stackGroup: 'group1',
      seriesFill: 'origin',
    },
  })

  // Controllable load.
  ds.addChartSeries(transformerLoadingChartDefinition, {
    metric: Metric.CONTROLLABLE_COND_POWER_APPARENT,
    config: {
      seriesName: 'Controllable load',
      seriesColor: PURPLE1.hex,
      stackGroup: 'group1',
      seriesFill: 'prev',
    },
  })

  // Forecast.
  ds.addChartSeries(transformerLoadingChartDefinition, {
    metric: Metric.FORECAST_LATEST_COND_POWER_APPARENT,
    config: {
      seriesName: 'Forecast',
      seriesColor: PACIFIC.hex,
      seriesLine: 'dashed',
    },
  })

  return ds
}

export function createCurtailmentDatasource(
  services: Services,
  resource: Resource,
  showOperatingEnvelope: boolean,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const power = getPowerRating(resource) ?? 0

  const observedMetric = 'rfa.curtailment.observed'
  const forecastedMetric = 'rfa.curtailment.forecasted'

  const dataProvider: DataProvider = async (request) => {
    const res = await services.chartsService.fetchTimeSeries(resource.id, {
      ...request,
      resolution: Resolution.ONE_MINUTE,
      metrics: [
        { metric: Metric.ENVELOPE_POWER_CONSUMING },
        { metric: Metric.COND_POWER_REAL },
      ],
    })

    const active = res.series.find(
      (series) => series.metric === Metric.COND_POWER_REAL
    )

    const envelope = res.series.find(
      (series) => series.metric === Metric.ENVELOPE_POWER_CONSUMING
    )

    const newRes = new TimeSeriesResponse()

    if (envelope) {
      envelope.data = increaseResolution(
        removeInfinityValues(envelope.data, power)
      )

      const now = getNow()

      // Observed.
      const observedData = envelope.data
        .reduce((acc, dp) => {
          // No "future" data points.
          if (dp.x > now.toMillis()) {
            return acc
          }

          // Find the "Active power" data point with the same timestamp,
          // so we can compare it with the "envelope" data point.
          const activeDp = active?.data.find((adp) => adp.x === dp.x)

          const newDp = new TsdbDataPoint({ x: dp.x })

          if (
            dp.y !== power &&
            isApproximatelyEqual(activeDp?.y ?? 0, dp.y ?? 0)
          ) {
            newDp.y = Infinity
          } else {
            newDp.y = NaN
          }

          acc.push(newDp)

          return acc
        }, [] as TsdbDataPoint[])
        .filter((dp, index, arr) => {
          const nextDp: undefined | TsdbDataPoint = arr[index + 1]

          if (Number.isNaN(dp.y) && nextDp && Number.isNaN(nextDp.y)) {
            return false
          }

          return true
        })

      const observed = new TsdbTimeSeries({
        metric: observedMetric,
        data: observedData,
      })

      // Forecasted.
      const forecastedData = envelope.data
        .reduce((acc, dp) => {
          // No historical data points.
          if (dp.x < now.toMillis()) {
            return acc
          }

          const newDp = new TsdbDataPoint({ x: dp.x })

          if (dp.y !== power) {
            newDp.y = Infinity
          } else {
            newDp.y = NaN
          }

          acc.push(newDp)

          return acc
        }, [] as TsdbDataPoint[])
        .filter((dp, index, arr) => {
          const nextDp: undefined | TsdbDataPoint = arr[index + 1]

          if (Number.isNaN(dp.y) && nextDp && Number.isNaN(nextDp.y)) {
            return false
          }

          return true
        })

      const forecasted = new TsdbTimeSeries({
        metric: forecastedMetric,
        data: forecastedData,
      })

      newRes.series.push(observed, forecasted)
    }

    return newRes
  }

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    getNow(), // No need for a big cut off since the data is hourly.
    ONE_TILE_SIZE
  )

  // Curtailment (observed).
  ds.addChartSeries(transformerLoadingChartDefinition, {
    metric: observedMetric,
    config: {
      seriesName: 'Curtailment (observed)',
      seriesColor: '#18440b',
      hideFromTooltip: true,
    },
  })

  // Curtailment (forecasted).
  if (showOperatingEnvelope) {
    ds.addChartSeries(transformerLoadingChartDefinition, {
      metric: forecastedMetric,
      config: {
        seriesName: 'Curtailment (forecasted)',
        seriesColor: '#349318',
        hideFromTooltip: true,
      },
    })
  }

  return ds
}

function isApproximatelyEqual(
  num1: number,
  num2: number,
  tolerancePercent: number = 5
): boolean {
  const tolerance = (tolerancePercent / 100) * num2
  return Math.abs(num1 - num2) <= tolerance
}

function removeInfinityValues(
  datapoints: TsdbDataPoint[],
  powerRating: number
): TsdbDataPoint[] {
  return datapoints.map((dp) => {
    if (dp.y === Infinity) {
      dp.y = powerRating
    }
    return dp
  })
}

/**
 * Increases the resolution of a series of data points by interpolating new points
 * between each pair of consecutive points. The interpolation follows a
 * "stepped line" strategy (like Chart.js's "after" option), where
 * intermediate points copy the `y` value of the later point.
 */
function increaseResolution(datapoints: TsdbDataPoint[]): TsdbDataPoint[] {
  return datapoints.reduce((acc, dp, index, arr) => {
    const prevDp: undefined | TsdbDataPoint = arr[index - 1]

    if (prevDp) {
      let currentX = prevDp.x + 60_000 // + 1-min

      while (currentX < dp.x) {
        acc.push(new TsdbDataPoint({ x: currentX, y: dp.y }))
        currentX = currentX + 60_000 // + 1-min
      }
    }

    acc.push(dp)

    return acc
  }, [] as TsdbDataPoint[])
}

import { shallowReactive } from 'vue'
import { defineImmutableStore } from './defineStore'
import type { Resource } from 'rfs/pb/resource_pb'

export const UPLINE_LAYER_ID = 'upline-layer'

export const useAncestryStore = defineImmutableStore('ancestry', {
  persist: { paths: ['isUplineVisible'] },
  state: () => {
    return shallowReactive({
      selectedResourceId: '',
      isLoading: false,
      uplineResources: [] as Resource[],
      isUplineVisible: true,
    })
  },
  actions: {
    setResource(newId: string): void {
      // Skip
      if (newId === this.selectedResourceId) return

      this.selectedResourceId = newId
      this.uplineResources = []

      this.fetchUplineResources()
    },
    clearResource(): void {
      this.selectedResourceId = ''
      this.uplineResources = []
    },
    toggleUplineVisibility(): void {
      this.isUplineVisible = !this.isUplineVisible
    },
    async fetchUplineResources(): Promise<void> {
      this.isLoading = true

      try {
        // The resources are listed from descendant to ancestor (bottom to top).
        const { resources } = await this.services.analysisService.getUpline({
          id: this.selectedResourceId,
        })
        this.uplineResources = resources
      } catch (err) {
        console.error('ancestry.fetchUplineResources: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})

import { createApp } from 'vue'
import App from '@/App.vue'
import { loadConfig } from '@/config'
import { CeUser } from '@/model/user'
import {
  newDictionaryPlugin,
  newConfigPlugin,
  newServicePlugin,
  newObservationTimePlugin,
} from '@/plugins/vue'
import vuetify from '@/plugins/vuetify'
import { initSentry } from '@/plugins/sentry'
import { newPinia } from '@/plugins/pinia'
import { newRouter } from '@/router'
import { newServices } from '@/services'
import { Analytics } from '@/utils/analytics'
import { addGcpIapSessionRefresherIframe } from '@/utils/gpcIapSessionRefresher'

const isDevEnv = import.meta.env.DEV

;(async () => {
  const config = await loadConfig()
  const userEmail = await CeUser.getUserEmailNoThrow()

  Analytics.init(config.customer)
  Analytics.setUser(userEmail)

  const services = newServices(isDevEnv, config)
  const router = newRouter(config)
  const pinia = newPinia(config, services)

  const app = createApp(App)
  app.use(newObservationTimePlugin(import.meta.env.VITE_OBSERVATION_TIME))
  app.use(newConfigPlugin(config))
  app.use(newDictionaryPlugin(config))
  app.use(newServicePlugin(services))
  app.use(vuetify)
  app.use(pinia)
  app.use(router)

  app.mount('#app')

  /** * Sentry */
  // Playwright: check if running under automation.
  const isAutomated = navigator.webdriver
  const releaseVersion = import.meta.env.VITE_GIT_VERSION
  if (!isDevEnv && !isAutomated && releaseVersion) {
    initSentry(app, config.customer.shortName, router, releaseVersion)
  }

  if (!isDevEnv) {
    addGcpIapSessionRefresherIframe()
  }
})()

import {
  LABEL_CONTROLS_OFF,
  LABEL_ENVELOPES_ONLY,
  LABEL_MANUAL,
  LABEL_RECU_SCHEDULE,
  LABEL_TEMP_SCHEDULE,
} from '@/model/control/labels'

export const SELECT_LABEL = 'Change control mode'

export enum Option {
  CONTROLS_OFF = 'CONTROLS_OFF',
  ENVELOPES_ONLY = 'ENVELOPES_ONLY',
  MANUAL = 'MANUAL',
  TEMPORARY_SCHEDULE = 'TEMPORARY_SCHEDULE',
  RECURRING_SCHEDULE = 'RECURRING_SCHEDULE',
  ENVELOPE_STATUS = 'ENVELOPE_STATUS',
}

const OPTION_LABEL: Record<Option, string> = {
  [Option.CONTROLS_OFF]: LABEL_CONTROLS_OFF,
  [Option.ENVELOPES_ONLY]: LABEL_ENVELOPES_ONLY,
  [Option.MANUAL]: LABEL_MANUAL,
  [Option.TEMPORARY_SCHEDULE]: LABEL_TEMP_SCHEDULE,
  [Option.RECURRING_SCHEDULE]: LABEL_RECU_SCHEDULE,
  [Option.ENVELOPE_STATUS]: 'Envelope status',
}

export type SelectItem = {
  title: string
  value: Option
  props?: {
    disabled?: boolean
    title?: string
    value?: any
  }
}

export const optionControlsOff: SelectItem = {
  title: OPTION_LABEL[Option.CONTROLS_OFF],
  value: Option.CONTROLS_OFF,
}

export const optionEnvelopesOnly: SelectItem = {
  title: OPTION_LABEL[Option.ENVELOPES_ONLY],
  value: Option.ENVELOPES_ONLY,
}

export const optionManual: SelectItem = {
  title: OPTION_LABEL[Option.MANUAL],
  value: Option.MANUAL,
}

export const optionRecurringSchedule: SelectItem = {
  title: OPTION_LABEL[Option.RECURRING_SCHEDULE],
  value: Option.RECURRING_SCHEDULE,
}

export const optionTemporarySchedule: SelectItem = {
  title: OPTION_LABEL[Option.TEMPORARY_SCHEDULE],
  value: Option.TEMPORARY_SCHEDULE,
}

export const optionEnvelopeStatus: SelectItem = {
  title: OPTION_LABEL[Option.ENVELOPE_STATUS],
  value: Option.ENVELOPE_STATUS,
}

export const controlsOffActivated = '"Controls off" successfully activated.'
export const envelopesOnlyActivated = '"Envelopes only" successfully activated.'

import { PACIFIC, WALLABY } from '@/constants/colors'
import { Metric } from '@/constants/metrics'
import { type TimeSeriesConfig } from '@/model/charts/TimeSeriesDataSource'
import { TransformerSeriesResponseV2 } from 'rfs/frontend/proto/transformer_details_pb'
import { Format } from '@/utils/format'

export type MeterStats = TransformerSeriesResponseV2['meterStats']

const netLoadAmiConfig: TimeSeriesConfig = {
  metric: Metric.COND_POWER_APPARENT,
  config: { seriesName: 'Net load (via AMI)', seriesColor: PACIFIC.hex },
}

export const loadPercentConfig: TimeSeriesConfig = {
  metric: Metric.RATINGS_POWER_APPARENT,
  config: {
    seriesName: 'Load %',
    seriesColor: WALLABY.hex,
    isGhostSeries: true,
    // NOTE(rafael): since this series won't be displayed in an additional Y
    // axis for itself, we need to use this formatter instead of the
    // formatter used for ChartType.ApparentPower.
    formatter: (val: number, _opts?: any) => Format.fmtPercent(val),
  },
}

export const transformerLoadingTimeSeriesConfigs = [
  netLoadAmiConfig,
  loadPercentConfig,
]

<template>
  <div class="c-SiteGridMap min-height-100">
    <!-- Loading -->
    <v-progress-circular
      v-if="isLoading"
      :size="50"
      color="primary"
      class="w-100 mt-12"
      indeterminate
    />

    <!-- Content -->
    <template v-else>
      <!-- Nothing. -->
      <p class="text-body-2" v-if="!noConductors.length">
        Full feeder connectivity could not be established for this meter.
      </p>

      <!-- "Timeline" -->
      <div v-else class="position-relative" style="height: 100%">
        <div
          class="position-absolute pb-6"
          style="top: 0; left: 0; width: 100%"
        >
          <v-timeline
            truncate-line="both"
            align="start"
            side="end"
            line-inset="12"
            density="compact"
            line-thickness="0"
          >
            <v-timeline-item
              v-for="resource of noConductors"
              :key="resource.id"
              dot-color="white"
              size="small"
            >
              <!-- Icon -->
              <template v-slot:icon>
                <img :src="getIcon(resource)" width="24px" height="24px" />
              </template>

              <div>
                <safe-router-link
                  :to="routeForResource(resource)"
                  class="text-body-2"
                >
                  {{ getDisplayName(resource) }}
                </safe-router-link>
                <span
                  class="d-block text-caption"
                  :style="{ color: rTypeColor }"
                >
                  ({{ getResourceTypeDisplayName(resource.type as any) }})
                </span>
              </div>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { NEUTRAL_400, NEUTRAL_600 } from '@/constants/colors'
import { ResourceType } from '@/constants/resourceType'
import { routeForResource } from '@/router/routeForResource'
import { useAncestryStore } from '@/stores/ancestry'
import { getDisplayName, getResourceTypeDisplayName } from '@/model/resource'
import type { Resource } from 'rfs/pb/resource_pb'
import SafeRouterLink from '@/components/common/SafeRouterLink.vue'
import { resourceIcons } from './maps/layers/iconAtlas'
import iconConductor from '@/assets/imgs/albert_ic_distribution--black.svg'

export default defineComponent({
  name: 'SiteGridTab',
  components: { SafeRouterLink },
  setup() {
    return {
      getDisplayName,
      getResourceTypeDisplayName,
      routeForResource,
      rTypeColor: NEUTRAL_600.hex,
      dotColor: NEUTRAL_400.hex,
    }
  },
  computed: {
    ...mapState(useAncestryStore, ['isLoading', 'uplineResources']),
    noConductors() {
      return (
        this.uplineResources
          // Remove the conductors.
          .filter((r) => r.type !== ResourceType.CONDUCTOR)
          // From Top to the Bottom.
          .reverse()
          // Put substation as the first item.
          .sort((a, b) => {
            if (a.type === ResourceType.SUBSTATION) return -1
            if (b.type === ResourceType.SUBSTATION) return 1
            return 0
          }) as Resource[]
      )
    },
  },
  methods: {
    getIcon(r: Resource): string {
      const type = r.type as ResourceType

      if (type === ResourceType.FEEDER) {
        return iconConductor
      } else {
        return resourceIcons.find((item) => item[0] === type)?.[1] ?? ''
      }
    },
  },
})
</script>

<style lang="scss">
.c-SiteGridMap {
  .v-timeline-divider__after {
    background-color: white !important;
    border-left: 2px dotted v-bind(dotColor);
  }

  .v-timeline-item__opposite {
    display: none;
  }

  .v-timeline-divider__dot {
    background-color: white !important;
  }
}
</style>
